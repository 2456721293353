import { apiRequest } from '../../services/index.js';
import { getPartner } from '../reducers/getPartnerReducer.js';
import * as env from '../../config/env.js';

// Actions
export const getPartnerAction = (id) => async dispatch => {
    try {
        await apiRequest("get", `${env.GETPARTNERPREFERENCE}/${id}`).then((response) => {
            if(response.status === 200){
                dispatch(getPartner(response.data.data));
            }
        }).catch((error) => {
            console.error('Get user Action Error',error);
        });
    } catch (e) {
        console.error('user get Error Action',e.message);
    }
};