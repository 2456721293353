import { Link } from "react-router-dom";
import male from "../../../assets/images/png/male.png";
import female from "../../../assets/images/png/female.png";
import unlock from "../../../assets/images/png/unlock.png";
import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import './index.css'
import { useSelector } from "react-redux";
import moment from "moment";
import locked from '../../../assets/images/png/locked.png';
import ClientWidthContext from '../../atom/clientWidth.jsx';
const SliderUserDetailComp = ({userDetails,isLike,userInfo}) => {
    const [settings,setSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false
    });
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const calculateAge = (dateOfBirth) => {
        const dob = moment(dateOfBirth);
        const now = moment();
        return now.diff(dob, 'years');
    };
    const getImageUrl = (photoUrls, size) => {
        const photo = photoUrls.find(photo => photo[size]);
        return photo ? photo[size] : null;
    };
    const clientWidth = useContext(ClientWidthContext);
    useEffect(() => {
        if (clientWidth < 480) {
            setSettings({
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                arrows: false
            });
        } else if (clientWidth < 1024) {
            setSettings({
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 2,
                autoplay: true,
                autoplaySpeed: 4000,
                arrows: false
            });
        } else {
            setSettings({
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                arrows: false
            });
        }
    },[clientWidth]);
    
    return(
        <>
            {
                userDetails.length === 1 ?
                    (userDetails.map((e,ind) => 
                        <div key={ind} className="px-[15px] relative">
                            <div className={`relative text-center`} >
                                {
                                    e?.profileMainPhoto && e?.profileMainPhoto?.length ? 
                                    <img className="rounded-[12px] lg:h-[260px] ms:h-[250px] xms:h-[200px] lg:w-full w-[260px] bg-[#d1c9aa] lg:mx-auto xms:mx-0 mx-auto" src={getImageUrl(e?.profileMainPhoto?.[0], "250x260")} alt={'profile'} />
                                    : e?.gender === 1 ? <img className="rounded-[12px] lg:h-[260px] ms:h-[250px] xms:h-[200px] lg:w-full w-[260px] bg-[#d1c9aa] lg:mx-auto xms:mx-0 mx-auto" src={male} alt={'male'} /> : <img className="rounded-[12px] lg:h-[260px] ms:h-[250px] xms:h-[200px] lg:w-full w-[260px] bg-[#d1c9aa] lg:mx-auto xms:mx-0 mx-auto" src={female} alt={'female'} />
                                }
                                <div className="xms:mt-0 mt-[10px]">
                                    <span className="xms:absolute text-white bg-[#b7892d] left-[15px] top-[10px] z-10 text-[14px] font-family-Quicksand-Bold rounded-[7px] pt-[2px] pb-[4px] px-[7px]">{e.profileId}</span>
                                </div>
                            </div>
                            <div className="py-[15px] text-center xms:text-left">
                                <div className="xms:flex w-full xms:items-center xms:justify-between">
                                    <div>
                                        <h4 className="leading-[normal] text-[#931217] text-[20px] font-family-Quicksand-Bold">{e?.firstName} {e?.lastName}</h4>
                                        <p className="text-[#262626] mt-[4px] leading-[normal] font-family-Quicksand-Medium text-stroke-2 text-[14px] font-medium">Profile Created by {e?.basicProfileCreatedFor ? dropDown['profileCreatorFor']?.option.find(ele => ele.key === Number(e?.basicProfileCreatedFor))?.name : '-'}</p >
                                    </div> 
                                    <div className="flex items-center justify-center sm:block sm:mt-0 mt-[12px]">
                                        <span className="sm:text-right sm:mr-0 sm:block mr-[8px] leading-[normal] font-family-Quicksand-Medium text-stroke text-[#931217] text-[18px]">{e?.matchScore}/6</span>
                                        <p className="leading-[normal] font-family-Quicksand-Medium text-[14px] text-stroke-2 text-[#931217]">Matched</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="font-family-Quicksand-Medium text-[16px] leading-[normal] text-stroke-2 mt-[15px]">{e?.horoscopeDateOfBirth ? `${calculateAge(e?.horoscopeDateOfBirth)} yrs` : '-'} | {e?.basicHeight ? dropDown?.['height']?.option.find(ele => ele.key === Number(e?.basicHeight))?.name : '-'} | {e?.basicMotherTongue ? dropDown?.['motherTongue']?.option.find(ele => ele.key === Number(e?.basicMotherTongue))?.name : '-'}</p>
                                    <p className="font-family-Quicksand-Medium text-[16px] leading-[normal] text-stroke-2">{e?.basicReligion ? dropDown?.['religion']?.option?.find(ele => ele.key === Number(e?.basicReligion))?.name : '-'}, {e?.basicCaste ? dropDown?.['caste']?.option?.find(ele => ele.key === Number(e?.basicCaste))?.name : '-'}</p>
                                    <p className="font-family-Quicksand-Medium text-[16px] leading-[normal] text-stroke-2 mt-[15px]">{e.locationCity}, {e.locationState}, {e.locationCountry}</p>
                                    <div className="flex items-center justify-center xms:justify-start mt-[10px] mb-[10px]">
                                        <img className="pr-[10px]" src={e?.isProfileUnlock ? unlock : locked} alt="unlock" />
                                        <Link to={`/author/${userInfo?.userId}/${e?.userId}`} className="relative after:bg-[#ed1c24] after:h-[2px] after:w-[22px] after:absolute after:bottom-[4px] after:ml-[10px] block font-family-Quicksand-SemiBold text-[18px] leading-[normal] text-[#ed1c24]">View Profile</Link >
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )
                :
                <Slider {...settings}>
                    {userDetails.map((e,ind) => 
                            <div key={ind} className="px-[15px] relative">
                                <div className={`relative text-center`} >
                                    {
                                        e?.profileMainPhoto && e?.profileMainPhoto?.length ? 
                                        <img className="rounded-[12px] lg:h-[260px] ms:h-[250px] xms:h-[200px] lg:w-full w-[260px] bg-[#d1c9aa] lg:mx-auto xms:mx-0 mx-auto" src={getImageUrl(e?.profileMainPhoto?.[0], "250x260")} alt={'profile'} />
                                        : e?.gender === 1 ? <img className="rounded-[12px] lg:h-[260px] ms:h-[250px] xms:h-[200px] lg:w-full w-[260px] bg-[#d1c9aa] lg:mx-auto xms:mx-0 mx-auto" src={male} alt={'male'} /> : <img className="rounded-[12px] lg:h-[260px] ms:h-[250px] xms:h-[200px] lg:w-full w-[260px] bg-[#d1c9aa] lg:mx-auto xms:mx-0 mx-auto" src={female} alt={'female'} />
                                    }
                                    <div className="xms:mt-0 mt-[10px]">
                                        <span className="xms:absolute text-white bg-[#b7892d] left-[15px] top-[10px] z-10 text-[14px] font-family-Quicksand-Bold rounded-[7px] pt-[2px] pb-[4px] px-[7px]">{e.profileId}</span>
                                    </div>
                                </div>
                                <div className="py-[15px] text-center sm:text-left">
                                    <div className="sm:flex w-full sm:items-center sm:justify-between">
                                        <div>
                                            <h4 className="leading-[normal] text-[#931217] text-[20px] font-family-Quicksand-Bold">{e?.firstName} {e?.lastName}</h4>
                                            <p className="text-[#262626] mt-[4px] leading-[normal] font-family-Quicksand-Medium text-stroke-2 text-[14px] font-medium">Profile Created by {e?.basicProfileCreatedFor ? dropDown['profileCreatorFor']?.option.find(ele => ele.key === Number(e?.basicProfileCreatedFor))?.name : '-'}</p >
                                        </div> 
                                        <div className="flex items-center justify-center sm:block sm:mt-0 mt-[12px]">
                                            <span className="sm:text-right sm:mr-0 sm:block mr-[8px] leading-[normal] font-family-Quicksand-Medium text-stroke text-[#931217] text-[18px]">{e?.matchScore}/6</span>
                                            <p className="leading-[normal] font-family-Quicksand-Medium text-[14px] text-stroke-2 text-[#931217]">Matched</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="font-family-Quicksand-Medium text-[16px] leading-[normal] text-stroke-2 mt-[15px]">{e?.horoscopeDateOfBirth ? `${calculateAge(e?.horoscopeDateOfBirth)} yrs` : '-'} | {e?.basicHeight ? dropDown?.['height']?.option.find(ele => ele.key === Number(e?.basicHeight))?.name : '-'} | {e?.basicMotherTongue ? dropDown?.['motherTongue']?.option.find(ele => ele.key === Number(e?.basicMotherTongue))?.name : '-'}</p>
                                        <p className="font-family-Quicksand-Medium text-[16px] leading-[normal] text-stroke-2">{e?.basicReligion ? dropDown?.['religion']?.option?.find(ele => ele.key === Number(e?.basicReligion))?.name : '-'}, {e?.basicCaste ? dropDown?.['caste']?.option?.find(ele => ele.key === Number(e?.basicCaste))?.name : '-'}</p>
                                        <p className="font-family-Quicksand-Medium text-[16px] leading-[normal] text-stroke-2 mt-[15px]">{e.locationCity}, {e.locationState}, {e.locationCountry}</p>
                                        <div className="flex items-center justify-center sm:justify-start mt-[10px] mb-[10px]">
                                            <img className="pr-[10px]" src={e?.isProfileUnlock ? unlock : locked} alt="unlock" />
                                            <Link to={`/author/${userInfo?.userId}/${e?.userId}`} className="relative after:bg-[#ed1c24] after:h-[2px] after:w-[22px] after:absolute after:bottom-[4px] after:ml-[10px] block font-family-Quicksand-SemiBold text-[18px] leading-[normal] text-[#ed1c24]">View Profile</Link >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Slider>
            }
        </>
    )
}

export default SliderUserDetailComp;