import { apiRequest } from '../../services/index.js';
import { getPlan } from '../reducers/getPlanReducer.js';
import * as env from '../../config/env.js';

// Actions
export const getPlanAction = () => async dispatch => {
    try {
        await apiRequest("get", `${env.GETPLAN}`).then((response) => {
            if (response.status === 200) {
                if (response?.data?.data && response?.data?.data?.length) {
                    dispatch(getPlan(response?.data?.data));
                }
            }
        }).catch((error) => {
            console.error('Get user Action Error', error);
        });
    } catch (e) {
        console.error('user get Error Action', e.message);
    }
};