import Button from "../../atom/button";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as env from '../../../config/env.js';
import UserDetail from "../../atom/userDetail";
import { apiRequest } from "../../../services";
import Spinner from "../../atom/spinner/spinner";
import ProfilePicture from "../dashBoard/profilePicture";
import { useNavigate, useLocation } from "react-router-dom";
import userImage from "../../../assets/images/png/userImage.png";

function Likes() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [pageNumber,setPageNumber] = useState(queryParams.get('pageNumber') || 1);
    const userDetails = useSelector(state => state?.user?.user);
    const [matchesCount, setMatchesCount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [myLikes, setMyLikes] = useState([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber);
    const [activeClass, setActiveClass] = useState(queryParams?.get('tab') ? Number(queryParams.get('tab')) : 1);
    
    const handleClick = (value) => {
        setPageNumber(1);
        setCurrentPageNumber(1);
        setActiveClass(value);
    };
    
    const getMyLikeOrLikersData = (skip, limit, currentPage, id, action) => {
        try {
            navigate(`/likes?pageNumber=${currentPage}&tab=${action === 'myLikes' ? 1 : 2}`);
            setIsLoading(true);
            setMyLikes([]);
            apiRequest("get", `${env.GETMYLIKEPROFILE}/${action}/${id}?skip=${skip}&limit=${limit}`).then((response) => {
                if (response.status === 200) {
                    if (response?.data?.data?.[0].profiles && response?.data?.data?.[0].profiles?.length) {
                        setMyLikes(response?.data?.data?.[0].profiles);
                        setIsLoading(false);
                        setCurrentPageNumber(currentPage);
                        setMatchesCount(response?.data?.data?.[0].totalCount?.[0]?.totalCount);
                    } else {
                        setMyLikes([]);
                        setIsLoading(false);
                        setMatchesCount(0);
                    }
                } else {
                    setMyLikes([]);
                    setIsLoading(false);
                    setMatchesCount(0);
                }
            }).catch((error) => {
                console.error('Error in getting the like or likers', error);
                setIsLoading(false);
                setMatchesCount(0);
            });
        } catch (error) {
            console.error('Error in getting the like or likers', error);
            setIsLoading(false);
            setMatchesCount(0);
        }
    };
    
    const getMyLikeOrLikerData = (batch, limit, currentPage, id, action) => {
        getMyLikeOrLikersData(batch, limit, currentPage, id, action);
    };

    // Dislike
    const handleLikeDisLike = (action, id, batch, limit, currentPage, userId, actions, ind) => {
        try {
            let likeDisLike = {
                userId: userId,
                likeProfileId: id,
                action: action
            };
            setIsLoading(true);
            apiRequest("post", `${env.MYLIKEPROFILE}`, likeDisLike).then(async (response) => {
                if (response.status === 200) {
                    if (actions === 'myLikes') {
                        await getMyLikeOrLikersData(batch, limit, currentPage, userId, actions);
                    } else {
                        let like = [...myLikes];
                        if (like && like.length) {
                            like[ind].isProfileLike = action === 'like' ? true : false;
                        }
                        setMyLikes(like);
                        setIsLoading(false);
                    }
                } else {
                    setIsLoading(false);
                }
            }).catch((error) => {
                console.error('Error in like or dislike', error);
                setIsLoading(false);
            });
        } catch (error) {
            console.error('Error in like or dislike', error);
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        if(!userDetails?.profileActiveDate || (userDetails?.profileActiveDate < new Date().getTime())){
            if (userDetails?.userId && Number(pageNumber)) {
                let page = Number(pageNumber);
                let skip = (page * env.LIMIT) - env.LIMIT;
                if (activeClass === 1) {
                    getMyLikeOrLikersData(skip, env.LIMIT, page, userDetails?.userId, 'myLikes');
                } else if (activeClass === 2) {
                    getMyLikeOrLikersData(skip, env.LIMIT, page, userDetails?.userId, 'myLikers');
                }
            }
        }
    }, [activeClass, userDetails, pageNumber]);
    useEffect(() => {
        if(queryParams?.get('tab') && Number(queryParams?.get('tab')) >= 3){
            navigate(`/likes?pageNumber=${1}&tab=${1}`);
        }
    },[queryParams])

    return (
        <>
            {
                isLoading ? 
                <>
                    <Spinner /> 
                </>
                : null
            }
            {
                !userDetails?.profileActiveDate || (userDetails?.profileActiveDate < new Date().getTime()) ? 
                <div className="lg:w-full xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] max-w-full mx-auto xl:mt-[90px] xl:mb-[100px] lg:mt-[60px] lg:mb-[60px] mt-[40px] mb-[40px]">
                    <div className="px-[15px] mb-[25px]">
                        <Button
                            class={`${activeClass === 1 ? 'bg-[#ed1c24] text-[#fff] border-[#ed1c24]' : 'text-[#b7892d] border-[#b7892d]'} border px-[15px] leading-[normal] py-[6px] mr-[11px] rounded-[50px] text-[#b7892d] text-[16px] font-family-Quicksand-Bold`}
                            buttonName={'My Likes'}
                            onClick={() => handleClick(1)}
                        />
                        <Button
                            class={`${activeClass === 2 ? 'bg-[#ed1c24] text-[#fff] border-[#ed1c24]' : 'text-[#b7892d] border-[#b7892d]'} border px-[15px] leading-[normal] py-[6px] mr-[11px] rounded-[50px] text-[#b7892d] text-[16px] font-family-Quicksand-Bold`}
                            buttonName={'My Likers'}
                            onClick={() => handleClick(2)}
                        />
                    </div>
                    <div className="grid md:grid-cols-1 lg:grid-cols-4">
                        <div className="px-[15px] mt-[7px]">
                            <ProfilePicture
                                imageWrapper={'w-[160px] h-[160px] mx-auto'}
                                bgWrapper={'px-[20px] pt-[35px] pb-[15px] mb-[20px]'}
                            />
                        </div>
                        <div className="md:col-span-3 px-[15px]">
                        {
                            activeClass === 1 && !isLoading ? 
                            <>
                                <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[44px] mb-[15px] lg:leading-[52.8px] md:leading-[30px] leading-[normal]">My Likes</h2>
                                {myLikes && myLikes.length ?  
                                    <UserDetail
                                        userDetails={myLikes}
                                        classWrapper={'mx-[-15px] grid grid-cols-1 ms:grid-cols-2 lg:grid-cols-3'}
                                        page={currentPageNumber}
                                        matchesCount={matchesCount}
                                        getMyLikeOrLikerData={(batch, limit, currentPage) => getMyLikeOrLikerData(batch, limit, currentPage, userDetails?.userId, 'myLikes')}
                                        userId={userDetails?.userId}
                                        handleLikeDisLike={(action, id, batch, limit, currentPage, index) => handleLikeDisLike(action, id, batch, limit, currentPage, userDetails?.userId, 'myLikes', index)}
                                    />
                                : (!isLoading && !(myLikes && myLikes.length) ? <span className="text-[20px] font-family-Quicksand-Bold text-[#931217]">My Like Not Found..</span> : null)}
                            </>
                            :
                            activeClass === 2 && !isLoading ? 
                            <>
                                <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[44px] mb-[15px] lg:leading-[52.8px] md:leading-[30px] leading-[normal]">My Likers</h2>
                                {myLikes && myLikes.length ?  
                                    <UserDetail
                                        userDetails={myLikes}
                                        classWrapper={'mx-[-15px] grid grid-cols-1 ms:grid-cols-2 lg:grid-cols-3'}
                                        page={currentPageNumber}
                                        matchesCount={matchesCount}
                                        getMyLikeOrLikerData={(batch, limit, currentPage) => getMyLikeOrLikerData(batch, limit, currentPage, userDetails?.userId, 'myLikers')}
                                        userId={userDetails?.userId}
                                        handleLikeDisLike={(action, id, batch, limit, currentPage, index) => handleLikeDisLike(action, id, batch, limit, currentPage, userDetails?.userId, 'myLikers', index)}
                                    />
                                : (!isLoading && !(myLikes && myLikes.length) ? <span className="text-[20px] font-family-Quicksand-Bold text-[#931217]">My Likers Not Found..</span> : null)}
                            </>
                            : null
                        }
                        </div>
                    </div>
                </div>
                :
                <div className="flex lg:w-full xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] max-w-full mx-auto lg:min-h-[500px] min-h-[400px] justify-center items-center">
                    <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Active Your Profile</h2>
                </div>
            }
        </>
    )
}

export default Likes;
