import React, { useContext, useEffect, useState } from 'react';
import arrow from '../../assets/images/png/prev_btn.png'
import ClientWidthContext from '../atom/clientWidth.jsx';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    const clientWidth = useContext(ClientWidthContext);
    const [pages, setPages] = useState([]);
    const getPagination = (value) => {
        
        let pages = [];
        if(!value){
            if (totalPages <= 10) {
                // Show all pages if total pages is 10 or less
                pages = [...Array(totalPages).keys()].map(num => num + 1);
            } else {
                // Show first 2 pages, last page, and around the current page
                if (currentPage <= 4) {
                    pages = [1, 2, 3, 4, 5, '...', totalPages];
                } else if (currentPage > totalPages - 4) {
                    pages = [1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
                } else {
                    pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
                }
            }
            return pages;
        }else{
            if (totalPages <= 4) {
                // Show all pages if total pages is 10 or less
                pages = [...Array(totalPages).keys()].map(num => num + 1);
            } else {
                // Show first 2 pages, last page, and around the current page
                if (currentPage <= 4) {
                    if(currentPage !== 1){
                        pages = [1, currentPage, '...', totalPages];
                    }else{
                        pages = [1,2, '...', totalPages];
                    }
                } else {
                    if(currentPage === totalPages){
                        pages = [1, '...', totalPages - 1,totalPages];
                    }else {
                        pages = [1, '...', currentPage,totalPages];
                    }
                }
            }
            return pages;
        }
    };

    useEffect(() => {
        setPages(getPagination(clientWidth <= 767));
    }, [clientWidth, currentPage, totalPages]);

    return (
        <div className="flex justify-center mt-4">
            {
                currentPage !== 1 ? 
                    <button
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="cursor-pointer mr-[7px] w-[35px] h-[35px] sm:w-[50px] sm:h-[50px]  text-[#b7892d] rounded-full border border-[#b7892d]"
                    >
                        <img src={arrow} alt='arrow' className='mx-auto' />
                    </button>
                : null
            }
            {pages.map((page, index) => (
                <button
                    key={index}
                    onClick={() => typeof page === 'number' && onPageChange(page)}
                    className={`font-family-Quicksand-Regular sm:text-[20px] text-[14px] leading-[30px] ${currentPage === page ? 'bg-[#ed1c24] text-white cursor-pointer mx-[7px] w-[35px] h-[35px] sm:w-[50px] sm:h-[50px]  text-[#b7892d] rounded-full border border-[#ed1c24]' : 'cursor-pointer mx-[7px] w-[35px] h-[35px] sm:w-[50px] sm:h-[50px]  text-[#b7892d] rounded-full border border-[#b7892d]'}`}
                    disabled={typeof page !== 'number'}
                >
                    {page}
                </button>
            ))}
            {
                currentPage !== totalPages ?
                    <button
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="cursor-pointer ml-[7px] w-[35px] h-[35px] sm:w-[50px] sm:h-[50px]  text-[#b7892d] rounded-full border border-[#b7892d]"
                    >
                        <img src={arrow} alt='arrow' className='rotate-180 mx-auto' />
                    </button> 
                : null
            }
        </div>
    );
};

export default Pagination;