import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'getPlan',
    initialState: {
        getPlan: null,
    },
    reducers: {
        getPlan: (state, action) => {
            state.getPlan = action.payload;
        },
    },
});

export const { getPlan } = slice.actions;
export default slice.reducer;