import React, { useEffect, useState } from 'react';
import Step1 from './formStep1.jsx';
import Step2 from './formStep2.jsx';
import Step3 from './formStep3.jsx';
import Step4 from './formStep4.jsx';
import Step5 from './formStep5.jsx';
import Popup from "../../../atom/popUp.jsx";
import { useDispatch, useSelector } from 'react-redux';
// import {setUserProfileReviewAction} from '../../../../stores/action/setUserProfileReviewAction.js';
import Spinner from '../../../atom/spinner/spinner.jsx';
import { getUserProfileReviewAction } from '../../../../stores/action/userProfileReviewAction.js';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getUser } from '../../../../stores/reducers/userReducer.js';
import Step6 from './formStep6.jsx';
import * as env from '../../../../config/env.js';
import { apiRequest } from '../../../../services/index.js';
import { toast, ToastContainer } from 'react-toastify';

const FormStep = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userProfileStatus = useSelector(state => state?.user?.user);
    const userProfileDetail = useSelector(state => state?.userProfileReview?.userProfileReview);
    const [step, setStep] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingData,setIsLoadingData] = useState(false);
    const [formData,setFormData] = useState({});
    const [isPopUp,setIsPopUp] = useState(false);
    const [form, setForm] = useState({
        alternateMobile1:{value: formData?.alternateMobile1?.mobileNumber ? formData?.alternateMobile1?.mobileNumber : '',rules:"",name: "alternate mobile 1",error: "",countryCode:formData?.alternateMobile1?.countryCode ? formData?.alternateMobile1?.countryCode : '+91'},
        alternateMobile2:{value: formData?.alternateMobile2?.mobileNumber,rules:"",name: "alternate mobile 2",error: "",countryCode:formData?.alternateMobile2?.countryCode ? formData?.alternateMobile2?.countryCode : '+91'},
        horoscopeDateOfBirth:{value: formData?.horoscopeDateOfBirth ? formData?.horoscopeDateOfBirth : '',rules:"required",name: "date of birth",error: ""},
        horoscopeTimeOfBirth:{value: formData?.horoscopeTimeOfBirth ? formData?.horoscopeTimeOfBirth : '',rules:"",name: "time of birth",error: ""},
        horoscopeStateOfBirth:{value: formData?.horoscopeStateOfBirth ? formData?.horoscopeStateOfBirth : '',rules:"",name: "state of birth",error: ""},
        horoscopeCityOfBirth:{value: formData?.horoscopeCityOfBirth ? formData?.horoscopeCityOfBirth : '',rules:"",name: "city of birth",error: ""},
        horoscopeMatch:{value: formData?.horoscopeMatch ? formData?.horoscopeMatch : 1,rules:"",name: "match",error: ""},
        horoscopeBelieveInHoroscope:{value: formData?.horoscopeBelieveInHoroscope ? formData?.horoscopeBelieveInHoroscope : 1,rules:"",name: "believe",error: ""},
        basicMotherTongue: {value: formData?.basicMotherTongue ? formData?.basicMotherTongue :'' ,rules:"required",name: "mother tongue",error: ""},
        basicReligion: {value: formData?.basicReligion ? formData?.basicReligion : '',rules:"required",name: "religion",error: ""},
        basicCaste: {value: formData?.basicCaste ? formData?.basicCaste : '',rules:"required",name: "caste",error: ""},
        basicGor: {value: formData?.basicGor ? formData?.basicGor : '',rules:"required|min:3|max:100",name: "gor",error: ""},
        basicWeight:{value: formData?.basicWeight ? formData?.basicWeight : '',rules:"",name: "weight",error: ""},
        basicBodyType:{value: formData?.basicBodyType ? formData?.basicBodyType : '',rules:"",name: "body type",error: ""},
        horoscopeManglik:{value: formData?.horoscopeManglik ? formData?.horoscopeManglik : 1,rules:"required",name: "manglik",error: ""},
        basicWillingToMarry:{value: formData?.basicWillingToMarry ? formData?.basicWillingToMarry : true,rules:"",name: "marry",error: ""},
        basicMaritalStatus:{value: formData?.basicMaritalStatus ? formData?.basicMaritalStatus : 1,rules:"required",name: "marital status",error: ""},
        basicHaveAChildren:{value: formData?.basicHaveAChildren ? formData?.basicHaveAChildren : null,rules:"",name: "have a children",error: ""},
        basicNumberOfChildrens:{value: formData?.basicNumberOfChildrens ? formData?.basicNumberOfChildrens : 0,rules:"regex:^[0-9]+$",name: "number of children",error: ""},
        basicAwaitingDivorcedRemark:{value: formData?.basicAwaitingDivorcedRemark ? formData?.basicAwaitingDivorcedRemark : '',rules:"",name: "awaiting divorce remark",error: ""},
        basicDivorcedRemark:{value: formData?.basicDivorcedRemark ? formData?.basicDivorcedRemark : '',rules:"",name: "divorce remark",error: ""},
        basicWidowedRemark:{value: formData?.basicWidowedRemark ? formData?.basicWidowedRemark : '',rules:"",name: "widowed remark",error: ""},
        basicHeight:{value: formData?.basicHeight ? formData?.basicHeight : '',rules:"required",name: "height",error: ""},
        familyStatus:{value: formData?.familyStatus ? formData?.familyStatus : '',rules:"required",name: "family status",error: ""},
        familyValues:{value: formData?.familyValues ? formData?.familyValues : '',rules:"required",name: "family values",error: ""},
        basicDisability:{value: formData?.basicDisability ? formData?.basicDisability : 1,rules:"required",name: "disability",error: ""},
        basicDisabilityDetail:{value: formData?.basicDisabilityDetail ? formData?.basicDisabilityDetail : '',rules:"",name: "basicDisabilityDetail",error: ""},
        educationHighestQualification:{value: formData?.educationHighestQualification ? formData?.educationHighestQualification : '',rules:"required",name: "highest qualification",error: ""},
        educationSubject:{value: formData?.educationSubject ? formData?.educationSubject : '',rules:"",name: "education subject",error: ""},
        educationInDetail:{value: formData?.educationInDetail ? formData?.educationInDetail : '',rules:"",name: "additional education detail",error: ""},
        educationAdditionalDegree:{value: formData?.educationAdditionalDegree ? formData?.educationAdditionalDegree : '',rules:"",name: "additional degree",error: ""},
        educationEmployeedIn:{value: formData?.educationEmployeedIn ? formData?.educationEmployeedIn : '',rules:"",name: "employed in",error: ""},
        educationOccupation:{value: formData?.educationOccupation ? formData?.educationOccupation : '',rules:"required",name: "education occupation",error: ""},
        educationAnnualIncome:{value: formData?.educationAnnualIncome ? formData?.educationAnnualIncome : '',rules:"",name: "annual income",error: ""},
        educationWorkLocation:{value: formData?.educationWorkLocation ? formData?.educationWorkLocation : '',rules:"",name: "work location",error: ""},
        locationCountry:{value: formData?.locationCountry ? formData?.locationCountry : '',rules:"required",name: "country",error: ""},
        locationCity:{value: formData?.locationCity ? formData?.locationCity : '',rules:"required",name: "city",error: ""},
        locationState:{value: formData?.locationState ? formData?.locationState : '',rules:"required",name: "state",error: ""},
        aboutMe:{value: formData?.aboutMe ? formData?.aboutMe : '',rules:"required|min:3|max:500",name: "about me",error: ""},
        locationResident:{value: formData?.locationResident ? formData?.locationResident : '',rules:"",name: "resident",error: ""},
        educationOrganization:{value: formData?.educationOrganization ? formData?.educationOrganization : '',rules:"",name: "organization",error: ""},
        educationOccupationDetail:{value: formData?.educationOccupationDetail ? formData?.educationOccupationDetail : '',rules:"",name: "education occupation detail",error: ""},
        educationCollege:{value: formData?.educationCollege ? formData?.educationCollege : '',rules:"",name: "education college",error: ""},
        familyFatherOccupation:{value: formData?.familyFatherOccupation ? formData?.familyFatherOccupation : '',rules:"",name: "father occupation",error: ""},
        familyMotherOccupation:{value: formData?.familyMotherOccupation ? formData?.familyMotherOccupation : '',rules:"",name: "mother occupation",error: ""},
        familySister:{value: formData?.familySister ? formData?.familySister : null,rules:"",name: "family sister",error: ""},
        familyMarriedSister:{value: formData?.familyMarriedSister ? formData?.familyMarriedSister : null,rules:"",name: "family married sister",error: ""},
        familyBrother:{value: formData?.familyBrother ? formData?.familyBrother : null,rules:"",name: "family brother",error: ""},
        familyMarriedBrother:{value: formData?.familyMarriedBrother ? formData?.familyMarriedBrother : null,rules:"",name: "family married brother",error: ""},
        familyType:{value: formData?.familyType ? formData?.familyType : '',rules:"",name: "family type",error: ""},
        familyIncome:{value: formData?.familyIncome ? formData?.familyIncome : '',rules:"",name: "family Income",error: ""},
        familyLivingState:{value: formData?.familyLivingState ? formData?.familyLivingState : '',rules:"",name: "family state",error: ""},
        familyLivingCountry:{value: formData?.familyLivingCountry ? formData?.familyLivingCountry : '',rules:"",name: "family country",error: ""},
        familyLivingIn:{value: formData?.familyLivingIn ? formData?.familyLivingIn : '',rules:"",name: "family livingIn",error: ""},
        familyLiveWithFamily:{value: formData?.familyLiveWithFamily ? formData?.familyLiveWithFamily : 1,rules:"",name: "live with family",error: ""},
        lifeStyleEatingHabits:{value: formData?.lifeStyleEatingHabits ? formData?.lifeStyleEatingHabits : '',rules:"",name: "eating habits",error: ""},
        lifeStyleSmokingHabits:{value: formData?.lifeStyleSmokingHabits ? formData?.lifeStyleSmokingHabits : '',rules:"",name: "smoking habits",error: ""},
        lifeStyleDrinkingHabits:{value: formData?.lifeStyleDrinkingHabits ? formData?.lifeStyleDrinkingHabits : '',rules:"",name: "drinking habits",error: ""},
        lifeStyleOwnsHouse:{value: formData?.lifeStyleOwnsHouse ? formData?.lifeStyleOwnsHouse : 1,rules:"",name: "owns house",error: ""},
        lifeStyleOwnsCar:{value: formData?.lifeStyleOwnsCar ? formData?.lifeStyleOwnsCar : 1,rules:"",name: "owns car",error: ""},
        lifeStyleLanguages:{value: formData?.lifeStyleLanguages ? formData?.lifeStyleLanguages : '',rules:"",name: "life style languages",error: ""},
        interestFavouriteVacationDestination:{value: formData?.interestFavouriteVacationDestination && formData?.interestFavouriteVacationDestination?.length ? formData?.interestFavouriteVacationDestination : [],rules:"",name: "favourite vacation destination",error: ""},
        interestFavouriteCusine:{value: formData?.interestFavouriteCusine && formData?.interestFavouriteCusine?.length ? formData?.interestFavouriteCusine : [],rules:"",name: "favourite cuisine",error: ""},
        interestSportsFitness:{value: formData?.interestSportsFitness && formData?.interestSportsFitness?.length ? formData?.interestSportsFitness : [],rules:"",name: "sports fitness",error: ""},
        interestPreferredMovies:{value: formData?.interestPreferredMovies && formData?.interestPreferredMovies?.length ? formData?.interestPreferredMovies : [],rules:"",name: "preferred movies",error: ""},
        interestFavouriteTVShows:{value: formData?.interestFavouriteTVShows && formData?.interestFavouriteTVShows?.length ? formData?.interestFavouriteTVShows : [],rules:"",name: "favourite tv shows",error: ""},
        interestDressStyle:{value: formData?.interestDressStyle && formData?.interestDressStyle?.length ? formData?.interestDressStyle : [],rules:"",name: "dress style",error: ""},
        interestFavouriteRead:{value: formData?.interestFavouriteRead && formData?.interestFavouriteRead?.length ? formData?.interestFavouriteRead : [],rules:"",name: "favourite read",error: ""},
        interestMusic:{value: formData?.interestMusic && formData?.interestMusic?.length ? formData?.interestMusic : [],rules:"",name: "music",error: ""},
        interest:{value: formData?.interest && formData?.interest?.length ? formData?.interest : [],rules:"",name: "interest",error: ""},
        interestHobbies:{value: formData?.interestHobbies && formData?.interestHobbies?.length ? formData?.interestHobbies : [],rules:"",name: "hobbies",error: ""}
    });

    useEffect(() => {
        setIsLoadingData(true);
        if (!userProfileDetail) {
            const fetchData = async () => {
                const userId = userProfileStatus?._id;
                if (userId) {
                    await dispatch(getUserProfileReviewAction(userId));
                    setIsLoadingData(false);
                }else{
                    setIsLoadingData(false);
                }
            };
            fetchData();
        }else{
            setIsLoadingData(false);
            setStep(1);
        }
    }, [userProfileDetail, userProfileStatus, dispatch]);
    useEffect(() => {
        setFormData(userProfileDetail);
        if(userProfileDetail?.completedStep === 6){
            setStep(userProfileDetail?.completedStep || 1)
        }else{
            setStep(userProfileDetail?.completedStep + 1 || 1)
        }
    },[userProfileDetail]);

    useEffect(() => {
        if (formData) {
            setForm({
                alternateMobile1:{value: formData?.alternateMobile1?.mobileNumber ? formData?.alternateMobile1?.mobileNumber : '',rules:"",name: "alternate mobile 1",error: "",countryCode:formData?.alternateMobile1?.countryCode ? formData?.alternateMobile1?.countryCode : '+91'},
                alternateMobile2:{value: formData?.alternateMobile2?.mobileNumber,rules:"",name: "alternate mobile 2",error: "",countryCode:formData?.alternateMobile2?.countryCode ? formData?.alternateMobile2?.countryCode : '+91'},
                horoscopeDateOfBirth:{value: formData?.horoscopeDateOfBirth ? moment(formData?.horoscopeDateOfBirth).format('YYYY-MM-DD') : '',rules:"required",name: "date of birth",error: ""},
                horoscopeTimeOfBirth:{value: formData?.horoscopeTimeOfBirth ? formData?.horoscopeTimeOfBirth : '',rules:"",name: "time of birth",error: ""},
                horoscopeStateOfBirth:{value: formData?.horoscopeStateOfBirth ? formData?.horoscopeStateOfBirth : '',rules:"",name: "state of birth",error: ""},
                horoscopeCityOfBirth:{value: formData?.horoscopeCityOfBirth ? formData?.horoscopeCityOfBirth : '',rules:"",name: "city of birth",error: ""},
                horoscopeMatch:{value: formData?.horoscopeMatch ? formData?.horoscopeMatch : 1,rules:"",name: "match",error: ""},
                horoscopeBelieveInHoroscope:{value: formData?.horoscopeBelieveInHoroscope ? formData?.horoscopeBelieveInHoroscope : 1,rules:"",name: "believe",error: ""},
                basicMotherTongue: {value: formData?.basicMotherTongue ? formData?.basicMotherTongue :'' ,rules:"required",name: "mother tongue",error: ""},
                basicReligion: {value: formData?.basicReligion ? formData?.basicReligion : '',rules:"required",name: "religion",error: ""},
                basicCaste: {value: formData?.basicCaste ? formData?.basicCaste : '',rules:"required",name: "caste",error: ""},
                basicGor: {value: formData?.basicGor ? formData?.basicGor : '',rules:"required|min:3|max:100",name: "gor",error: ""},
                basicWeight:{value: formData?.basicWeight ? formData?.basicWeight : '',rules:"",name: "weight",error: ""},
                basicBodyType:{value: formData?.basicBodyType ? formData?.basicBodyType : '',rules:"",name: "body type",error: ""},
                horoscopeManglik:{value: formData?.horoscopeManglik ? formData?.horoscopeManglik : 1,rules:"required",name: "manglik",error: ""},
                basicWillingToMarry:{value: formData?.basicWillingToMarry ? formData?.basicWillingToMarry : false,rules:"",name: "marry",error: ""},
                basicMaritalStatus:{value: formData?.basicMaritalStatus ? formData?.basicMaritalStatus : 1,rules:"required",name: "marital status",error: ""},
                basicHaveAChildren:{value: formData?.basicHaveAChildren ? formData?.basicHaveAChildren : null,rules:"",name: "have a children",error: ""},
                basicNumberOfChildrens:{value: formData?.basicNumberOfChildrens ? formData?.basicNumberOfChildrens : 0,rules:"regex:^[0-9]+$",name: "number of children",error: ""},
                basicAwaitingDivorcedRemark:{value: formData?.basicAwaitingDivorcedRemark ? formData?.basicAwaitingDivorcedRemark : '',rules:"",name: "awaiting divorce remark",error: ""},
                basicDivorcedRemark:{value: formData?.basicDivorcedRemark ? formData?.basicDivorcedRemark : '',rules:"",name: "divorce remark",error: ""},
                basicWidowedRemark:{value: formData?.basicWidowedRemark ? formData?.basicWidowedRemark : '',rules:"",name: "widowed remark",error: ""},
                basicHeight:{value: formData?.basicHeight ? formData?.basicHeight : '',rules:"required",name: "height",error: ""},
                familyStatus:{value: formData?.familyStatus ? formData?.familyStatus : '',rules:"required",name: "family status",error: ""},
                familyValues:{value: formData?.familyValues ? formData?.familyValues : '',rules:"required",name: "family values",error: ""},
                basicDisability:{value: formData?.basicDisability ? formData?.basicDisability : 1,rules:"required",name: "disability",error: ""},
                basicDisabilityDetail:{value: formData?.basicDisabilityDetail ? formData?.basicDisabilityDetail : '',rules:"",name: "basicDisabilityDetail",error: ""},
                educationHighestQualification:{value: formData?.educationHighestQualification ? formData?.educationHighestQualification : '',rules:"required",name: "highest qualification",error: ""},
                educationSubject:{value: formData?.educationSubject ? formData?.educationSubject : '',rules:"",name: "education subject",error: ""},
                educationInDetail:{value: formData?.educationInDetail ? formData?.educationInDetail : '',rules:"",name: "additional education detail",error: ""},
                educationAdditionalDegree:{value: formData?.educationAdditionalDegree ? formData?.educationAdditionalDegree : '',rules:"",name: "additional degree",error: ""},
                educationEmployeedIn:{value: formData?.educationEmployeedIn ? formData?.educationEmployeedIn : '',rules:"",name: "employed in",error: ""},
                educationOccupation:{value: formData?.educationOccupation ? formData?.educationOccupation : '',rules:"required",name: "education occupation",error: ""},
                educationAnnualIncome:{value: formData?.educationAnnualIncome ? formData?.educationAnnualIncome : '',rules:"",name: "annual income",error: ""},
                educationWorkLocation:{value: formData?.educationWorkLocation ? formData?.educationWorkLocation : '',rules:"",name: "work location",error: ""},
                locationCountry:{value: formData?.locationCountry ? formData?.locationCountry : '',rules:"required",name: "country",error: ""},
                locationCity:{value: formData?.locationCity ? formData?.locationCity : '',rules:"required",name: "city",error: ""},
                locationState:{value: formData?.locationState ? formData?.locationState : '',rules:"required",name: "state",error: ""},
                aboutMe:{value: formData?.aboutMe ? formData?.aboutMe : '',rules:"required|min:3|max:500",name: "about me",error: ""},
                locationResident:{value: formData?.locationResident ? formData?.locationResident : '',rules:"",name: "resident",error: ""},
                educationOrganization:{value: formData?.educationOrganization ? formData?.educationOrganization : '',rules:"",name: "organization",error: ""},
                educationOccupationDetail:{value: formData?.educationOccupationDetail ? formData?.educationOccupationDetail : '',rules:"",name: "education occupation detail",error: ""},
                educationCollege:{value: formData?.educationCollege ? formData?.educationCollege : '',rules:"",name: "education college",error: ""},
                familyFatherOccupation:{value: formData?.familyFatherOccupation ? formData?.familyFatherOccupation : '',rules:"",name: "father occupation",error: ""},
                familyMotherOccupation:{value: formData?.familyMotherOccupation ? formData?.familyMotherOccupation : '',rules:"",name: "mother occupation",error: ""},
                familySister:{value: formData?.familySister ? formData?.familySister : null,rules:"",name: "family sister",error: ""},
                familyMarriedSister:{value: formData?.familyMarriedSister ? formData?.familyMarriedSister : null,rules:"",name: "family married sister",error: ""},
                familyBrother:{value: formData?.familyBrother ? formData?.familyBrother : null,rules:"",name: "family brother",error: ""},
                familyMarriedBrother:{value: formData?.familyMarriedBrother ? formData?.familyMarriedBrother : null,rules:"",name: "family married brother",error: ""},
                familyType:{value: formData?.familyType ? formData?.familyType : '',rules:"",name: "family type",error: ""},
                familyIncome:{value: formData?.familyIncome ? formData?.familyIncome : '',rules:"",name: "family Income",error: ""},
                familyLivingState:{value: formData?.familyLivingState ? formData?.familyLivingState : '',rules:"",name: "family state",error: ""},
                familyLivingCountry:{value: formData?.familyLivingCountry ? formData?.familyLivingCountry : '',rules:"",name: "family country",error: ""},
                familyLivingIn:{value: formData?.familyLivingIn ? formData?.familyLivingIn : '',rules:"",name: "family livingIn",error: ""},
                familyLiveWithFamily:{value: formData?.familyLiveWithFamily ? formData?.familyLiveWithFamily : 1,rules:"",name: "live with family",error: ""},
                lifeStyleEatingHabits:{value: formData?.lifeStyleEatingHabits ? formData?.lifeStyleEatingHabits : '',rules:"",name: "eating habits",error: ""},
                lifeStyleSmokingHabits:{value: formData?.lifeStyleSmokingHabits ? formData?.lifeStyleSmokingHabits : '',rules:"",name: "smoking habits",error: ""},
                lifeStyleDrinkingHabits:{value: formData?.lifeStyleDrinkingHabits ? formData?.lifeStyleDrinkingHabits : '',rules:"",name: "drinking habits",error: ""},
                lifeStyleOwnsHouse:{value: formData?.lifeStyleOwnsHouse ? formData?.lifeStyleOwnsHouse : 1,rules:"",name: "owns house",error: ""},
                lifeStyleOwnsCar:{value: formData?.lifeStyleOwnsCar ? formData?.lifeStyleOwnsCar : 1,rules:"",name: "owns car",error: ""},
                lifeStyleLanguages:{value: formData?.lifeStyleLanguages ? formData?.lifeStyleLanguages : '',rules:"",name: "life style languages",error: ""},
                interestFavouriteVacationDestination:{value: formData?.interestFavouriteVacationDestination && formData?.interestFavouriteVacationDestination?.length ? formData?.interestFavouriteVacationDestination : [],rules:"",name: "favourite vacation destination",error: ""},
                interestFavouriteCusine:{value: formData?.interestFavouriteCusine && formData?.interestFavouriteCusine?.length ? formData?.interestFavouriteCusine : [],rules:"",name: "favourite cuisine",error: ""},
                interestSportsFitness:{value: formData?.interestSportsFitness && formData?.interestSportsFitness?.length ? formData?.interestSportsFitness : [],rules:"",name: "sports fitness",error: ""},
                interestPreferredMovies:{value: formData?.interestPreferredMovies && formData?.interestPreferredMovies?.length ? formData?.interestPreferredMovies : [],rules:"",name: "preferred movies",error: ""},
                interestFavouriteTVShows:{value: formData?.interestFavouriteTVShows && formData?.interestFavouriteTVShows?.length ? formData?.interestFavouriteTVShows : [],rules:"",name: "favourite tv shows",error: ""},
                interestDressStyle:{value: formData?.interestDressStyle && formData?.interestDressStyle?.length ? formData?.interestDressStyle : [],rules:"",name: "dress style",error: ""},
                interestFavouriteRead:{value: formData?.interestFavouriteRead && formData?.interestFavouriteRead?.length ? formData?.interestFavouriteRead : [],rules:"",name: "favourite read",error: ""},
                interestMusic:{value: formData?.interestMusic && formData?.interestMusic?.length ? formData?.interestMusic : [],rules:"",name: "music",error: ""},
                interest:{value: formData?.interest && formData?.interest?.length ? formData?.interest : [],rules:"",name: "interest",error: ""},
                interestHobbies:{value: formData?.interestHobbies && formData?.interestHobbies?.length ? formData?.interestHobbies : [],rules:"",name: "hobbies",error: ""}
            });
        }
    }, [formData,userProfileStatus]);

    const nextStep = async(value) => {
        setIsLoading(true);
        let obj = {
            ...userProfileDetail,
            userId:userProfileStatus._id,
            step:step,
            completedStep:step
        }
        if(step ===  1){
            obj.horoscopeDateOfBirth=new Date(value.horoscopeDateOfBirth.value);
            obj.basicMotherTongue=value.basicMotherTongue.value ? Number(value.basicMotherTongue.value) : null;
            obj.basicReligion=value.basicReligion.value ? Number(value.basicReligion.value) : null;
            if(value?.alternateMobile1?.value){
                obj.alternateMobile1 = {
                    mobileNumber:value.alternateMobile1.value,
                    countryCode:value.alternateMobile1.countryCode
                }
            }else{
                obj.alternateMobile1 = {
                    mobileNumber:'',
                    countryCode:''
                }
            }
            if(value?.alternateMobile2?.value){
                obj.alternateMobile2 = {
                    mobileNumber:value.alternateMobile2.value,
                    countryCode:value.alternateMobile2.countryCode
                }
            }else{
                obj.alternateMobile2 = {
                    mobileNumber:'',
                    countryCode:''
                }
            }
            obj.horoscopeTimeOfBirth=value.horoscopeTimeOfBirth.value ? value.horoscopeTimeOfBirth.value : '';
            obj.horoscopeStateOfBirth=value.horoscopeStateOfBirth.value ? value.horoscopeStateOfBirth.value : '';
            obj.horoscopeCityOfBirth=value.horoscopeCityOfBirth.value ? value.horoscopeCityOfBirth.value : '';
            obj.horoscopeBelieveInHoroscope=value.horoscopeBelieveInHoroscope.value ? Number(value.horoscopeBelieveInHoroscope.value) : null;
            obj.horoscopeMatch=value.horoscopeMatch.value ? Number(value.horoscopeMatch.value) : null;
            obj.horoscopeManglik=value.horoscopeManglik.value ? Number(value.horoscopeManglik.value) : null;
            obj.basicWillingToMarry=value.basicWillingToMarry.value;
            obj.basicGor=value.basicGor.value;
            obj.basicCaste=value.basicCaste.value ? Number(value.basicCaste.value) : null;
        }else if(step ===  2){
            obj.basicMaritalStatus=value.basicMaritalStatus.value ? Number(value.basicMaritalStatus.value) : null;
            obj.basicHeight=value.basicHeight.value ? Number(value.basicHeight.value) : null;
            obj.basicDisability=value.basicDisability.value ? Number(value.basicDisability.value) : null;
            if(value.basicDisability.value == 2){
                obj.basicDisabilityDetail=value.basicDisabilityDetail.value ? value.basicDisabilityDetail.value : '';
            }else {
                obj.basicDisabilityDetail='';
            }
            obj.basicHaveAChildren=value?.basicHaveAChildren?.value ? Number(value?.basicHaveAChildren?.value) : null;
            if(obj.basicHaveAChildren === 2){
                obj.basicWidowedRemark= '';
                obj.basicDivorcedRemark='';
                obj.basicAwaitingDivorcedRemark='';
                obj.basicNumberOfChildrens=0;
            }else{
                obj.basicWidowedRemark=value?.basicWidowedRemark?.value || '';
                obj.basicDivorcedRemark=value?.basicDivorcedRemark?.value || '';
                obj.basicAwaitingDivorcedRemark=value?.basicAwaitingDivorcedRemark?.value || '';
                obj.basicNumberOfChildrens=value?.basicNumberOfChildrens?.value;
            }
            obj.basicWeight=value.basicWeight.value ? value.basicWeight.value : null;
            obj.basicBodyType=value.basicBodyType.value ? value.basicBodyType.value : null;
            obj.lifeStyleEatingHabits=value.lifeStyleEatingHabits.value ? value.lifeStyleEatingHabits.value : null;
            obj.lifeStyleSmokingHabits=value.lifeStyleSmokingHabits.value ? value.lifeStyleSmokingHabits.value : null;
            obj.lifeStyleDrinkingHabits=value.lifeStyleDrinkingHabits.value ? value.lifeStyleDrinkingHabits.value : null;
            obj.lifeStyleOwnsHouse=value.lifeStyleOwnsHouse.value ? Number(value.lifeStyleOwnsHouse.value) : null;
            obj.lifeStyleOwnsCar=value.lifeStyleOwnsCar.value ? Number(value.lifeStyleOwnsCar.value) : null;
            obj.lifeStyleLanguages=value?.lifeStyleLanguages?.value && value?.lifeStyleLanguages?.value?.length ? value.lifeStyleLanguages.value : [];
        }else if(step ===  3){
            obj.educationHighestQualification=value.educationHighestQualification.value ? Number(value.educationHighestQualification.value) : null;
            obj.educationSubject=value.educationSubject.value;
            obj.educationInDetail=value.educationInDetail.value;
            obj.educationAdditionalDegree=value.educationAdditionalDegree.value ? Number(value.educationAdditionalDegree.value) : null;
            obj.educationEmployeedIn=value.educationEmployeedIn.value ? Number(value.educationEmployeedIn.value) : null;
            obj.educationOccupation=value.educationOccupation.value ? Number(value.educationOccupation.value) : null;
            obj.educationAnnualIncome=value.educationAnnualIncome.value ? Number(value.educationAnnualIncome.value) : null;
            obj.educationWorkLocation=value.educationWorkLocation.value;
            obj.locationCountry=value.locationCountry.value ? value.locationCountry.value : '';
            obj.locationState=value.locationState.value ? value.locationState.value : '';
            obj.locationCity=value.locationCity.value ? value.locationCity.value : '';
            obj.locationResident=value.locationResident.value ? value.locationResident.value : null;
            obj.educationCollege=value.educationCollege.value ? value.educationCollege.value : '';
            obj.educationOrganization=value.educationOrganization.value ? value.educationOrganization.value : '';
            obj.educationOccupationDetail=value.educationOccupationDetail.value ? value.educationOccupationDetail.value : '';
        }else if(step ===  4){
            obj.familyFatherOccupation=value.familyFatherOccupation.value ? value.familyFatherOccupation.value : null;
            obj.familyMotherOccupation=value.familyMotherOccupation.value ? value.familyMotherOccupation.value : null;
            obj.familySister=value.familySister.value ? value.familySister.value : null;
            obj.familyMarriedSister=value.familyMarriedSister.value ? value.familyMarriedSister.value : null;
            obj.familyBrother=value.familyBrother.value ? value.familyBrother.value : null;
            obj.familyMarriedBrother=value.familyMarriedBrother.value ? value.familyMarriedBrother.value : null;
            obj.familyStatus=value.familyStatus.value ? value.familyStatus.value : null;
            obj.familyType=value.familyType.value ? value.familyType.value : null;
            obj.familyValues=value.familyValues.value ? value.familyValues.value : null;
            obj.familyIncome=value.familyIncome.value ? value.familyIncome.value : null;
            obj.familyLivingState=value.familyLivingState.value ? value.familyLivingState.value : null;
            obj.familyLivingCountry=value.familyLivingCountry.value ? value.familyLivingCountry.value : null;
            obj.familyLivingIn=value.familyLivingIn.value ? value.familyLivingIn.value : null;
            obj.familyLiveWithFamily=value.familyLiveWithFamily?.value ? Number(value.familyLiveWithFamily.value) : null;
        }else if(step ===  5){
            // obj.interestFavouriteVacationDestination=value.interestFavouriteVacationDestination.value && value.interestFavouriteVacationDestination.value.length ? value.interestFavouriteVacationDestination.value : [];
            // obj.interestFavouriteCusine=value.interestFavouriteCusine.value && value.interestFavouriteCusine.value.length ? value.interestFavouriteCusine.value : [];
            // obj.interestSportsFitness=value.interestSportsFitness.value && value.interestSportsFitness.value.length ? value.interestSportsFitness.value : [];
            // obj.interestPreferredMovies=value.interestPreferredMovies.value && value.interestPreferredMovies.value.length ? value.interestPreferredMovies.value : [];
            // obj.interestFavouriteTVShows=value.interestFavouriteTVShows.value && value.interestFavouriteTVShows.value.length ? value.interestFavouriteTVShows.value : [];
            // obj.interestDressStyle=value.interestDressStyle.value && value.interestDressStyle.value.length ? value.interestDressStyle.value : [];
            // obj.interestFavouriteRead=value.interestFavouriteRead.value && value.interestFavouriteRead.value.length ? value.interestFavouriteRead.value : [];
            // obj.interestMusic=value.interestMusic.value && value.interestMusic.value.length ? value.interestMusic.value : [];
            // obj.interest=value.interest.value && value.interest.value.length ? value.interest.value : [];
            // obj.interestHobbies=value.interestHobbies.value && value.interestHobbies.value.length ? value.interestHobbies.value :[];
            obj.aboutMe = value.aboutMe.value;
            obj.photos = obj?.photos?.length ? obj.photos : [];
        }
        try {
            await apiRequest("post", `${env.SETPROFILEREVIEW3}`,obj).then(async(response) => {
                if(response.status === 200){
                    setForm(prev => ({
                        ...prev,
                        ...value
                    }));
                    await dispatch(getUserProfileReviewAction(obj,true));
                    setIsLoading(false);
                }else{
                    setIsLoading(false);
                    toast.error('Please try it again', {
                        position: 'top-right',
                    });
                }
            }).catch((error) => {
                console.error('error in set profile',error);
                setIsLoading(false);
                toast.error('Please try it again', {
                    position: 'top-right',
                });  
            });
        } catch (e) {
            console.error('error in set profile',e);
            setIsLoading(false);
            toast.error('Please try it again', {
                position: 'top-right',
            });
        }
    };
    const prevStep = () => {
        setStep(step - 1);
    };
    const handleSubmitForm = async(value) => {
        setForm(prev => ({
            ...prev,
            ...value
        }));
        setIsPopUp(true);
    };
    const handleSubmit = async() => {
        setIsLoading(true);
        let obj = {
            userId:userProfileStatus._id,
            step:6
        }
        await apiRequest("post", `${env.SETPROFILEREVIEW3}`,obj).then(async(response) => {
            if(response.status === 200){
                let userDetailInfo = userProfileStatus;
                userDetailInfo = {
                    ...userDetailInfo,
                    userProfileStatus:3
                }
                await dispatch(getUser(userDetailInfo));
                toast.success('Added successfully', {
                    position: 'top-right',
                });
                navigate('/profile-under-review')
                setIsLoading(false);
                setIsPopUp(false);
            }else{
                setIsLoading(false);
                setIsPopUp(false);
                toast.error('Please try it again', {
                    position: 'top-right',
                });
            }
        }).catch((error) => {
            console.error('error in set profile',error);
            setIsLoading(false);
            setIsPopUp(false);
            toast.error('Please try it again', {
                position: 'top-right',
            });
        });
    };

    return (
        <>
            {
                step !== 6 ? 
                    <ToastContainer />
                : null
            }
            {
                isLoadingData || !step ? 
                    <>
                        <Spinner />
                        <div className='min-h-[600px]'></div>
                    </> 
                :
                <div>
                    {isLoading ? <Spinner /> : null}
                    <div className={'xl:max-w-[1140px] w-full sm:px-[15px] px-[22px] xms:px-[30px] mx-auto'}>
                        <div className={`mx-auto my-[60px] md:my-[125px] max-w-[1070px] bg-[#ead7ab] relative rounded-[25px] py-[36px] xl:after:bg-no-repeat xl:after:bg-auto xl:after:w-[150px] xl:after:h-[150px] xl:after:bottom-[-24px] xl:after:rotate-180 xl:after:left-[-40px] xl:after:bg-top xl:after:absolute xl:after:bg-[url("../../assets/images/png/border_design.png")] xl:before:bg-no-repeat xl:before:w-[150px] xl:before:h-[150px] xl:before:top-[-24px] xl:before:right-[-40px] xl:before:bg-top xl:before:absolute xl:before:bg-[url("../../assets/images/png/border_design.png")] xl:before:bg-auto`}>
                            <div className='mx-auto px-[20px] lg:pt-[14px]'>
                                <div className='max-w-[820px] mx-auto grid md:grid-cols-6 xms:grid-cols-3 grid-cols-2 py-[10px] bg-[#f6ecc8] shadow-[2px_2px_25px_rgba(0,0,0,0.2)] lg:rounded-[50px] rounded-[15px] pl-[12px] pr-[12px]'>
                                    <div className='flex justify-center w-full text-center xl:after:absolute xl:after:w-[2px] xl:after:h-[20px] xl:after:right-[-2px] xl:after:top-[50%] xl:after:translate-y-[-50%] xl:relative lg:after:bg-white'>
                                        <span className={`${step >= 1 ? 'bg-[#ed1c24] text-white font-family-Quicksand-Medium' : 'text-[#931217] font-family-Quicksand-Bold'} leading-[normal] md:w-[102px] w-[90px] h-[45px] flex items-center justify-center py-[6px] rounded-[50px] text-[20px] md:mb-0 mb-[10px]`}>Step 1</span>
                                    </div>
                                    <div className='flex justify-center w-full text-center xl:after:absolute xl:after:w-[2px] xl:after:h-[20px] xl:after:right-[-2px] xl:after:top-[50%] xl:after:translate-y-[-50%] xl:relative lg:after:bg-white'>
                                        <span className={`${step >= 2 ? 'bg-[#ed1c24] text-white font-family-Quicksand-Medium' : 'text-[#931217] font-family-Quicksand-Bold'} leading-[normal] md:w-[102px] w-[90px] h-[45px] flex items-center justify-center py-[6px] rounded-[50px] text-[20px] md:mb-0 mb-[10px]`}>Step 2</span>
                                    </div>
                                    <div className='flex justify-center w-full text-center xl:after:absolute xl:after:w-[2px] xl:after:h-[20px] xl:after:right-[-2px] xl:after:top-[50%] xl:after:translate-y-[-50%] xl:relative lg:after:bg-white'>
                                        <span className={`${step >= 3 ? 'bg-[#ed1c24] text-white font-family-Quicksand-Medium' : 'text-[#931217] font-family-Quicksand-Bold'} leading-[normal] md:w-[102px] w-[90px] h-[45px] flex items-center justify-center py-[6px] rounded-[50px] text-[20px] md:mb-0 mb-[10px]`}>Step 3</span>
                                    </div>
                                    <div className='flex justify-center w-full text-center xl:after:absolute xl:after:w-[2px] xl:after:h-[20px] xl:after:right-[-2px] xl:after:top-[50%] xl:after:translate-y-[-50%] xl:relative lg:after:bg-white'>
                                        <span className={`${step >= 4 ? 'bg-[#ed1c24] text-white font-family-Quicksand-Medium' : 'text-[#931217] font-family-Quicksand-Bold'} leading-[normal] md:w-[102px] w-[90px] h-[45px] flex items-center justify-center py-[6px] rounded-[50px] text-[20px]`}>Step 4</span>
                                    </div>
                                    <div className='flex justify-center w-full text-center xl:after:absolute xl:after:w-[2px] xl:after:h-[20px] xl:after:right-[-2px] xl:after:top-[50%] xl:after:translate-y-[-50%] xl:relative lg:after:bg-white'>
                                        <span className={`${step >= 5 ? 'bg-[#ed1c24] text-white font-family-Quicksand-Medium' : 'text-[#931217] font-family-Quicksand-Bold'} leading-[normal] md:w-[102px] w-[90px] h-[45px] flex items-center justify-center py-[6px] rounded-[50px] text-[20px]`}>Step 5</span>
                                    </div>
                                    <div className='flex justify-center w-full text-center'>
                                        <span className={`${step >= 6 ? 'bg-[#ed1c24] text-white font-family-Quicksand-Medium' : 'text-[#931217] font-family-Quicksand-Bold'} leading-[normal] md:w-[102px] w-[90px] h-[45px] flex items-center justify-center py-[6px] rounded-[50px] text-[20px] md:mb-0`}>Step 6</span>
                                    </div>
                                </div>
                                <div className='max-w-[900px] mx-auto'>

                                    <div className={`mt-[25px] ${step === 6 ? '' : 'lg:grid-cols-2 grid grid-cols-1'}`}>
                                        {                    
                                            step === 1 ? 
                                                <Step1 form={form} nextStep={nextStep} userProfileStatus={userProfileDetail} />
                                            : step === 2 ?
                                                <Step2 form={form} nextStep={nextStep} prevStep={prevStep} />
                                            : step === 3 ?
                                                <Step3 form={form} nextStep={nextStep} prevStep={prevStep} />
                                            :step === 4 ?
                                                <Step4 form={form} nextStep={nextStep} prevStep={prevStep} />
                                            :step === 5 ?
                                                <Step5 form={form} nextStep={nextStep} prevStep={prevStep} />
                                            :step === 6 ?
                                                <Step6 form={form} prevStep={prevStep} userProfile={userProfileDetail} handleSubmitForm={handleSubmitForm} />
                                            : null
                                        }
                                    </div>
                                </div>
                                {
                                    isPopUp ?
                                        <Popup
                                            onClose={() => setIsPopUp(false)}
                                            onConfirm={handleSubmit}
                                            closeBtnName={'Close'}
                                            submitBtnName={'Continue'}
                                            submitClass={'leading-[normal] text-white bg-[#ed1c24] py-[10px] px-[15px] rounded-[50px] font-family-Quicksand-SemiBold text-[18px]'}
                                            closeClass={'leading-[normal] text-white bg-[#e1b923] py-[10px] px-[15px] rounded-[50px] font-family-Quicksand-SemiBold text-[18px] ml-[0.25rem]'}
                                            classWrapper={'bg-[#ead7ab] md:max-w-[960px] w-full md:px-[35px] md:pt-[50px] md:pb-[24px] xms:px-[20px] px-[10px] text-center py-[15px] rounded-[20px] shadow-lg relative'}
                                            mainClassWrapper={'p-[10px]'}
                                        >
                                            <div>
                                                <h2 className={'ms:pb-[23px] pb-[15px] relative text-[#ed1c24] text-center md:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular lg:text-[36px] text-[34px] after:bg-no-repeat after:w-[100%] ms:after:h-[100%] after:h-[62px] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Welcome to Rishtamatrimony..!!</h2>
                                                <p className="pt-[20px] ms:pb-[25px] text-[20px] text-[#262626] font-family-Quicksand-Regular text-stroke">NAMASTE <strong>{userProfileDetail?.firstName || userProfileDetail?.lastName ? `${userProfileDetail?.firstName} ${userProfileDetail?.lastName}` : userProfileStatus?.firstName || userProfileStatus?.lastName ? `${userProfileStatus?.firstName} ${userProfileStatus?.lastName}` : ''}</strong></p>
                                            </div>
                                        </Popup> 
                                    : 
                                    
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
            }
        </>
    )
};

export default FormStep;