import { useSelector } from 'react-redux';
import Button from '../../../atom/button.jsx';
import React, { useEffect, useState } from 'react';
import useValidation from "../../../../utils/validation.js";
import TextArea from '../../../atom/textarea.jsx';

const Step5 = ({ form, nextStep, prevStep }) => {
    //useSelecter
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    //validation
    const { checkErrors, checkAllFields } = useValidation();
    //useState
    const [formData, setFormData] = useState({
        // interestFavouriteVacationDestination: form.interestFavouriteVacationDestination,
        // interestFavouriteCusine: form.interestFavouriteCusine,
        // interestSportsFitness: form.interestSportsFitness,
        // interestPreferredMovies: form.interestPreferredMovies,
        // interestFavouriteTVShows: form.interestFavouriteTVShows,
        // interestDressStyle: form.interestDressStyle,
        // interestFavouriteRead: form.interestFavouriteRead,
        // interestMusic: form.interestMusic,
        // interest: form.interest,
        // interestHobbies: form.interestHobbies,
        aboutMe:{...form.aboutMe,value:form.aboutMe.value ? form.aboutMe.value : `Hi there, I live in ${form?.locationState?.value}, ${form?.locationCountry?.value}. My education is a ${form?.educationHighestQualification?.value ? dropDown?.['degree']?.option.find(e => e.key === Number(form?.educationHighestQualification?.value)).name : ''}. I work as a ${form?.educationOccupation?.value ? dropDown?.['occupation']?.option.find(e => e.key === Number(form?.educationOccupation?.value)).name : ''}. I am looking for someone that I can see as my friend and my partner. Someone special  that I can depend on and someone that can rely on me. I hope to hear from you soon. Your messages will be replied to. Take care`}
    });
    // const [totalLength, setTotalLength] = useState(0); 

    //function
    const handleCheck = () => {
        checkAllFields(formData).then((valid) => {
            setFormData({ ...formData });
            // && totalLength <= 5
            if (valid) {
                nextStep({ ...formData });
            }
        });
    };

    // const handleSelect = (e, id) => {
    //     const selected = [...formData[id].value]; // Copy current array

    //     // Toggle selection
    //     if (selected.includes(e.key)) {
    //         // If already selected, remove it
    //         selected.splice(selected.indexOf(e.key), 1);
    //         setTotalLength(prev => prev - 1);
    //     } else {
    //         // If not selected, add it
    //         selected.push(e.key);
    //         setTotalLength(prev => prev + 1);
    //     }
    //     checkErrors({
    //         'field': formData[id],
    //         'name': id,
    //         'validations': formData[id].rules,
    //         'value': selected
    //     });
    //     setFormData({
    //         ...formData,
    //         [id]: { ...formData[id], value: selected }
    //     });
    // };
    const handleSelectAboutMe = (e) => {
        checkErrors({
            'field': formData.aboutMe,
            'name': 'about me',
            'validations': formData.aboutMe.rules,                
            'value': e.value
        });
        setFormData({ ...formData, aboutMe: {...formData.aboutMe,value:e.value}});
    };

    //useEffect
    useEffect(() => {
        setFormData({
            // interestFavouriteVacationDestination: form.interestFavouriteVacationDestination,
            // interestFavouriteCusine: form.interestFavouriteCusine,
            // interestSportsFitness: form.interestSportsFitness,
            // interestPreferredMovies: form.interestPreferredMovies,
            // interestFavouriteTVShows: form.interestFavouriteTVShows,
            // interestDressStyle: form.interestDressStyle,
            // interestFavouriteRead: form.interestFavouriteRead,
            // interestMusic: form.interestMusic,
            // interest: form.interest,
            // interestHobbies: form.interestHobbies,
            aboutMe:{...form.aboutMe,value:form.aboutMe.value ? form.aboutMe.value : `Hi there, I live in ${form?.locationState?.value}, ${form?.locationCountry?.value}. My education is a ${form?.educationHighestQualification?.value ? dropDown?.['degree']?.option.find(e => e.key === Number(form?.educationHighestQualification?.value)).name : ''}. I work as a ${form?.educationOccupation?.value ? dropDown?.['occupation']?.option.find(e => e.key === Number(form?.educationOccupation?.value)).name : ''}. I am looking for someone that I can see as my friend and my partner. Someone special  that I can depend on and someone that can rely on me. I hope to hear from you soon. Your messages will be replied to. Take care`}
        });
        // const interestHobbiesLength = form.interestHobbies.value && form.interestHobbies.value.length ? form.interestHobbies.value.length : 0;
        // const interestLength = form.interest.value && form.interest.value.length ? form.interest.value.length : 0;
        // const interestMusicLength = form.interestMusic.value && form.interestMusic.value.length ? form.interestMusic.value.length : 0;
        // const interestFavouriteRead = form.interestFavouriteRead.value && form.interestFavouriteRead.value.length ? form.interestFavouriteRead.value.length : 0;
        // const interestDressStyle = form.interestDressStyle.value && form.interestDressStyle.value.length ? form.interestDressStyle.value.length : 0;
        // const interestFavouriteTVShows = form.interestFavouriteTVShows.value && form.interestFavouriteTVShows.value.length ? form.interestFavouriteTVShows.value.length : 0;
        // const interestPreferredMovies = form.interestPreferredMovies.value && form.interestPreferredMovies.value.length ? form.interestPreferredMovies.value.length : 0;
        // const interestSportsFitness = form.interestSportsFitness.value && form.interestSportsFitness.value.length ? form.interestSportsFitness.value.length : 0;
        // const interestFavouriteCusine = form.interestFavouriteCusine.value && form.interestFavouriteCusine.value.length ? form.interestFavouriteCusine.value.length : 0;
        // const interestFavouriteVacationDestination = form.interestFavouriteVacationDestination.value && form.interestFavouriteVacationDestination.value.length ? form.interestFavouriteVacationDestination.value.length : 0;
        // const totalLengths = interestHobbiesLength+interestLength + interestMusicLength +interestFavouriteRead +interestDressStyle +interestFavouriteTVShows +interestPreferredMovies +interestSportsFitness +interestFavouriteCusine+interestFavouriteVacationDestination;
        // setTotalLength(totalLengths);
    }, [form]);

    return (
        <>
            <p className='lg:col-span-2 font-family-Quicksand-Medium text-[16px] text-[#262626] text-center text-stroke-2 mb-[20px]'>Let's write about your Interest & More</p>
            <div className='lg:col-span-2 mb-4'>
                <label className='text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'>Write a few words to get to know you better *</label>
                <TextArea
                    class='resize-none font-family-Quicksand-Medium text-[18px] text-stroke-2 h-[140px] w-full p-[15px] rounded-[12px]'
                    modelValue={formData?.aboutMe?.value}
                    onKeyUp={(e) => handleSelectAboutMe(e)}
                />
                {formData?.aboutMe?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.aboutMe.error}</small>
                }
            </div>
            {/* <div className="px-[15px]">
                <h5 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]">Hobbies</h5>
                <div className='flex items-center flex-wrap my-[10px]'>
                    {dropDown?.['hobbies']?.option.map((element, index) => (
                        <span onClick={() => handleSelect(element, 'interestHobbies')} className={`${formData?.interestHobbies?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                    ))}
                </div>
            </div>
            <div className="px-[15px]">
                <h5 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]">Interest</h5>
                <div className='flex items-center flex-wrap my-[10px]'>
                    {dropDown?.['interest']?.option.map((element, index) => (
                        <span onClick={() => handleSelect(element, 'interest')} className={`${formData?.interest?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                    ))}
                </div>
            </div>
            <div className="px-[15px]">
                <h5 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]">Music</h5>
                <div className='flex items-center flex-wrap my-[10px]'>
                    {dropDown?.['music']?.option.map((element, index) => (
                        <span onClick={() => handleSelect(element, 'interestMusic')} className={`${formData?.interestMusic?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                    ))}
                </div>
            </div>
            <div className="px-[15px]">
                <h5 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]">Favourite Read</h5>
                <div className='flex items-center flex-wrap my-[10px]'>
                    {dropDown?.['favouriteRead']?.option.map((element, index) => (
                        <span onClick={() => handleSelect(element, 'interestFavouriteRead')} className={`${formData?.interestFavouriteRead?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                    ))}
                </div>
            </div>
            <div className="px-[15px]">
                <h5 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]">Dress Style</h5>
                <div className='flex items-center flex-wrap my-[10px]'>
                    {dropDown?.['dressStyle']?.option.map((element, index) => (
                        <span onClick={() => handleSelect(element, 'interestDressStyle')} className={`${formData?.interestDressStyle?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                    ))}
                </div>
            </div>
            <div className="px-[15px]">
                <h5 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]">Favourite TV Shows</h5>
                <div className='flex items-center flex-wrap my-[10px]'>
                    {dropDown?.['favouriteTvShows']?.option.map((element, index) => (
                        <span onClick={() => handleSelect(element, 'interestFavouriteTVShows')} className={`${formData?.interestFavouriteTVShows?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                    ))}
                </div>
            </div>
            <div className="px-[15px]">
                <h5 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]">Preferred Movies</h5>
                <div className='flex items-center flex-wrap my-[10px]'>
                    {dropDown?.['preferredMovies']?.option.map((element, index) => (
                        <span onClick={() => handleSelect(element, 'interestPreferredMovies')} className={`${formData?.interestPreferredMovies?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                    ))}
                </div>
            </div>
            <div className="px-[15px]">
                <h5 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]">Sports & Fitness</h5>
                <div className='flex items-center flex-wrap my-[10px]'>
                    {dropDown?.['sportsFitness']?.option.map((element, index) => (
                        <span onClick={() => handleSelect(element, 'interestSportsFitness')} className={`${formData?.interestSportsFitness?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                    ))}
                </div>
            </div>
            <div className="px-[15px]">
                <h5 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]">Favourite Cuisine</h5>
                <div className='flex items-center flex-wrap my-[10px]'>
                    {dropDown?.['favouriteCuisine']?.option.map((element, index) => (
                        <span onClick={() => handleSelect(element, 'interestFavouriteCusine')} className={`${formData?.interestFavouriteCusine?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                    ))}
                </div>
            </div>
            <div className="px-[15px]">
                <h5 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]">Favourite Vacation Destination</h5>
                <div className='flex items-center flex-wrap my-[10px]'>
                    {dropDown?.['favouriteVacationDestination']?.option.map((element, index) => (
                        <span onClick={() => handleSelect(element, 'interestFavouriteVacationDestination')} className={`${formData?.interestFavouriteVacationDestination?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                    ))}
                </div>
            </div>
            {totalLength > 5 &&
                <small className="px-[15px] leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">Interest & More value should be less then 5 value</small>
            } */}
            <div className='lg:col-span-2 lg:mt-[25px] lg:mb-[11px] mt-4 flex md:justify-between justify-end z-10'>
                <Button
                    onClick={prevStep} 
                    buttonName={'Previous'}
                    class={'block leading-[normal] bg-[#ed1c24] rounded-[50px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] ms:px-[30px] px-[15px] md:mr-0 mr-[10px]'}
                />
                <Button
                    onClick={handleCheck} 
                    buttonName={'Next'}
                    class={'block leading-[normal] bg-[#ed1c24] rounded-[50px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] ms:px-[30px] px-[15px]'}
                />
            </div>
        </>
    );
};

export default Step5;