// import { apiRequest } from '../../services/index.js';
import { getUserWithoutAuth } from '../reducers/userWithOutAuthReducer.js';
// import * as env from '../../config/env.js';

// Actions
export const getUserWithoutAuthentication = (data) => async dispatch => {
    try {
        dispatch(getUserWithoutAuth(data)); 
    } catch (e) {
        console.error('user step error',e.message);
    }
};