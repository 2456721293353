import { useEffect, useState } from "react";
import Input from "../atom/input.jsx";
const Text = (props) => {
    const [labelName,setLabelName] = useState(props.label? props.label :'');
    const [value, setValue] = useState(props.modelValue);
    useEffect(() => {
        setValue(props.modelValue);
    }, [props.modelValue]);
    useEffect(() => {
        setLabelName(props.label);
    }, [props.label]);
    return(
        <>
            {labelName && <label className={props.class ? props.class : ''}>{labelName}</label>}
            <Input
                inputClass={props.inputClass ? props.inputClass : ''}
                inputId={props.inputId ? props.inputId : ''}
                type={props.type ? props.type : "text"}
                placeHolder={props.placeHolder ? props.placeHolder : ''}
                name={props.name ? props.name : ''}
                modelValue={value}
                isDisabled={props.isDisabled ? props.isDisabled : false}
                isReadonly={props.isReadonly ? props.isReadonly : false}
                isDirectFocus={props.isDirectFocus ? props.isDirectFocus : false}
                isRequired={props.isRequired ? props.isRequired : false}
                isOutline={props.isOutline ? props.isOutline : true}
                onChange={(value,event) => props.onChange ? props.onChange(value,event) : ''}
                onFocus={(event) => props.onFocus ? props.onFocus(event) : ''}
                onBlur={(event) => props.onBlur ? props.onBlur(event) : ''}
                onClick={(event) => props.onClick ? props.onClick(event) : ''}
                onKeyPress={(event) => props.onKeyPress ? props.onKeyPress(event) : ''}
                onKeyUp={(event) => props.onKeyUp ? props.onKeyUp(event) : ''}
                onKeyDown={(event) => props.onKeyDown ? props.onKeyDown(event) : ''}
                onPaste={(event) => props.onPaste ? props.onPaste(event) : ''}
                onMouseUp={(event) => props.onMouseUp ? props.onMouseUp(event) : ''}
                onMouseDown={(event) => props.onMouseDown ? props.onMouseDown(event) : ''}
                max={props.max ? props.max : ''}
                min={props.min ? props.min : ''}
            />
        </>
    )
}
export default Text;