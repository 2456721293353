import { useEffect, useState } from "react";
import Input from "../../atom/input.jsx";
import "./index.css"
const RadioButton = (props) => {
    const [labelName] = useState(props.label? props.label :'');
    const [value, setValue] = useState(props.modelValue);
    useEffect(() => {
        setValue(props.modelValue);
    }, [props.modelValue]);
    return(
        <div className={`flex items-center ${props.radioClassWrapper ? props.radioClassWrapper : ''}`}>
            <Input
                inputId={props.inputId ? props.inputId : ''}
                type="radio"
                checked={props.checked ? props.checked : false}
                inputClass={props.inputClass ? props.inputClass : ''}
                name={props.name ? props.name : ''}
                modelValue={value}
                isDisabled={props.isDisabled ? props.isDisabled : false}
                isReadonly={props.isReadonly ? props.isReadonly : false}
                isDirectFocus={props.isDirectFocus ? props.isDirectFocus : false}
                isRequired={props.isRequired ? props.isRequired : false}
                height={props.height ? props.height : '20px'}
                width={props.width ? props.width : '20px'}
                isOutline={props.isOutline ? props.isOutline : true}
                onChange={(value,event) => props.onChange ? props.onChange(value,event) : ''}
                onFocus={(event) => props.onFocus ? props.onFocus(event) : ''}
                onBlur={(event) => props.onBlur ? props.onBlur(event) : ''}
                onClick={(event) => props.onClick ? props.onClick(event) : ''}
            />
            {labelName && <label className={`${props.class ? props.class : ''} cursor-pointer`} htmlFor={value}>{labelName}</label>}
        </div>
    )
}

export default RadioButton;