import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // assuming you're using react-router for routing
import "../../../components/molecules/radio/index.css";
import { getCheckOutAction } from '../../../stores/action/setCheckOutAction';
import { useDispatch, useSelector } from 'react-redux';
import { getPlanAction } from '../../../stores/action/getPlanAction';
import Spinner from '../../atom/spinner/spinner';
import { apiRequest } from '../../../services';
import * as env from '../../../config/env.js';

const MembershipAddOn = () => {
    const plansData = useSelector(state => state.getPlanReducer.getPlan);
    const location = useLocation();
    // const history = useHistory();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadings,setIsLoadings] = useState(false);
    const userDetails = useSelector(state => state?.user?.user);

    const [selectedRelationshipManager, setSelectedRelationshipManager] = useState({ duration: '', price: 0, id:'' });
    const [selectedFeatureDisplay, setSelectedFeatureDisplay] = useState({ duration: '', price: 0, id:'' });
    const [totalMembershipPrice, setTotalMembershipPrice] = useState();
    const [currentPlan, setCurrentPlan] = useState();
    const [packages,setPackages] = useState();

    useEffect(() => {
        const fetchPlans = async () => {
            setIsLoading(true);
            if (!plansData) {
                await dispatch(getPlanAction());
            }else{
                const params = new URLSearchParams(location.search);
                let plan = params.get('plan') || 'gold';
                const selectedPlan = plansData?.find(p => p.planName.toLowerCase() === plan.toLowerCase() && p.planType === 'plan');
                if (!selectedPlan) {
                    plan = 'gold';
                    params.set('plan', 'gold');
                    navigate({ search: params.toString() });
                }
                
                // Step 1: Filter for 'addOn' plans
                const addOnPlans = plansData?.filter(plan => plan.planType === 'addOn') || [];
                
                // Step 2: Group by duration
                const groupedPlans = addOnPlans.reduce((acc, plan) => {
                    const duration = plan.planDuration === 3 ? 3 :
                                    plan.planDuration === 6 ? 6 : 12;
                    
                    if (!acc[duration]) {
                        acc[duration] = { duration, relationshipManager: 0, featureDisplay: 0 };
                    }
                    
                    if (plan?.planName?.toLowerCase() === 'relationship manager') {
                        acc[duration].relationshipManager = plan.price;
                        acc[duration].relationshipManagerId = plan._id;
                    } else if (plan?.planName?.toLowerCase() === 'feature display') {
                        acc[duration].featureDisplay = plan.price;
                        acc[duration].featureDisplayId = plan._id;
                    }
                    
                    return acc;
                }, {});
                
                setPackages(Object.values(groupedPlans))
                setCurrentPlan(selectedPlan);
                setTotalMembershipPrice(selectedPlan?.price || 0);
                setIsLoading(false);
            }
        };

        fetchPlans();
    }, [location.search, plansData, dispatch, navigate]);

    const handleSelection = (type, duration, price,id) => {
        if (type === 'Relationship Manager') {
            setSelectedRelationshipManager({ duration, price,id });
        } else if (type === 'Feature Display') {
            setSelectedFeatureDisplay({ duration, price,id });
        }
    };

    const clearSelection = (type) => {
        if (type === 'Relationship Manager') {
            setSelectedRelationshipManager({ duration: '', price: 0 });
        } else if (type === 'Feature Display') {
            setSelectedFeatureDisplay({ duration: '', price: 0 });
        }
    };
    const handleNavigation = async () => {
        try {
            setIsLoadings(true);
            const planArray = [
                currentPlan._id,
                selectedRelationshipManager.id,
                selectedFeatureDisplay.id
            ].filter(Boolean);
            const requestObject = {
                userId: userDetails.userId,
                planIds: planArray
            };
            
            const response = await apiRequest("post", `${env.GETESTIMATEPLANDATE}`, requestObject);
            
            if (response.status === 200 && response.data?.data?.length) {
                const planDate = response.data.data;
                const updatedPlan = {
                    ...currentPlan,
                    membershipPrice: totalMembershipPrice,
                    relationshipManagerDuration: selectedRelationshipManager.duration,
                    relationshipManagerPrice: selectedRelationshipManager.price,
                    relationshipManagerId: selectedRelationshipManager.id,
                    featureDisplayDuration: selectedFeatureDisplay.duration,
                    featureDisplayPrice: selectedFeatureDisplay.price,
                    featureDisplayId: selectedFeatureDisplay.id,
                    totalPrice: totalMembershipPrice + totalRelationshipManagerPrice + totalFeatureDisplayPrice
                };
    
                planDate.forEach((e) => {
                    switch (e?.planName?.toLowerCase()) {
                        case 'relationship manager':
                            updatedPlan.relationshipManagerStartDate = e.planStartDate;
                            updatedPlan.relationshipManagerExpiryDate = e.planExpiryDate;
                            break;
                        case 'feature display':
                            updatedPlan.featureDisplayStartDate = e.planStartDate;
                            updatedPlan.featureDisplayExpiryDate = e.planExpiryDate;
                            break;
                        default:
                            updatedPlan.planTypeStartDate = e.planStartDate;
                            updatedPlan.planTypeExpiryDate = e.planExpiryDate;
                            break;
                    }
                });
                await dispatch(getCheckOutAction(updatedPlan));
            }else{
                setIsLoadings(false);    
            }
        } catch (error) {
            console.error('Error in getting date:', error);
            setIsLoadings(false);
        } finally {
            setIsLoadings(false);
            navigate('/checkout');
        }
    };
    

    const totalRelationshipManagerPrice = selectedRelationshipManager.price;
    const totalFeatureDisplayPrice = selectedFeatureDisplay.price;

    return (
        <>
            {isLoadings && <Spinner />}
            {
                isLoading ? <Spinner /> :
                <div className={'member_ship_add_on xl:max-w-[1140px] lg:max-w-[100%] xl:mt-[90px] xl:mb-[100px] lg:mt-[60px] lg:mb-[60px] mt-[40px] mb-[40px] w-full px-[15px] mx-auto'}>
                    <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-5 gap-8">
                        <div className='md:col-span-3 lg:col-span-1'>
                            <h2 className={'text-[#ed1c24] leading-[1.2] font-family-ZCOOLXiaoWei-Regular lg:text-[36px] md:text-[34px] text-[30px] mb-[20px]'}>Add-on Package</h2>
                            <div className="rounded-[12px] bg-[#ead7ab] xms:py-[27px] xms:px-[28px] px-[15px] py-[15px]">
                                <table className="table-auto w-full text-left">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2"></th>
                                            <th className="leading-[normal] text-[#931217] xms:text-[18px] text-[16px] font-family-Quicksand-Bold pb-[20px] align-baseline">Relationship Manager</th>
                                            <th className="leading-[normal] text-[#931217] xms:text-[18px] text-[16px] font-family-Quicksand-Bold pb-[20px] align-baseline">Feature Display</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {packages?.map((pkg) => (
                                            <tr key={pkg.duration}>
                                                <td className="py-[17px] xms:text-[20px] text-[16px] border-t border-t-[#b7892d] font-family-Quicksand-Regular text-stroke w-[33.333%] leading-[normal]">{pkg.duration} Months</td>
                                                <td className="py-[17px] xms:text-[20px] text-[16px] border-t border-t-[#b7892d] font-family-Quicksand-Regular text-stroke w-[33.333%] leading-[normal]">
                                                    <input
                                                        type="radio"
                                                        name="relationshipManager"
                                                        className='setting-styled-radioBtn opacity-0 absolute'
                                                        checked={selectedRelationshipManager?.duration === pkg.duration}
                                                        onChange={() => handleSelection('Relationship Manager', pkg.duration, pkg.relationshipManager,pkg.relationshipManagerId)}
                                                    />
                                                    <label onClick={() => handleSelection('Relationship Manager', pkg.duration, pkg.relationshipManager,pkg.relationshipManagerId)} className="flex items-center font-family-Quicksand-Regular xms:text-[20px] text-[16px] text-stroke cursor-pointer leading-[20px] text-[#666] relative">₹{pkg.relationshipManager}</label>
                                                </td>
                                                <td className="py-[17px] xms:text-[20px] text-[16px] border-t border-t-[#b7892d] font-family-Quicksand-Regular text-stroke w-[33.333%] leading-[normal]">
                                                    <input
                                                        type="radio"
                                                        name="featureDisplay"
                                                        className='setting-styled-radioBtn opacity-0 absolute'
                                                        checked={selectedFeatureDisplay?.duration === pkg.duration}
                                                        onChange={() => handleSelection('Feature Display', pkg.duration, pkg.featureDisplay,pkg.featureDisplayId)}
                                                    />
                                                    <label onClick={() => handleSelection('Feature Display', pkg.duration, pkg.featureDisplay,pkg.featureDisplayId)} className="flex items-center font-family-Quicksand-Regular xms:text-[20px] text-[16px] text-stroke cursor-pointer leading-[20px] text-[#666] relative">₹{pkg.featureDisplay}</label>
                                                </td>
                                            </tr>
                                        ))}
                                        {/* {plansData?.filter(e => e.planName === 'Relationship manager')?.map((pkg) => (
                                            <tr key={pkg.planDuration}>
                                                <td className="py-[17px] xms:text-[20px] text-[16px] border-t border-t-[#b7892d] font-family-Quicksand-Regular text-stroke w-[33.333%] leading-[normal]">{pkg.planDuration} Months</td>
                                                <td className="py-[17px] xms:text-[20px] text-[16px] border-t border-t-[#b7892d] font-family-Quicksand-Regular text-stroke w-[33.333%] leading-[normal]">
                                                    <input
                                                        type="radio"
                                                        name="relationshipManager"
                                                        className='setting-styled-radioBtn opacity-0 absolute'
                                                        checked={selectedRelationshipManager?.duration === pkg.planDuration}
                                                        onChange={() => handleSelection('Relationship Manager', pkg.planDuration, pkg.price)}
                                                    />
                                                    <label onClick={() => handleSelection('Relationship Manager', pkg.planDuration, pkg.price)} className="flex items-center font-family-Quicksand-Regular xms:text-[20px] text-[16px] text-stroke cursor-pointer leading-[20px] text-[#666] relative">₹{pkg.price}</label>
                                                </td>
                                            </tr>
                                        ))}
                                        {plansData?.filter(e => e.planName === 'Feature Display')?.map((pkg) => (
                                            <tr key={pkg.planDuration}>
                                                <td className="py-[17px] xms:text-[20px] text-[16px] border-t border-t-[#b7892d] font-family-Quicksand-Regular text-stroke w-[33.333%] leading-[normal]">
                                                    <input
                                                        type="radio"
                                                        name="featureDisplay"
                                                        className='setting-styled-radioBtn opacity-0 absolute'
                                                        checked={selectedFeatureDisplay?.duration === pkg.planDuration}
                                                        onChange={() => handleSelection('Feature Display', pkg.planDuration, pkg.price)}
                                                    />
                                                    <label onClick={() => handleSelection('Feature Display', pkg.planDuration, pkg.price)} className="flex items-center font-family-Quicksand-Regular xms:text-[20px] text-[16px] text-stroke cursor-pointer leading-[20px] text-[#666] relative">₹{pkg.price}</label>
                                                </td>
                                            </tr>
                                        ))} */}
                                        <tr>
                                            <td className=""></td>
                                            <td className="">
                                                <button className="text-[#931217] text-[16px] leading-[normal] my-[10px]" onClick={() => clearSelection('Relationship Manager')}>
                                                    Clear
                                                </button>
                                            </td>
                                            <td className="">
                                                <button className="text-[#931217] text-[16px] leading-[normal] my-[10px]" onClick={() => clearSelection('Feature Display')}>
                                                    Clear
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='md:col-span-2 lg:col-span-1'>
                            <h2 className={'border-b border-b-[#b7892d] pb-[20px] text-[#ed1c24] leading-[1.2] font-family-ZCOOLXiaoWei-Regular lg:text-[36px] md:text-[34px] text-[30px] mb-[15px]'}>You have selected</h2>
                            <div className="">
                                <div className="mb-[15px] border-b border-b-[#b7892d]">
                                    <span className="leading-[1.5] font-family-Quicksand-Regular text-[20px] text-[#262626] text-stroke block">Membership</span>
                                    <div className='pb-[15px] leading-[normal] flex items-center justify-between'>
                                        <span className="lg:max-w-[fit-content] md:max-w-[210px] xms:max-w-[fit-content] max-w-[210px]"><span className='text-[18px] mr-[5px] text-[#931217] font-family-Quicksand-Bold'>{currentPlan?.planName}</span> <span className='leading-[1.5] font-family-Quicksand-Regular text-[#262626] text-stroke text-[16px]'>Unlock upto {currentPlan?.unlockProfiles} Profile - {currentPlan?.planDuration} Months</span></span>
                                        <span className="text-stroke font-family-Quicksand-Bold text-[18px] leading-[normal] text-[#931217]">₹ {totalMembershipPrice}</span>
                                    </div>
                                </div>
                                {selectedRelationshipManager.duration ? 
                                    <div className="mb-[15px] border-b border-b-[#b7892d]">
                                        <span className="leading-[1.5] font-family-Quicksand-Regular text-[20px] text-[#262626] text-stroke block">Add-on Package</span>
                                        <div className='pb-[15px] leading-[normal] flex items-center justify-between'>
                                            <span className='lg:max-w-[fit-content] md:max-w-[210px] xms:max-w-[fit-content] max-w-[210px]'><span className='text-[18px] mr-[5px] text-[#931217] font-family-Quicksand-Bold'>Relationship Manager</span> <span className='leading-[1.5] font-family-Quicksand-Regular text-[#262626] text-stroke text-[16px]'>{selectedRelationshipManager.duration && `${selectedRelationshipManager.duration} Months`}</span></span>
                                            <span className="text-stroke font-family-Quicksand-Bold text-[18px] leading-[normal] text-[#931217]">₹ {totalRelationshipManagerPrice}</span>
                                        </div>
                                    </div>
                                    : null
                                }
                                {selectedFeatureDisplay.duration ? 
                                    <div className="mb-[15px] border-b border-b-[#b7892d]">
                                        <span className="leading-[1.5] font-family-Quicksand-Regular text-[20px] text-[#262626] text-stroke block">Add-on Package</span>
                                        <div className='pb-[15px] leading-[normal] flex items-center justify-between'>
                                            <span className='lg:max-w-[fit-content] md:max-w-[210px] xms:max-w-[fit-content] max-w-[210px]'><span className='text-[18px] mr-[5px] text-[#931217] font-family-Quicksand-Bold'>Feature Display</span> <span className='leading-[1.5] font-family-Quicksand-Regular text-[#262626] text-stroke text-[16px]'>{selectedFeatureDisplay.duration && `${selectedFeatureDisplay.duration} Months`}</span></span>
                                            <span className="text-stroke font-family-Quicksand-Bold text-[18px] leading-[normal] text-[#931217]">₹ {totalFeatureDisplayPrice}</span>
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className="font-family-Quicksand-Bold text-[24px] text-[#931217] leading-[normal]">
                                    Total<span className="float-right">₹ {totalMembershipPrice + totalRelationshipManagerPrice + totalFeatureDisplayPrice}</span>
                                </div>
                                <button className="leading-[1.5] font-family-Quicksand-Regular text-[20px] mb-[10px] bg-[#ed1c24] text-[#fff] py-[10px] px-[35px] rounded-[50px] mt-[25px] w-[max-content] ml-auto block" onClick={() => handleNavigation()}>
                                    Continue Payment
                                </button>
                                <Link className='block font-family-Quicksand-Semibold font-semibold w-[max-content] text-[18px] text-[#ed1c24] relative pr-[45px] ml-auto my-[10px] block leading-[normal] text-center after:w-[30px] after:h-[2px] after:absolute after:right-0 after:top-[50%] after:bg-[#ed1c24]' to={'/'}>Skip Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default MembershipAddOn;
