import ContactDetails from "../dashBoard/contactDetails";
import ViewProfileDetail from "./viewProfileDetail";
import ViewProfileLeft from "./viewProfileLeft";
import Slider from "react-slick";
import '../../atom/silderUserDetailComp/index.css'
import male from "../../../assets/images/png/male.png";
import female from '../../../assets/images/png/female.png';
import ClientWidthContext from '../../atom/clientWidth.jsx';
import { useContext, useEffect, useState } from "react";
import { apiRequest } from "../../../services/index.js";
import * as env from '../../../config/env.js';
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../atom/spinner/spinner.jsx";
import Popup from "../../atom/popUp.jsx";
import { useDispatch, useSelector } from "react-redux";
import {getDashboard} from "../../../stores/reducers/getDashboardReducer.js";

const ViewProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id,currentUserId } = useParams();
    const [isPopUp,setIsPopUp] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadings,setIsLoadings] = useState(false);
    const [userDetailobject,setUserDetailobject] = useState(null);
    const dashboardDetail = useSelector(state => state?.dashboard?.dashboard);
    const [title,setTitle] = useState('')
    const [settings,setSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false
    });
    const clientWidth = useContext(ClientWidthContext);
    useEffect(() => {
        if(id && currentUserId){
            try {
                setIsLoading(true);
                apiRequest("get", `${env.GETPROFILEDETAIL}/${currentUserId}/${id}`).then((response) => {
                    if(response.status === 200){
                        if(response?.data?.data && response?.data?.data?.length){
                            setUserDetailobject(response?.data?.data?.[0] || null);
                            if(response?.data?.data?.[0].photos && response?.data?.data?.[0].photos?.length){
                                if(response?.data?.data?.[0].photos?.length === 1){
                                    setSettings(prev => ({
                                        ...prev,
                                        infinite:false,
                                        dots:false
                                    }));
                                }else{
                                    setSettings(prev => ({
                                        ...prev,
                                        infinite:true,
                                        dots:true
                                    }));
                                }
                            }else{
                                setSettings(prev => ({
                                    ...prev,
                                    infinite:false,
                                    dots:false
                                }));
                            }
                        }
                        setIsLoading(false);
                    }else{
                        setIsLoading(false);   
                    }
                }).catch((error) => {
                    console.error('Error in getting the user detail',error);   
                    setIsLoading(false);
                })
            } catch(error){
                console.error('error in getting the user detail',error);
                setIsLoading(false);
            }
        }
    },[currentUserId,id]);
    const handleUnlock = () => {
        try {
            setIsLoadings(true);
            let unlockProfile = {
                unlockProfileId:id,
                userId:currentUserId
            };
            apiRequest("post", `${env.UNLOCKPROFILE}`,unlockProfile).then(async(response) => {
                if(response.status === 200 && response.data.data && Object.keys(response.data.data).length){
                    let dashBoard = {...dashboardDetail};
                    dashBoard = {
                        ...dashBoard,
                        currentCredit: dashBoard.currentCredit - 1 < 0 ? 0 : dashBoard.currentCredit - 1
                    };
                    setUserDetailobject(prev => ({
                        ...prev,
                        ...response.data.data,
                        isProfileUnlock:true
                    }));
                    await dispatch(getDashboard(dashBoard));
                    setIsLoadings(false);
                }else{
                    setIsLoadings(false);
                }
            }).catch((error) => {
                console.error('Error unlock profile',error);
                if(error?.response?.data?.isCreditPlanNotActivate){
                    setTitle('Please upgrade your plan to unlock the profile.');
                    setIsPopUp(true);
                }else if(error?.response?.data?.isNotEnoughCredit){
                    setTitle("You don't have enough credit to unlock the profile please upgrade your plan.");
                    setIsPopUp(true);
                }
                setIsLoadings(false);
            })
        } catch(error){
            console.error('error unlock profile',error);
            if(error?.response?.data?.isCreditPlanNotActivate){
                setTitle('Please upgrade your plan to unlock the profile.');
                setIsPopUp(true);
            }else if(error?.response?.data?.isNotEnoughCredit){
                setTitle("You don't have enough credit to unlock the profile please upgrade your plan.");
                setIsPopUp(true);
            }
            setIsLoadings(false);
        }
    };
    const getImageUrl = (photoUrls, size) => {
        const photo = photoUrls.find(photo => photo[size]);
        return photo ? photo[size] : null;
    };
    const handleSubmit = () => {
        navigate('/membership');
    };
    return (
        <>
            {
                isPopUp ?
                    <Popup
                        onClose={() => setIsPopUp(false)}
                        onConfirm={handleSubmit}
                        submitBtnName={'View All Plans'}
                        submitClass={'font-family-sans-serif text-[#fff] bg-[#ed1c24] border-[#ed1c24] rounded-[50px] px-[20px] py-[8px] leading-[1.5] text-[1rem] border'}
                        closeClass={'font-family-sans-serif text-[#fff] ml-[0.25rem] bg-[#e1b923] border-[#e1b923] rounded-[50px] px-[20px] py-[8px] leading-[1.5] text-[1rem] border'}
                        classWrapper={'bg-[#ead7ab] m-[10px] md:px-[30px] px-[15px] text-center py-[15px] rounded-[20px] shadow-lg relative'}
                    >
                        <div>
                            <p className="text-[18px] font-family-Quicksand-Medium text-stroke pt-[30px]">{title}</p>
                        </div>
                    </Popup>
                : 
                null
            }
            {isLoadings && <Spinner />}
            <div className={`${isLoading ? 'min-h-[430px]' : ''}`}>
                {
                    isLoading ? 
                        <>
                            <Spinner /> 
                        </>
                    : 
                    (
                        userDetailobject && Object?.keys(userDetailobject)?.length && userDetailobject?.userStatus === 4 ? 
                            <div className="lg:w-full lg:max-w-[1140px]  md:max-w-[720px] max-w-full mx-auto xl:mt-[140px] xl:mb-[100px] lg:mt-[60px] lg:mb-[60px] mt-[40px] mb-[40px]">
                                <div className="mb-[25px]">
                                    <div className="lg:flex w-full lg:flex-row-reverse">
                                        {clientWidth <= 1023 ? 
                                            <div className="px-[15px] lg:w-[full] xms:w-[400px] w-[full] lg:mx-0 mx-auto">
                                                <Slider {...settings}>
                                                    {
                                                        userDetailobject?.photos && userDetailobject?.photos?.length ? 
                                                        (userDetailobject.photos.map((e,ind) =>
                                                            <img key={ind} className="mb-[10px] lg:h-[325px] h-[400px] rounded-[15px] object-cover object-position-top-center" src={getImageUrl(e.photoUrls, "346x325")} alt={ind} /> 
                                                        ))
                                                        : userDetailobject?.gender === 1 ? <img className="mb-[10px] lg:h-[325px] h-[400px] rounded-[15px] object-cover object-position-top-center" src={male} alt={'male'} /> : <img className="mb-[10px] lg:h-[325px] h-[400px] rounded-[15px] object-cover object-position-top-center" src={female} alt={'female'} />
                                                    }
                                                </Slider>
                                            </div>
                                        : ''}
                                        <div className="lg:max-w-[67%] lg:w-[67%] w-full px-[15px]">
                                            <ViewProfileDetail
                                                userDetailobject={userDetailobject}
                                                handleUnlock={handleUnlock}
                                            />
                                        </div>
                                        <div className="lg:max-w-[33%] lg:w-[33%] w-full px-[15px]">
                                            {clientWidth > 1023 ? 
                                                <Slider {...settings}>
                                                    {
                                                        userDetailobject?.photos && userDetailobject?.photos?.length ? 
                                                        (userDetailobject.photos.map((e,ind) =>
                                                            <img key={ind} className="mb-[10px] lg:h-[325px] h-[400px] rounded-[15px] object-cover object-position-top-center" src={getImageUrl(e.photoUrls, "346x325")} alt={ind} /> 
                                                        ))
                                                        : userDetailobject?.gender === 1 ? <img className="mb-[10px] lg:h-[325px] h-[400px] rounded-[15px] object-cover object-position-top-center" src={male} alt={'male'} /> : <img className="mb-[10px] lg:h-[325px] h-[400px] rounded-[15px] object-cover object-position-top-center" src={female} alt={'female'} />
                                                    }
                                                </Slider> 
                                            : ''}
                                            <h4 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular md:text-[30px] leading-[1.2] text-[24px] text-stroke">Contact Details</h4>
                                            <ContactDetails 
                                                isEdit={true}
                                                userDetailobject={userDetailobject}
                                                handleUnlock={handleUnlock}
                                            />
                                            <ViewProfileLeft />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : 
                        <div className="text-center lg:w-full xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] max-w-full mx-auto lg:pt-[175px] lg:pb-[175px] md:pt-[120px] md:pb-[120px] pt-[140px] pb-[140px] lg:justify-center">
                            <h2 className={'pb-[10px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px]'}>No Such Profile Found</h2>
                            <Link to="/search" className={'inline-block leading-[normal] my-[10px] text-stroke text-[#fff] cursor-pointer xl:py-[8px] xl:px-[31px] py-[5px] px-[15px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'}>Search</Link> 
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default ViewProfile;