import { useContext, useState } from "react";
import SearchById from "./savedAndIdSearch";
import Button from "../../atom/button";
import BasicSearch from "./basicSearch";
import AdvanceSearch from "./advanceSearch";
import DeepSearch from "./deepSearch";
import { useNavigate } from 'react-router-dom';
import { useSearch } from "../../atom/SearchContext";
import { useSelector } from "react-redux";
import ClientWidthContext from '../../atom/clientWidth';

function Search() {
    const clientWidth = useContext(ClientWidthContext);
    const navigate = useNavigate();
    const { setSearchData } = useSearch();
    const [activeClass,setActiveClass] = useState(1);
    const [tittle,setTittle] = useState('Basic Search');
    const userDetails = useSelector(state => state?.user?.user);
    const handleClick = (value,name) => {
        setActiveClass(value);
        setTittle(name);
    };
    const handleReset = (obj) => {
    };
    const handleApply = (obj,id) => {
        let object = {};
        if(id === 'basic'){
            object = {
                basicReligion:obj.basicReligion.value ? obj.basicReligion.value : '',
                basicCaste:obj.basicCaste.value ? obj.basicCaste.value : '',
                basicMotherTongue:obj.basicMotherTongue.value ? obj.basicMotherTongue.value : '',
                basicageMax:obj.basicAgeMax.value ? obj.basicAgeMax.value : '',
                basicageMin:obj.basicAgeMin.value ? obj.basicAgeMin.value : '',
                searchType:'Basic'
            };
        }else if(id === 'deep') {
            object = {
                basicReligion:obj.basicReligion.value ? obj.basicReligion.value : '',
                basicCaste:obj.basicCaste.value ? obj.basicCaste.value : '',
                basicMotherTongue:obj.basicMotherTongue.value ? obj.basicMotherTongue.value : '',
                basicageMax:obj.basicAgeMax.value ? obj.basicAgeMax.value : '',
                basicHeightMin:obj.basicHeightMin.value ? obj.basicHeightMin.value : '',
                basicHeightMax:obj.basicHeightMax.value ? obj.basicHeightMax.value : '',
                basicMaritalStatus:obj.basicMaritalStatus.value ? obj.basicMaritalStatus.value : '',
                locationCountry:obj.locationCountry.value ? obj.locationCountry.value : '',
                locationState:obj.locationState.value ? obj.locationState.value : '',
                locationCity:obj.locationCity.value ? obj.locationCity.value : '',
                basicageMin:obj.basicAgeMin.value ? obj.basicAgeMin.value : '',
                basicDisability:obj.basicDisability.value ? obj.basicDisability.value : '',
                horoscopeManglik:obj.basicManglik.value ? obj.basicManglik.value : '',
                lifeStyleEatingHabits:obj.lifeStyleEatingHabits.value ? obj.lifeStyleEatingHabits.value : '',
                lifeStyleSmokingHabits:obj.lifeStyleSmokingHabits.value ? obj.lifeStyleSmokingHabits.value : '',
                lifeStyleDrinkingHabits:obj.lifeStyleDrinkingHabits.value ? obj.lifeStyleDrinkingHabits.value : '',
                searchType:'Deep',
                saveSearchAs:obj.saveSearch?.value ? obj.saveSearch?.value : ''
            };
            if(obj?.saveSearch?.value){
                object.saveSearchAs = obj.saveSearch?.value ? obj.saveSearch?.value : ''
            }
        }else if(id === 'advance'){
            object = {
                basicReligion:obj.basicReligion.value ? obj.basicReligion.value : '',
                basicCaste:obj.basicCaste.value ? obj.basicCaste.value : '',
                basicMotherTongue:obj.basicMotherTongue.value ? obj.basicMotherTongue.value : '',
                basicageMax:obj.basicAgeMax.value ? obj.basicAgeMax.value : '',
                basicHeightMin:obj.basicHeightMin.value ? obj.basicHeightMin.value : '',
                basicHeightMax:obj.basicHeightMax.value ? obj.basicHeightMax.value : '',
                basicMaritalStatus:obj.basicMaritalStatus.value ? obj.basicMaritalStatus.value : '',
                locationCountry:obj.locationCountry.value ? obj.locationCountry.value : '',
                locationState:obj.locationState.value ? obj.locationState.value : '',
                locationCity:obj.locationCity.value ? obj.locationCity.value : '',
                educationHighestQualification:obj.educationHighestQualification.value ? obj.educationHighestQualification.value : '',
                basicageMin:obj.basicAgeMin.value ? obj.basicAgeMin.value : '',
                searchType:'Advance',
                saveSearchAs:obj.saveSearch?.value ? obj.saveSearch?.value : ''
            };
            if(obj?.saveSearch?.value){
                object.saveSearchAs = obj.saveSearch?.value ? obj.saveSearch?.value : ''
            }
        }        
        setSearchData(object);
        navigate(`/matches?pageNumber=${1}`);
    };
    return (
        <>
            {
                !userDetails?.profileActiveDate || (userDetails?.profileActiveDate < new Date().getTime()) ? 
                <>
                    <div className="lg:w-full md:px-0 xl:max-w-[1140px] max-w-full mx-auto xl:mt-[109px] xl:mb-[100px] lg:mt-[60px] lg:mb-[60px] mt-[40px] mb-[40px]">
                        <div className='grid md:grid-cols-3 grid-cols-1'>
                            <div className="col-span-1 px-[15px]">
                                <SearchById />
                            </div>
                            <div className="col-span-2 px-[15px] xl:mt-[-18px]">
                                <div className="xms:flex xms:justify-between xms:items-baseline lg:items-center lg:flex-row xms:flex-col-reverse mb-[15px] mt-[15px] md:mt-0">
                                    {clientWidth >= 480 ? <h4 className="xl:text-[35px] md:text-[28px] text-[30px] text-[#ed1c24] leading-[normal] font-family-ZCOOLXiaoWei-Regular xl:leading-[52.8px] lg:leading-[45px]">{tittle}</h4> : ''}
                                    <div className="bg-[#E9D3A4] overflow-auto max-w-[max-content] h-[32px] rounded-[25px] flex lg:mb-0 mb-[15px]">
                                        <Button
                                            class={`${activeClass === 1 ? 'bg-[#ED1B24] text-white' : 'text-[#931217]'} xms:min-w-[150px] whitespace-nowrap min-w-[auto] block leading-[normal] text-[16px] py-[5px] px-[10px] rounded-[25px] font-family-Quicksand-Bold`}
                                            buttonName={'Basic Search'}
                                            onClick={() => handleClick(1,'Basic Search')}
                                        />
                                        <Button
                                            class={`${activeClass === 2 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#931217]'} xms:min-w-[150px] whitespace-nowrap min-w-[auto] block leading-[normal] text-[16px] py-[5px] px-[10px] rounded-[25px] font-family-Quicksand-Bold`}
                                            buttonName={'Advance Search'}
                                            onClick={() => handleClick(2,'Advance Search')}
                                        />
                                        <Button
                                            class={`${activeClass === 3 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#931217]'} xms:min-w-[150px] whitespace-nowrap min-w-[auto] block leading-[normal] text-[16px] py-[5px] px-[10px] rounded-[25px] font-family-Quicksand-Bold`}
                                            buttonName={'Deep Search'}
                                            onClick={() => handleClick(3,'Deep Search')}
                                        />
                                    </div>
                                    {clientWidth > 479 ? '' : <h4 className="xl:text-[35px] md:text-[28px] text-[30px] text-[#ed1c24] leading-[normal] font-family-ZCOOLXiaoWei-Regular xl:leading-[52.8px] lg:leading-[45px]">{tittle}</h4>}
                                </div>
                                <div>
                                    {
                                        activeClass === 1 ?
                                            <BasicSearch 
                                                handleReset={(obj) => handleReset(obj)}
                                                handleApply={(obj,id) => handleApply(obj,id)}
                                            />
                                        :
                                        activeClass === 2 ?
                                            <AdvanceSearch 
                                                handleReset={(obj) => handleReset(obj)}
                                                handleApply={(obj,id) => handleApply(obj,id)}
                                            />
                                        :
                                        activeClass === 3 ?
                                            <DeepSearch 
                                                handleReset={(obj) => handleReset(obj)}
                                                handleApply={(obj,id) => handleApply(obj,id)}
                                            />
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </> : 
                <div className="flex lg:w-full xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] max-w-full mx-auto lg:min-h-[500px] min-h-[400px] justify-center items-center">
                    <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Active Your Profile</h2>
                </div>
            }
        </>
    )
}

export default Search;