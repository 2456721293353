import { useState } from "react";
import Button from "../../atom/button";
import Dropdown from "../../atom/dropDown";
import { apiRequest } from "../../../services";
import * as env from '../../../config/env.js';
import useValidation from "../../../utils/validation.js";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../atom/spinner/spinner";
import { getUser } from "../../../stores/reducers/userReducer";
import moment from "moment";

const DeactiveProfile = ({form,submitForm}) => {
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state?.user?.user);
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const [isLoading,setIsLoading] = useState(false);
    const [formData,setFormData] = useState({
        deactivateProfile:form.deactivateProfile
    });
    const  { checkErrors,checkAllFields } = useValidation();
    const handleSubmit = () => {
        checkAllFields(formData).then((valid)=>{
            setFormData({...formData});
            if(valid){
                try {
                    setIsLoading(true);
                    let deactivated = {
                        userId:userDetails.userId,
                        days:formData?.deactivateProfile?.value ? dropDown?.['deactivateDays']?.option.find(e => e.key === Number(formData?.deactivateProfile?.value)).days : ''
                    };
                    apiRequest("post", env.DEACTIVEPROFILE,deactivated ).then(async(response) => {
                        if(response.status === 200){
                            toast.success('Updated successfully', {
                                position: 'top-right',
                            });
                            let actualDays = Number(deactivated.days);
                            let activateDate = new Date(new Date().setDate(new Date().getDate() + actualDays)).setHours(0, 0, 0, 0);
                            let userDetailInfo = userDetails;
                            userDetailInfo = {
                                ...userDetailInfo,
                                profileActiveDate:activateDate
                            }
                            await dispatch(getUser(userDetailInfo));
                            setIsLoading(false);
                            setFormData({ ...formData, deactivateProfile: {...formData.deactivateProfile,value:''}});
                        }else{
                            toast.error('Please try it again', {
                                position: 'top-right',
                            });
                            setIsLoading(false);
                        }
                    }).catch((error) => {
                        console.error('error',error);
                        if (error?.response?.data?.message) {
                            toast.error(error.response.data.message, {
                                position: 'top-right',
                            });
                        }
                        setIsLoading(false);
                    })
                } catch (error) {
                    console.error('error',error);
                    setIsLoading(false);
                }
            }
        });
    };
    const handleSubmitActive = () => {
        try {
            setIsLoading(true);
            let activated = {
                userId:userDetails.userId
            };
            apiRequest("post", env.ACTIVEPROFILE,activated ).then(async(response) => {
                if(response.status === 200){
                    toast.success('Updated successfully', {
                        position: 'top-right',
                    });
                    let userDetailInfo = userDetails;
                    userDetailInfo = {
                        ...userDetailInfo,
                        profileActiveDate:null
                    }
                    await dispatch(getUser(userDetailInfo));
                    setIsLoading(false);
                    setFormData({ ...formData, deactivateProfile: {...formData.deactivateProfile,value:''}});
                }else{
                    toast.error('Please try it again', {
                        position: 'top-right',
                    });
                    setIsLoading(false);
                }
            }).catch((error) => {
                console.error('error',error);
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message, {
                        position: 'top-right',
                    });
                }else{
                    toast.error('Please try it again', {
                        position: 'top-right',
                    });
                }
                setIsLoading(false);
            })
        } catch (error) {
            toast.error('Please try it again', {
                position: 'top-right',
            });
            console.error('Error in active profile',error);
        }
    };
    const handleSelect = (e,id) => {
        if(id === 'deactive'){
            checkErrors({
                'field': formData.deactivateProfile,
                'name': 'days',
                'validations': formData.deactivateProfile.rules,                
                'value': e.key
            });
            setFormData({ ...formData, deactivateProfile: {...formData.deactivateProfile,value:e.key}});
        }
    };
    return(
        <>
            {isLoading ? <Spinner /> : null}
            <ToastContainer />
            <div className="bg-[#f0e1b9] rounded-[15px] md:py-[25px] md:px-[30px] p-[15px]"> 
                <p className="tracking-[0.5px] text-stroke-4 pb-[15px] leading-[normal] mb-[20px] border-b-[#b7892d] border-b font-family-Quicksand-Bold text-[18px] text-[#931217]">Select the number of days / months you would like to keep your profile deactivated.</p>
                {!userDetails?.profileActiveDate || (userDetails?.profileActiveDate < new Date().getTime()) ?  
                    <>
                        <Dropdown
                            options={dropDown?.['deactivateDays'].option}
                            onUpdate={(e) => handleSelect(e,'deactive')}
                            multiSelect={false}
                            selectedValue={formData?.deactivateProfile?.value ? [formData?.deactivateProfile?.value] : []}
                            labelName={'days *'}
                            labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[0.5rem]'}
                            selectClass={'w-[150px] mb-[12px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:left-[120px] flex items-center text-stroke bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[42px]'}
                            wrapperClass={'relative'}
                            optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                            optionsWrapper={'bg-white'}
                        />
                        {formData?.deactivateProfile?.error &&
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.deactivateProfile.error}</small>
                        }
                    </> : null
                }
                <p className="font-family-Quicksand-Regular text-[18px] tracking-[0.5px] text-[#262626] text-stroke-4 leading-[30px] pb-[20px]">Note: Your profile will be activated after the selected time period elapses. E.G. If you select 15 days as the time period, your profile will be deactivated for 15 days and will be automatically activated on the 16th day. You will receive a mailer in the regard.</p>
                {
                    !userDetails?.profileActiveDate || (userDetails?.profileActiveDate < new Date().getTime()) ? null :
                    <div className="flex border-b border-b-[#b7892d] pb-[15px] mb-[20px]">
                        <span className="block font-family-Quicksand-Bold text-[20px] tracking-[0.5px] text-[#262626] text-stroke-4 leading-[30px]">Your profile deactivated till:</span>
                        <span className="block font-family-Quicksand-Bold text-[20px] tracking-[0.5px] text-[#ed1c24] text-stroke-4 leading-[30px]">{moment(userDetails?.profileActiveDate).format('YYYY-MM-DD')}</span> 
                    </div>
                }
                {
                    !userDetails?.profileActiveDate || (userDetails?.profileActiveDate < new Date().getTime()) ? 
                    <Button
                        class='leading-[normal] text-[#fff] cursor-pointer py-[8px] px-[30px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                        buttonName={'Deactivate Now'}
                        onClick={() => handleSubmit()}
                    /> 
                    : 
                    <Button
                        class='leading-[normal] text-[#fff] cursor-pointer py-[8px] px-[30px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                        buttonName={'Activate Now'}
                        onClick={() => handleSubmitActive()}
                    />
                        
                }
            </div>
        </>
    );
};

export default DeactiveProfile;