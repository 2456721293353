import { useEffect, useState } from "react";
import "./index.css";
import male from "../../../assets/images/png/male.png";
import female from "../../../assets/images/png/female.png";
import { useSelector } from "react-redux";
const ProfilePicture = (props) => {
    const userDetails = useSelector(state => state?.user?.user);
    const dashboardDetail = useSelector(state => state?.dashboard?.dashboard);
    const [userDetail,setUserDetail] = useState(userDetails ? userDetails : {});
    useEffect(() =>{
        setUserDetail(userDetails);
        setProfileImage(userDetails)
    },[userDetails]);
    const setProfileImage = (userDetail) => {
        if(userDetail){
            if (userDetail?.photos && userDetail?.photos.length > 0) {
                const defaultPhoto = userDetail.photos.find(photo => photo.isMainPhoto);
                if (defaultPhoto) {
                    const image250x260 = defaultPhoto.photoUrls.find(url => url['270x270']);
                    return image250x260 ? image250x260['270x270'] : (userDetail.gender === 1 ? male : female);
                }else{
                    return userDetail?.gender === 1 ? male : female;
                }
            } else {
                return userDetail?.gender === 1 ? male : female;
            }
        }
    };    
    return(
        <>
            {
                userDetails && Object.keys(userDetails).length ? 
                <div className={`bg-[#ead7ab] text-center rounded-[12px] ${props.bgWrapper ? props.bgWrapper : ''}`}> 
                    <div className={props.imageWrapper ? props.imageWrapper : ''}>
                        <img className="rounded-full" src={setProfileImage(userDetails)} alt="profileimage" />
                    </div>
                    <div className="mt-[30px]">
                        <p className="text-[#b7882c] font-family-Quicksand-Bold text-[20px] leading-[normal]">{dashboardDetail?.percentage}%</p>
                        <p className="text-[#ed1c24] leading-[normal] mt-[13px] mb-[13px] font-family-ZCOOLXiaoWei-Regular text-[30px]">{userDetail?.firstName} {userDetail?.lastName}</p>
                        <p className={`font-family-Quicksand-Bold leading-[normal] text-[16px] text-[#931217] ${dashboardDetail?.percentage === "100" ? '' : 'border-b-[1px] border-b-[#b7892d] mb-[22px] pb-[22px]'}`}>{userDetail.profileId}</p>
                        {
                            dashboardDetail?.isYouAndFamilyValueRemaining || dashboardDetail?.isPartnerPrefrenceRemaining  ? 
                                <h4 className="tracking-[0.5px] font-family-Quicksand-Bold xms:text-[20px] text-[16px] text-[#931217] mb-[12px] leading-[normal] text-left truncate" title="Add Details to your Profile">Add Details to your Profile</h4>
                            : ''
                        }
                        {
                            dashboardDetail?.isYouAndFamilyValueRemaining ? 
                                <div className="flex justify-between" title="Write about you & Family +20%">
                                    <p className="xms:text-[18px] text-[16px] text-stroke font-family-Quicksand-Medium mb-[8px] leading-[normal] truncate">Write about you & Family</p>
                                    <p className="xms:text-[18px] text-[16px] text-stroke font-family-Quicksand-Bold mb-[8px] leading-[normal]">+20%</p>
                                </div>
                            : null
                        }
                        {
                            dashboardDetail?.isPartnerPrefrenceRemaining ?
                                <div className="flex justify-between" title="Add partner preference +20%">
                                    <p className="xms:text-[18px] text-[16px] text-stroke font-family-Quicksand-Medium leading-[normal] truncate">Add partner preference</p>
                                    <p className="xms:text-[18px] text-[16px] text-stroke font-family-Quicksand-Bold leading-[normal]">+20%</p>
                                </div>
                            : null
                        }
                    </div>
                </div> : null
            }
        </>
    )
};

export default ProfilePicture;