import { useSelector } from 'react-redux';
import Button from '../../../atom/button.jsx';
import React, { useEffect, useState } from 'react';
import useValidation from "../../../../utils/validation.js";
import Dropdown from '../../../atom/dropDown.jsx';
import RadioButton from '../../../molecules/radio/radio.jsx';
import Autocomplete from "react-google-autocomplete";
import * as env from '../../../../config/env.js';

const Step4 = ({ form,prevStep,nextStep }) => {
    //dropdown option
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const  { checkErrors,checkAllFields } = useValidation();
    //useState
    const [formData,setFormData] = useState({
        familyStatus:form.familyStatus,
        familyValues:form.familyValues,
        familyFatherOccupation:form.familyFatherOccupation,
        familyMotherOccupation:form.familyMotherOccupation,
        familySister:form.familySister,
        familyMarriedSister:form.familyMarriedSister,
        familyBrother:form.familyBrother,
        familyMarriedBrother:form.familyMarriedBrother,
        familyType:form.familyType,
        familyIncome:form.familyIncome,
        familyLivingState:form.familyLivingState,
        familyLivingCountry:form.familyLivingCountry,
        familyLivingIn:form.familyLivingIn,
        familyLiveWithFamily:form.familyLiveWithFamily
    });

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            familyStatus:form.familyStatus,
            familyValues:form.familyValues,
            familyFatherOccupation:form.familyFatherOccupation,
            familyMotherOccupation:form.familyMotherOccupation,
            familySister:form.familySister,
            familyMarriedSister:form.familyMarriedSister,
            familyBrother:form.familyBrother,
            familyMarriedBrother:form.familyMarriedBrother,
            familyType:form.familyType,
            familyIncome:form.familyIncome,
            familyLivingState:form.familyLivingState,
            familyLivingCountry:form.familyLivingCountry,
            familyLivingIn:form.familyLivingIn,
            familyLiveWithFamily:form.familyLiveWithFamily
        }));
    },[form]);

    //function
    const handleSelect = (e,id) => {
        if(id === 'familyStatus'){
            checkErrors({
                'field': formData.familyStatus,
                'name': 'family status',
                'validations': formData.familyStatus.rules,                
                'value': e.key
            });
            setFormData({ ...formData, familyStatus: {...formData.familyStatus,value:e.key}});
        }else if(id === 'familyValues'){
            checkErrors({
                'field': formData.familyValues,
                'name': 'family values',
                'validations': formData.familyValues.rules,                
                'value': e.key
            });
            setFormData({ ...formData, familyValues: {...formData.familyValues,value:e.key}});
        }else if(id === 'familyFatherOccupation'){
            checkErrors({
                'field': formData.familyFatherOccupation,
                'name': 'father occupation',
                'validations': formData.familyFatherOccupation.rules,
                'value': e.key
            });
            setFormData({ ...formData, familyFatherOccupation: {...formData.familyFatherOccupation ,value:e.key}});
        }else if(id === 'familyMotherOccupation'){
            checkErrors({
                'field': formData.familyMotherOccupation,
                'name': 'mother occupation',
                'validations': formData.familyMotherOccupation.rules,
                'value': e.key
            });
            setFormData({ ...formData, familyMotherOccupation: {...formData.familyMotherOccupation ,value:e.key}});
        }else if(id === 'familySister'){
            checkErrors({
                'field': formData.familySister,
                'name': 'family sister',
                'validations': formData.familySister.rules,
                'value': e.key
            });
            setFormData({ ...formData, familySister: {...formData.familySister ,value:e.key},familyMarriedSister: {...formData.familyMarriedSister ,value:''}});
        }else if(id === 'familyMarriedSister'){
            checkErrors({
                'field': formData.familyMarriedSister,
                'name': 'family married sister',
                'validations': formData.familyMarriedSister.rules,
                'value': e.key
            });
            setFormData({ ...formData, familyMarriedSister: {...formData.familyMarriedSister ,value:e.key}});
        }else if(id === 'familyBrother'){
            checkErrors({
                'field': formData.familyBrother,
                'name': 'family brother',
                'validations': formData.familyBrother.rules,
                'value': e.key
            });
            setFormData({ ...formData, familyBrother: {...formData.familyBrother ,value:e.key},familyMarriedBrother: {...formData.familyMarriedBrother ,value:''}});
        }else if(id === 'familyMarriedBrother'){
            checkErrors({
                'field': formData.familyMarriedBrother,
                'name': 'family married brother',
                'validations': formData.familyMarriedBrother.rules,
                'value': e.key
            });
            setFormData({ ...formData, familyMarriedBrother: {...formData.familyMarriedBrother ,value:e.key}});
        }else if(id === 'familyType'){
            checkErrors({
                'field': formData.familyType,
                'name': 'family type',
                'validations': formData.familyType.rules,
                'value': e.key
            });
            setFormData({ ...formData, familyType: {...formData.familyType ,value:e.key}});
        }else if(id === 'familyIncome'){
            checkErrors({
                'field': formData.familyIncome,
                'name': 'family Income',
                'validations': formData.familyIncome.rules,
                'value': e.key
            });
            setFormData({ ...formData, familyIncome: {...formData.familyIncome ,value:e.key}});
        }else if(id === 'familyLiving'){
            const addressComponents = e?.address_components;
            let state = '';
            let country = '';
            let city = '';
    
            // Loop through address components and extract the relevant information
            addressComponents?.forEach(component => {
                const types = component.types;
                if (types.includes('administrative_area_level_1')) {
                    state = component.long_name; // or component.short_name
                }
                if (types.includes('country')) {
                    country = component.long_name; // or component.short_name
                }
                if (types.includes('locality') || types.includes('postal_town')) {
                    city = component.long_name; // or component.short_name
                }
            });
            checkErrors({
                'field': formData.familyLivingCountry,
                'name': 'family country',
                'validations': formData.familyLivingCountry.rules,
                'value': country
            });
            checkErrors({
                'field': formData.familyLivingState,
                'name': 'family state',
                'validations': formData.familyLivingState.rules,
                'value': state
            });
            checkErrors({
                'field': formData.familyLivingIn,
                'name': 'family livingIn',
                'validations': formData.familyLivingIn.rules,
                'value': city
            });
            setFormData(prevFormData => ({
                ...prevFormData,
                familyLivingState: { ...formData.familyLivingState, value: state },
                familyLivingIn: { ...formData.familyLivingIn, value: city },
                familyLivingCountry: { ...formData.familyLivingCountry, value: country }
            }));
        }else if(id === 'familyLiveWithFamily'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': formData.familyLiveWithFamily,
                'name': 'live with family',
                'validations': formData.familyLiveWithFamily.rules,                
                'value': values
            });
            setFormData({ ...formData, familyLiveWithFamily: {...formData.familyLiveWithFamily ,value:values}});
        }
    };
    const handleCheck = () => {
        checkAllFields(formData).then((valid)=>{
            setFormData({...formData});
            if(valid){
                nextStep(formData);
            }
        });
    };
    const handleChangeLivingWith = (value) => {
        if(!(value.target.value)){
            checkErrors({
                'field': formData.familyLivingCountry,
                'name': 'family country',
                'validations': formData.familyLivingCountry.rules,
                'value': ''
            });
            checkErrors({
                'field': formData.familyLivingState,
                'name': 'family state',
                'validations': formData.familyLivingState.rules,
                'value': ''
            });
            checkErrors({
                'field': formData.familyLivingIn,
                'name': 'family livingIn',
                'validations': formData.familyLivingIn.rules,
                'value': ''
            });
            setFormData(prevFormData => ({
                ...prevFormData,
                familyLivingState: { ...formData.familyLivingState, value: '' },
                familyLivingIn: { ...formData.familyLivingIn, value: '' },
                familyLivingCountry: { ...formData.familyLivingCountry, value: '' }
            }));
        }
    }

    return (
        <>
            <p className='lg:col-span-2 font-family-Quicksand-Medium text-[16px] text-[#262626] text-center text-stroke-2 mb-[20px]'>Let's write about your family information</p>
            <div className='lg:pr-[15px] mb-4'>
                <Dropdown
                    options={dropDown?.['fatherOccupation']?.option}
                    onUpdate={(e) => handleSelect(e,'familyFatherOccupation')}
                    multiSelect={false}
                    selectedValue={formData?.familyFatherOccupation?.value ? [formData?.familyFatherOccupation?.value] : []}
                    labelName={"Father's Occupation"}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <Dropdown
                    options={dropDown?.['motherOccupation']?.option}
                    onUpdate={(e) => handleSelect(e,'familyMotherOccupation')}
                    multiSelect={false}
                    selectedValue={formData?.familyMotherOccupation?.value ? [formData?.familyMotherOccupation?.value] : []}
                    labelName={"Mother's Occupation"}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <label className='text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'>Sister(s)</label>
                <div className='flex justify-between xms:flex-row flex-col'>
                    <Dropdown
                        options={dropDown?.['sisterBrother']?.option}
                        onUpdate={(e) => handleSelect(e,'familySister')}
                        multiSelect={false}
                        selectedValue={formData?.familySister?.value ? [formData?.familySister?.value] : []}
                        selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                        wrapperClass={'mb-[12px] mt-[5px] relative xms:w-[40.1%] w-full'}
                        optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                        optionsWrapper={'bg-white'}
                    />
                    <Dropdown
                        options={dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= formData?.familySister?.value)?.length ? dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= formData.familySister?.value) : dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= 0)}
                        onUpdate={(e) => handleSelect(e,'familyMarriedSister')}
                        multiSelect={false}
                        selectedValue={formData?.familyMarriedSister?.value ? [formData?.familyMarriedSister?.value] : []}
                        selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                        wrapperClass={'mb-[12px] mt-[5px] relative xms:w-[55.5%] w-full'}
                        optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                        optionsWrapper={'bg-white'}
                    />
                </div>
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <label className='text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'>Brother(s)</label>
                <div className='flex justify-between xms:flex-row flex-col'>
                    <Dropdown
                        options={dropDown?.['sisterBrother']?.option}
                        onUpdate={(e) => handleSelect(e,'familyBrother')}
                        multiSelect={false}
                        selectedValue={formData?.familyBrother?.value ? [formData?.familyBrother?.value] : []}
                        selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                        wrapperClass={'mb-[12px] mt-[5px] relative xms:w-[40.1%] w-full'}
                        optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                        optionsWrapper={'bg-white'}
                    />
                    <Dropdown
                        options={dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= formData?.familyBrother?.value)?.length ? dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= formData?.familyBrother?.value) : dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= 0)}
                        onUpdate={(e) => handleSelect(e,'familyMarriedBrother')}
                        multiSelect={false}
                        selectedValue={formData?.familyMarriedBrother?.value ? [formData?.familyMarriedBrother?.value] : []}
                        selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                        wrapperClass={'mb-[12px] mt-[5px] relative xms:w-[55.5%] w-full'}
                        optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                        optionsWrapper={'bg-white'}
                    />
                </div>
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <Dropdown
                    options={dropDown?.['familyStatus']?.option}
                    onUpdate={(e) => handleSelect(e,'familyStatus')}
                    multiSelect={false}
                    selectedValue={formData?.familyStatus?.value ? [formData?.familyStatus?.value] : []}
                    labelName={'Family Status *'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
                {formData?.familyStatus?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.familyStatus.error}</small>
                }
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <Dropdown
                    options={dropDown?.['familyType']?.option}
                    onUpdate={(e) => handleSelect(e,'familyType')}
                    multiSelect={false}
                    selectedValue={formData?.familyType?.value ? [formData?.familyType?.value] : []}
                    labelName={'Family Type'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <Dropdown
                    options={dropDown?.['familyValues']?.option}
                    onUpdate={(e) => handleSelect(e,'familyValues')}
                    multiSelect={false}
                    selectedValue={formData?.familyValues?.value ? [formData?.familyValues?.value] : []}
                    labelName={'Family Values *'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
                {formData?.familyValues?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.familyValues.error}</small>
                }
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <Dropdown
                    isInputSearch={true}
                    options={dropDown?.['annualIncome']?.option}
                    onUpdate={(e) => handleSelect(e,'familyIncome')}
                    multiSelect={false}
                    selectedValue={formData?.familyIncome?.value ? [formData?.familyIncome?.value] : []}
                    labelName={'Family Income'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <label className='text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'>Family Live In</label>
                <Autocomplete
                    apiKey={env.GOOGLEAPIKEY}
                    onChange={(e) => handleChangeLivingWith(e)}
                    onPlaceSelected={(place) => {handleSelect(place,'familyLiving')}}
                    types={['(regions)']}
                    fields={['address_components']}
                    className='w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'
                    placeholder={'Enter city country state'}
                    defaultValue={formData?.familyLivingIn?.value && formData?.familyLivingState?.value && formData?.familyLivingCountry?.value ? `${formData?.familyLivingIn?.value}, ${formData?.familyLivingState?.value}, ${formData?.familyLivingCountry?.value}` : ''}
                />
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <label className={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}>You Live With Your Family</label>
                <div className="grid lg:grid-cols-3 grid-cols-1">
                    {dropDown?.['yesNo']?.option.map((option, index) => (
                        <div key={index}>
                            <RadioButton
                                inputId={`${option.key}+liveWithFamily`}
                                modelValue={`${option.key}+liveWithFamily`}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                name="liveWithFamily"
                                onClick={(value) => handleSelect(value, 'familyLiveWithFamily')}
                                label={option.name}
                                class={'mt-[6px] ml-[10px] mb-[6px] leading-[normal] font-family-Quicksand-Medium text-[16px] text-[#262626] text-stroke'}
                                checked={`${formData?.familyLiveWithFamily?.value}+liveWithFamily` === `${option.key}+liveWithFamily` || false}
                                inputClass={`w-[20px] inline min-h-[30px] cursor-pointer`}
                                radioClassWrapper={'lg:mt-[5px] lg:mb-[2px]'}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className='lg:col-span-2 lg:mt-[25px] lg:mb-[11px] mt-4 flex md:justify-between justify-end z-10'>
                <Button
                    onClick={prevStep} 
                    buttonName={'Previous'}
                    class={'block leading-[normal] bg-[#ed1c24] rounded-[50px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] py-[10px] ms:px-[30px] px-[15px] md:mr-0 mr-[10px]'}
                />
                <Button
                    onClick={handleCheck} 
                    buttonName={'Next'}
                    class={'block leading-[normal] bg-[#ed1c24] rounded-[50px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] ms:px-[30px] px-[15px]'}
                />
            </div>
        </>
    );
};

export default Step4;