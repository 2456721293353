import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../atom/spinner/spinner";
import { apiRequest } from "../../../services";
import * as env from '../../../config/env.js';
import moment from "moment";

const TransactionHistory = () => {
    const [isLoading,setIsLoading] = useState(false);
    const userDetails = useSelector(state => state?.user?.user);
    const [transaction,setTransaction] = useState([]);
    useEffect(() => {
        try {
            setIsLoading(true);
            if(userDetails?.userId){
                apiRequest("get", `${env.GETTRANSACTION}/${userDetails?.userId}`).then(async(response) => {
                    if(response.status === 200 && response?.data?.data && response?.data?.data?.length){
                        setTransaction(response.data.data)
                        setIsLoading(false);
                    }else{
                        setIsLoading(false);
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    console.error('error in getting transaction',error);
                });
            }else{
                setIsLoading(false);    
            }
        } catch (error) {
            console.error('error in getting transaction',error);
            setIsLoading(false);
        }
    },[userDetails]);
    return (
        <>
            {
                isLoading ? <Spinner /> : 
                <div>
                    <div className={`bg-[#f0e1b9] rounded-[15px] md:py-[25px] md:px-[30px] p-[15px] w-full overflow-auto max-h-[500px] style-scroll-6-px`}>
                        {
                            transaction && transaction.length ? 
                                <table className={`table-auto w-full xl:table block overflow-scroll`}>
                                    <thead>
                                        <tr>
                                            <th className="pr-[15px] pb-[25px] text-left font-family-Quicksand-Bold text-[18px] text-[#931217] w-[290px] xl:min-w-[auto] min-w-[290px] leading-[normal]">Name</th>
                                            <th className="pr-[15px] pb-[25px] text-left font-family-Quicksand-Bold text-[18px] text-[#931217] w-[150px] xl:min-w-[auto] min-w-[150px] leading-[normal]">Purchase Date</th>
                                            <th className="pr-[15px] pb-[25px] text-left font-family-Quicksand-Bold text-[18px] text-[#931217] w-[155px] xl:min-w-[auto] min-w-[155px] leading-[normal]">Expiry Date</th>
                                            <th className="pb-[25px] text-right font-family-Quicksand-Bold text-[18px] text-[#931217] xl:min-w-[auto] min-w-[70px] leading-[normal]">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            transaction.map((e, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className={index + 1  === transaction.length ? '' : 'border-y border-y-[#b7892d]'}>
                                                        <td className="w-[290px] xl:min-w-[auto] min-w-[290px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke"><strong>{e.planName}</strong><p className="text-[16px] font-family-Quicksand-Regular text-[#262626] leading-[30px]">{e.productType === "plan" ? `Unlock upto ${e?.unlockProfiles || ''} profile - ${e.planDuration} Months` : `Add-on - ${e.planDuration} Months`}</p></td>
                                                        <td className="w-[150px] xl:min-w-[auto] min-w-[150px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e?.purchaseDate ? moment(e.purchaseDate).format('DD-MM-YYYY') : '-'}</td>
                                                        <td className="w-[155px] xl:min-w-[auto] min-w-[155px] leading-[1.5] text-left font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">{e?.planExpiryDate ? moment(e.planExpiryDate).format('DD-MM-YYYY') : '-'}</td>
                                                        <td className="leading-[1.5] xl:min-w-[auto] min-w-[70px] text-right font-family-Quicksand-Regular py-[15px] pb-[15px] text-[18px] text-stroke">₹ {e?.price || '-'}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        }
                                    </tbody>
                                </table> 
                            : (!isLoading && !(transaction && transaction.length) ? <span className="leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular">no record found</span> : null)
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default TransactionHistory;
