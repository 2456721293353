import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Button from "../../atom/button.jsx";
import DropDown from "../../atom/dropDown.jsx";
import useValidation from "../../../utils/validation.js";
import Autocomplete from "react-google-autocomplete";
import * as env from '../../../config/env.js';
import useCustomComposable from "../../../utils/composables/index.js";

const EditPartnerPreference = (props) => {
    const { focusOnDropdown,focusNextInput } = useCustomComposable();
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const [myProfileObject,setMyProfileObject] = useState(JSON.parse(JSON.stringify(props.profileObject)));
    const [changesInForm,setChangesInForm] = useState({});
    const  { checkErrors,checkAllFields } = useValidation();
    const [searchValue, setSearchValue] = useState([]);
    const handleDropDown = (nextInputId) => {
        focusOnDropdown(nextInputId);
    };
    const handleSelect = (e,id) => {
        if(id === 'basicAgeMax'){
            const updatedFormData = { ...myProfileObject };
            const value = e.key;

            if (id === 'basicAgeMax') {
                updatedFormData.basicAgeMax.value = value;
            } else {
                updatedFormData.basicAgeMin.value = value;
            }

            // Reset errors
            updatedFormData.basicAgeMin.error = '';
            updatedFormData.basicAgeMax.error = '';

            // Validate the heights
            const minHeight = updatedFormData.basicAgeMin.value;
            const maxHeight = updatedFormData.basicAgeMax.value;

            if (minHeight && maxHeight) {
                if (minHeight > maxHeight) {
                    updatedFormData.basicAgeMin.error = 'The min age should be smaller than max age';
                    updatedFormData.basicAgeMax.error = 'The max age should be greater than min age';
                }
            } else if (minHeight || maxHeight) {
                if (!minHeight) {
                    updatedFormData.basicAgeMin.error = 'The min age is required if max age is entered';
                }
                if (!maxHeight) {
                    updatedFormData.basicAgeMax.error = 'The max age is required if min age is entered';
                }
            }
            checkErrors({
                'field': myProfileObject.basicAgeMax,
                'name': 'basic age max',
                'validations': myProfileObject.basicAgeMax.rules,
                'value': e.key
            });
            handleDropDown('minheight');
            
            setChangesInForm({
                ...changesInForm,
                basicAgeMax: { ...changesInForm.basicAgeMax, value },
                basicAgeMin: { ...changesInForm.basicAgeMin },
            });
            setMyProfileObject({
                ...myProfileObject,
                basicAgeMax: { ...updatedFormData.basicAgeMax, value },
                basicAgeMin: { ...myProfileObject.basicAgeMin },
            });
        }else if(id === 'basicAgeMin'){
            const updatedFormData = { ...myProfileObject };
            const value = e.key;

            if (id === 'basicAgeMax') {
                updatedFormData.basicAgeMax.value = value;
            } else {
                updatedFormData.basicAgeMin.value = value;
            }

            // Reset errors
            updatedFormData.basicAgeMin.error = '';
            updatedFormData.basicAgeMax.error = '';

            // Validate the heights
            const minHeight = updatedFormData.basicAgeMin.value;
            const maxHeight = updatedFormData.basicAgeMax.value;

            if (minHeight && maxHeight) {
                if (minHeight > maxHeight) {
                    updatedFormData.basicAgeMin.error = 'The min age should be smaller than max age';
                    updatedFormData.basicAgeMax.error = 'The max age should be greater than min age';
                }
            } else if (minHeight || maxHeight) {
                if (!minHeight) {
                    updatedFormData.basicAgeMin.error = 'The min age field is required if max age is entered';
                }
                if (!maxHeight) {
                    updatedFormData.basicAgeMax.error = 'The max age field is required if min age is entered';
                }
            }
            checkErrors({
                'field': myProfileObject.basicAgeMin,
                'name': 'basic age min',
                'validations': myProfileObject.basicAgeMin.rules,
                'value': e.key
            });
            setChangesInForm({
                ...changesInForm,
                basicAgeMin: { ...changesInForm.basicAgeMin, value },
                basicAgeMax: { ...changesInForm.basicAgeMax},
            });
            setMyProfileObject({
                ...myProfileObject,
                basicAgeMin: { ...updatedFormData.basicAgeMin, value },
                basicAgeMax: { ...myProfileObject.basicAgeMax},
            });
        }else if(id === 'basicHeightMax'){
            const updatedFormData = { ...myProfileObject };
            const value = e.key;

            if (id === 'basicHeightMax') {
                updatedFormData.basicHeightMax.value = value;
            } else {
                updatedFormData.basicHeightMin.value = value;
            }

            // Reset errors
            updatedFormData.basicHeightMin.error = '';
            updatedFormData.basicHeightMax.error = '';

            // Validate the heights
            const minHeight = updatedFormData.basicHeightMin.value;
            const maxHeight = updatedFormData.basicHeightMax.value;

            if (minHeight && maxHeight) {
                if (minHeight > maxHeight) {
                    updatedFormData.basicHeightMin.error = 'The min height should be smaller than max height';
                    updatedFormData.basicHeightMax.error = 'The max height should be greater than min height';
                }
            } else if (minHeight || maxHeight) {
                if (!minHeight) {
                    updatedFormData.basicHeightMin.error = 'The min height is required if max height is entered';
                }
                if (!maxHeight) {
                    updatedFormData.basicHeightMax.error = 'The max height is required if min height is entered';
                }
            }

            checkErrors({
                'field': myProfileObject.basicHeightMax,
                'name': 'max height',
                'validations': myProfileObject.basicHeightMax.rules,
                'value': e.key
            });
            setChangesInForm({
                ...changesInForm,
                basicHeightMax: { ...changesInForm.basicHeightMax, value },
                basicHeightMin: { ...changesInForm.basicHeightMin },
            });
            setMyProfileObject({
                ...myProfileObject,
                basicHeightMax: { ...updatedFormData.basicHeightMax, value },
                basicHeightMin: { ...myProfileObject.basicHeightMin },
            });
            handleDropDown('religion');
        }else if(id === 'basicHeightMin'){
            const updatedFormData = { ...myProfileObject };
            const value = e.key;

            if (id === 'basicHeightMax') {
                updatedFormData.basicHeightMax.value = value;
            } else {
                updatedFormData.basicHeightMin.value = value;
            }

            // Reset errors
            updatedFormData.basicHeightMin.error = '';
            updatedFormData.basicHeightMax.error = '';

            // Validate the heights
            const minHeight = updatedFormData.basicHeightMin.value;
            const maxHeight = updatedFormData.basicHeightMax.value;

            if (minHeight && maxHeight) {
                if (minHeight > maxHeight) {
                    updatedFormData.basicHeightMin.error = 'The min height should be smaller than max height';
                    updatedFormData.basicHeightMax.error = 'The max height should be greater than min height';
                }
            } else if (minHeight || maxHeight) {
                if (!minHeight) {
                    updatedFormData.basicHeightMin.error = 'The min height is required if max height is entered';
                }
                if (!maxHeight) {
                    updatedFormData.basicHeightMax.error = 'The max height is required if min height is entered';
                }
            }
            checkErrors({
                'field': myProfileObject.basicHeightMin,
                'name': 'min height',
                'validations': myProfileObject.basicHeightMin.rules,
                'value': e.key
            });
            handleDropDown('maxheight');
            setChangesInForm({
                ...changesInForm,
                basicHeightMin: { ...changesInForm.basicHeightMin, value },
                basicHeightMax: { ...changesInForm.basicHeightMax},
            });
            setMyProfileObject({
                ...myProfileObject,
                basicHeightMin: { ...updatedFormData.basicHeightMin, value },
                basicHeightMax: { ...myProfileObject.basicHeightMax},
            });
        }else if (id === 'martialStatus') {
            const value = Number(e.event.target.value);
            let newMartialStatusCheckedValues = [...myProfileObject.basicMaritalStatus?.value];
            const isIncludes = newMartialStatusCheckedValues.includes(value);
            if (isIncludes) {
                newMartialStatusCheckedValues = newMartialStatusCheckedValues.filter((item) => item !== value);
            } else {
                newMartialStatusCheckedValues.push(value);
            }
            checkErrors({
                'field': myProfileObject.basicMaritalStatus,
                'name': 'marital status',
                'validations': myProfileObject.basicMaritalStatus.rules,
                'value': newMartialStatusCheckedValues
            });
            setChangesInForm({ ...changesInForm, basicMaritalStatus: {...changesInForm.basicMaritalStatus ,value:newMartialStatusCheckedValues}});
            setMyProfileObject({ ...myProfileObject, basicMaritalStatus: {...myProfileObject.basicMaritalStatus ,value:newMartialStatusCheckedValues}});
        }else if(id === 'basicReligion'){
            const value = e.map(e => e.key);
            checkErrors({
                'field': myProfileObject.basicReligion,
                'name': 'religion',
                'validations': myProfileObject.basicReligion.rules,
                'value': value
            });
            setChangesInForm({ ...changesInForm, basicReligion: {...changesInForm.basicReligion ,value:value}});
            setMyProfileObject({ ...myProfileObject, basicReligion: {...myProfileObject.basicReligion ,value:value}});
            handleDropDown('caste');
        }else if(id === 'basicCaste'){
            const value = e.map(e => e.key);
            checkErrors({
                'field': myProfileObject.basicCaste,
                'name': 'caste',
                'validations': myProfileObject.basicCaste.rules,
                'value': value
            });
            setChangesInForm({ ...changesInForm, basicCaste: {...changesInForm.basicCaste ,value:value}});
            setMyProfileObject({ ...myProfileObject, basicCaste: {...myProfileObject.basicCaste ,value:value}});
            handleDropDown('location');
        }else if(id === 'location'){
            if(e) {
                const addressComponents = e?.address_components || [];
                const getComponent = (type) => addressComponents.find(component => component.types.includes(type));
            
                const countryComponent = getComponent('country');
                if (countryComponent) {
                    checkErrors({
                        'field': myProfileObject.locationCountry,
                        'name': 'country',
                        'validations': myProfileObject.locationCountry.rules,
                        'value': [countryComponent?.long_name || '']
                    });
        
                    setMyProfileObject((prevState) => ({
                        ...prevState,
                        locationCountry: {
                            ...prevState.locationCountry,
                            value: [countryComponent?.long_name || '']
                        }
                    }));
                }
            }
        }
        // else if (id === 'location'){
        //     const addressComponents = e?.address_components || [];
        //     const getComponent = (type) => addressComponents.find(component => component.types.includes(type));
            
        //     const countryComponent = getComponent('country');
        //     if (countryComponent) {
        //         setMyProfileObject(prevFormData => {
        //             // Ensure locationCountry exists and is an array before pushing
        //             const currentCountryValues = prevFormData?.locationCountry?.value || [];
                    
        //             // Prevent duplicate entries
        //             if (!currentCountryValues.includes(countryComponent.long_name)) {
        //                 const countryValue = [...currentCountryValues, countryComponent.long_name];
                
        //                 // Update newFilters with the new country value
        //                 const newFilters = {
        //                     ...prevFormData,
        //                     locationCountry: { ...prevFormData.locationCountry, value: countryValue }
        //                 };                
        //                 return newFilters;
        //             }
            
        //             return prevFormData; // Return unchanged state if duplicate
        //         });
            
        //         // Reset search value
        //         setSearchValue('');
        //     }
        // }
        else if(id === 'educationAnnualIncome'){
            checkErrors({
                'field': myProfileObject.educationAnnualIncome,
                'name': 'annual income',
                'validations': myProfileObject.educationAnnualIncome.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, educationAnnualIncome: {...changesInForm.educationAnnualIncome ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, educationAnnualIncome: {...myProfileObject.educationAnnualIncome ,value:e.key}});
        }
    };
    const handleCancel = (event) => {
        setChangesInForm({});
        setMyProfileObject({...props.profileObject});
        props.onClick(event,'cancel',{});
    };
    const handleSubmit = (event) => {
        checkAllFields(myProfileObject).then((valid)=>{
            setMyProfileObject({...myProfileObject});
            if(valid && myProfileObject.basicHeightMax.error === '' && myProfileObject.basicHeightMin.error === '' && myProfileObject.basicAgeMax.error === '' && myProfileObject.basicAgeMin.error === ''){
                props.onClick(event,'save',myProfileObject);
            }
        });
    };
    const handleKeyPress = (event, nextInputId) => {
        focusNextInput(event, nextInputId);
    };
    const handleChange = (e,type) => {
        if(!(e.target.value)){
            let newFilters = { ...myProfileObject };
            if (type === "country") {
                newFilters = {
                    ...newFilters,
                    locationCountry: { ...newFilters.locationCountry, value: '' }
                };
            }
            setMyProfileObject(prevFormData => ({
                ...prevFormData,
                ...newFilters
            }));
        }
    };
    // const handleRemove = (value,index) => {
    //     setMyProfileObject(prevFormData => {
    //         // Ensure locationCountry exists and is an array before pushing
    //         const currentCountryValues = prevFormData?.locationCountry?.value;
    //         currentCountryValues.splice(index, 1);
    //         const countryValue = [...currentCountryValues];
    
    //         // Update newFilters with the new country value
    //         const newFilters = {
    //             ...prevFormData,
    //             locationCountry: { ...prevFormData.locationCountry, value: countryValue }
    //         };
    
    //         return newFilters;    
    //     });
    // }
    
    return(
        <div>
            <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[48px] mb-[15px] lg:leading-[57.6px] md:leading-[30px] leading-[normal]">Edit Partner Preference</h2>
            <div>
                <div className="bg-[#f0e1b9] rounded-[12px] pt-[20px] pb-[10px] mb-[30px]">
                    <div className="grid grid-cols-1 md:grid-cols-2 ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px]">
                        <div>
                            <h5 className="px-[15px] leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Age</h5>
                            <div className="relative flex">
                                <div className="px-[15px] w-full md:max-w-[170px]">
                                    <DropDown
                                        isInputSearch={true}
                                        options={dropDown['age']?.option}
                                        onUpdate={(e) => handleSelect(e,'basicAgeMin')}
                                        multiSelect={false}
                                        selectedValue={myProfileObject?.basicAgeMin?.value ? [myProfileObject.basicAgeMin?.value] : []}
                                        selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                        wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                        optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                        optionsWrapper={'bg-white'}
                                        focusInd="minage"
                                    />
                                    {myProfileObject?.basicAgeMin?.error &&
                                        <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicAgeMin.error}</small>
                                    }
                                </div>
                                <div className="mt-[14px] font-family-Quicksand-Bold text-[18px] text-[#931217] leading-[normal]">to</div>
                                <div className="px-[15px] w-full md:max-w-[170px]">
                                    <DropDown
                                        isInputSearch={true}
                                        options={dropDown['age']?.option}
                                        onUpdate={(e) => handleSelect(e,'basicAgeMax')}
                                        multiSelect={false}
                                        selectedValue={myProfileObject?.basicAgeMax?.value ? [myProfileObject.basicAgeMax?.value] : []}
                                        selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                        wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                        optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                        optionsWrapper={'bg-white'}
                                        focusInd="maxage"
                                    />
                                    {myProfileObject?.basicAgeMax?.error &&
                                        <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicAgeMax.error}</small>
                                    }
                                </div>
                            </div>
                        </div>
                        <div >
                            <h5 className="px-[15px] leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Height</h5>
                            <div className="flex relative">
                                <div className="px-[15px] w-full md:max-w-[170px]">
                                    <DropDown
                                        isInputSearch={true}
                                        options={dropDown['height']?.option}
                                        onUpdate={(e) => handleSelect(e,'basicHeightMin')}
                                        multiSelect={false}
                                        selectedValue={myProfileObject?.basicHeightMin?.value ? [myProfileObject.basicHeightMin?.value] : []}
                                        selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                        wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                        optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                        optionsWrapper={'bg-white'}
                                        focusInd="minheight"
                                    />
                                    {myProfileObject?.basicHeightMin?.error &&
                                        <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicHeightMin.error}</small>
                                    }
                                </div>
                                <div className="mt-[14px] font-family-Quicksand-Bold text-[18px] text-[#931217] leading-[normal]">to</div>
                                <div className="px-[15px] w-full md:max-w-[170px]">
                                    <DropDown
                                        isInputSearch={true}
                                        options={dropDown['height']?.option}
                                        onUpdate={(e) => handleSelect(e,'basicHeightMax')}
                                        multiSelect={false}
                                        selectedValue={myProfileObject?.basicHeightMax?.value ? [myProfileObject.basicHeightMax?.value] : []}
                                        selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                        wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                        optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                        optionsWrapper={'bg-white'}
                                        focusInd="maxheight"
                                    />
                                    {myProfileObject?.basicHeightMax?.error &&
                                        <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicHeightMax.error}</small>
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Religion</h5>
                            <DropDown
                                options={dropDown['religion']?.option}
                                onUpdate={(e) => handleSelect(e,'basicReligion')}
                                multiSelect={true}
                                selectedValue={myProfileObject?.basicReligion?.value ? myProfileObject.basicReligion?.value : []}
                                selectClass={'border border-[#ced4da] flex-wrap rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular px-[16px] py-[5.3px] h-[auto]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="religion"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Caste</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown['caste']?.option}
                                onUpdate={(e) => handleSelect(e,'basicCaste')}
                                multiSelect={true}
                                selectedValue={myProfileObject?.basicCaste?.value ? myProfileObject.basicCaste?.value : []}
                                selectClass={'max-h-[300px] overflow-auto border border-[#ced4da] flex-wrap rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular px-[16px] py-[5.3px] h-[auto]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="caste"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Country</h5>
                            <Autocomplete
                                apiKey={env.GOOGLEAPIKEY}
                                onPlaceSelected={(place) => {handleSelect(place,'location')}}
                                options={{
                                    types: ['country']
                                }}
                                fields={['address_components']}
                                className='w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'
                                placeholder={'Enter country'}
                                onChange={(e) => handleChange(e,'country')}
                                defaultValue={myProfileObject?.locationCountry?.value || ''}
                                onKeyPress={(e) => {
                                    let event = {
                                        event:e
                                    }
                                    handleKeyPress(event,'income')
                                }}
                                id="location"
                                // value={searchValue}
                                // onChange={(e) => setSearchValue(e.target.value)}
                            />
                            {/* {
                                myProfileObject?.locationCountry?.value?.length ? (
                                    <div className="border border-[#ced4da] flex-wrap rounded-[10px] after:w-0 after:h-0 flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular px-[16px] py-[5.3px] h-[auto] cursor-pointer relative">
                                        {myProfileObject.locationCountry.value.map((country, index) => (
                                            <div key={index} className="border border-[#00000080] text-[#262626] text-[16px] rounded-[5px] px-[10px] py-[4px] mr-[3.75px] my-[3.75px]">
                                                <span className="text-[#262626] text-[16px] font-family-Quicksand-SemiBold">
                                                    {country}
                                                </span>
                                                <span className="ml-[8px]" onClick={() => handleRemove(country, index)}>x</span>
                                            </div>
                                        ))}
                                    </div>
                                ) : null
                            } */}
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Annual Income</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown['annualIncome']?.option}
                                onUpdate={(value) => handleSelect(value, 'educationAnnualIncome')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.educationAnnualIncome?.value ? [myProfileObject.educationAnnualIncome?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="income"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <Button
                        class='leading-[normal] text-[#ee1c24] cursor-pointer py-[8px] px-[30px] border-[2px] border-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                        buttonName={'Cancel'}
                        onClick={(event) => handleCancel(event)}
                    />
                    <Button
                        class='leading-[normal] text-[#fff] ml-[20px] cursor-pointer py-[8px] px-[30px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                        buttonName={'Save'}
                        onClick={(event) => handleSubmit(event)}
                    />
                </div>
            </div>
        </div>
    )
};

export default EditPartnerPreference;