import { apiRequest } from '../../services/index.js';
import { getUserProfileReview } from '../reducers/userProfileReviewReducer.js';
import * as env from '../../config/env.js';

// Actions
export const getUserProfileReviewAction = (id,setValue) => async dispatch => {
    try {
        if(!setValue){
            await apiRequest("get", `${env.PROFILEREVIEW}/${id}`).then((response) => {
                if(response.status === 200){
                    let data = response.data.data;
                    data.photos = data?.photos?.length ? data.photos : []; 
                    dispatch(getUserProfileReview(data));
                }
            });
        }
        else {
            dispatch(getUserProfileReview(id));
        }
    } catch (e) {
        console.error('user get Error Action',e.message);
    }
};