import { useSelector } from 'react-redux';
import Button from '../../../atom/button.jsx';
import DropDown from '../../../atom/dropDown.jsx';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../molecules/radio/radio';
import useValidation from "../../../../utils/validation.js";
import CustomInput from "../../../molecules/customInput.jsx";
const Step2 = ({ form, nextStep,prevStep }) => {
    //useSelecter
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const  { checkErrors,checkAllFields } = useValidation();
    const [formData,setFormData] = useState({
        basicMaritalStatus:form.basicMaritalStatus,
        basicHeight:form.basicHeight,
        basicBodyType:form.basicBodyType,
        basicWeight:form.basicWeight,
        basicDisability:form.basicDisability,
        basicDisabilityDetail:form.basicDisabilityDetail,
        basicHaveAChildren:form.basicHaveAChildren,
        basicNumberOfChildrens:form.basicNumberOfChildrens,
        basicAwaitingDivorcedRemark:form.basicAwaitingDivorcedRemark,
        basicDivorcedRemark:form.basicDivorcedRemark,
        basicWidowedRemark:form.basicWidowedRemark,
        lifeStyleEatingHabits:form.lifeStyleEatingHabits,
        lifeStyleSmokingHabits:form.lifeStyleSmokingHabits,
        lifeStyleDrinkingHabits:form.lifeStyleDrinkingHabits,
        lifeStyleOwnsHouse:form.lifeStyleOwnsHouse,
        lifeStyleOwnsCar:form.lifeStyleOwnsCar,
        lifeStyleLanguages:form.lifeStyleLanguages
    });

    //function
    const handleSelect = (e,id) => {
        if(id === 'maritalStatus'){
            checkErrors({
                'field': formData.basicMaritalStatus,
                'name': 'marital status',
                'validations': formData.basicMaritalStatus.rules,                
                'value': e.key
            });
            setFormData({ ...formData,basicWidowedRemark: {...formData.basicWidowedRemark,value:''},basicNumberOfChildrens: {...formData.basicNumberOfChildrens,value:0},basicDivorcedRemark: {...formData.basicDivorcedRemark,value:''},basicAwaitingDivorcedRemark: {...formData.basicAwaitingDivorcedRemark,value:''},basicMaritalStatus: {...formData.basicMaritalStatus,value:e.key} });
        }else if(id === 'height'){
            checkErrors({
                'field': formData.basicHeight,
                'name': 'height',
                'validations': formData.basicHeight.rules,                
                'value': e.key
            });
            setFormData({ ...formData, basicHeight: {...formData.basicHeight,value:e.key}});
        }else if(id === 'basicWeight'){
            checkErrors({
                'field': formData.basicWeight,
                'name': 'weight',
                'validations': formData.basicWeight.rules,
                'value': e.key
            });
            setFormData({ ...formData, basicWeight: {...formData.basicWeight ,value:e.key}});
        }else if(id === 'basicBodyType'){
            checkErrors({
                'field': formData.basicBodyType,
                'name': 'body type',
                'validations': formData.basicBodyType.rules,
                'value': e.key
            });
            setFormData({ ...formData, basicBodyType: {...formData.basicBodyType ,value:e.key}});
        }else if(id === 'disability'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': formData.basicDisability,
                'name': 'disability',
                'validations': formData.basicDisability.rules,                
                'value': values
            });
            if(values == 1){
                setFormData({ ...formData, basicDisability: {...formData.basicDisability,value:values}});
            }else{
                setFormData({ ...formData, basicDisability: {...formData.basicDisability,value:values}, basicDisabilityDetail: {...formData.basicDisabilityDetail,value:''}});
            }
        }else if (id === 'basicDisabilityDetail'){
            checkErrors({
                'field': formData.basicDisabilityDetail,
                'name': 'basicDisabilityDetail',
                'validations': formData.basicDisabilityDetail.rules,                
                'value': e?.target?.value?.trim()
            });
            setFormData({ ...formData, basicDisabilityDetail: {...formData.basicDisabilityDetail,value:e?.target?.value?.trim()}});
        }else if(id === 'basicHaveAChildren'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': formData.basicHaveAChildren,
                'name': 'have a children',
                'validations': formData.basicHaveAChildren.rules,                
                'value': values
            });
            if(Number(values) === 2){
                setFormData({ ...formData, basicNumberOfChildrens: {...formData.basicNumberOfChildrens,value:0},basicWidowedRemark: {...formData.basicWidowedRemark,value:''},basicDivorcedRemark: {...formData.basicDivorcedRemark,value:''},basicAwaitingDivorcedRemark: {...formData.basicAwaitingDivorcedRemark,value:''}, basicHaveAChildren: {...formData.basicHaveAChildren,value:values}});
            }else{
                setFormData({ ...formData, basicHaveAChildren: {...formData.basicHaveAChildren,value:values}});
            }
            
        }else if(id === 'numberOfChildrens'){
            checkErrors({
                'field': formData.basicNumberOfChildrens,
                'name': 'number of children',
                'validations': formData.basicNumberOfChildrens.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData, basicNumberOfChildrens: {...formData.basicNumberOfChildrens,value:e.target.value} });
        }else if(id === '2'){
            checkErrors({
                'field': formData.basicWidowedRemark,
                'name': 'widowed remark',
                'validations': formData.basicWidowedRemark.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData,basicDivorcedRemark: {...formData.basicDivorcedRemark,value:''},basicAwaitingDivorcedRemark: {...formData.basicAwaitingDivorcedRemark,value:''},basicWidowedRemark: {...formData.basicWidowedRemark,value:e.target.value} });
        }else if(id === '3'){
            checkErrors({
                'field': formData.basicDivorcedRemark,
                'name': 'divorce remark',
                'validations': formData.basicDivorcedRemark.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData,basicDivorcedRemark: {...formData.basicDivorcedRemark,value:e.target.value},basicAwaitingDivorcedRemark: {...formData.basicAwaitingDivorcedRemark,value:''},basicWidowedRemark: {...formData.basicWidowedRemark,value:''} });
        }else if(id === '4'){
            checkErrors({
                'field': formData.basicAwaitingDivorcedRemark,
                'name': 'awaiting divorce remark',
                'validations': formData.basicAwaitingDivorcedRemark.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData,basicDivorcedRemark: {...formData.basicDivorcedRemark,value:''},basicAwaitingDivorcedRemark: {...formData.basicAwaitingDivorcedRemark,value:e.target.value},basicWidowedRemark: {...formData.basicWidowedRemark,value:''} });
        }else if(id === 'lifeStyleEatingHabits'){
            checkErrors({
                'field': formData.lifeStyleEatingHabits,
                'name': 'eating habits',
                'validations': formData.lifeStyleEatingHabits.rules,
                'value': e.key
            });
            setFormData({ ...formData, lifeStyleEatingHabits: {...formData.lifeStyleEatingHabits ,value:e.key}});
        }else if(id === 'lifeStyleSmokingHabits'){
            checkErrors({
                'field': formData.lifeStyleSmokingHabits,
                'name': 'smoking habits',
                'validations': formData.lifeStyleSmokingHabits.rules,
                'value': e.key
            });
            setFormData({ ...formData, lifeStyleSmokingHabits: {...formData.lifeStyleSmokingHabits ,value:e.key}});
        }else if(id === 'lifeStyleDrinkingHabits'){
            checkErrors({
                'field': formData.lifeStyleDrinkingHabits,
                'name': 'drinking habits',
                'validations': formData.lifeStyleDrinkingHabits.rules,
                'value': e.key
            });
            setFormData({ ...formData, lifeStyleDrinkingHabits: {...formData.lifeStyleDrinkingHabits ,value:e.key}});
        }else if(id === 'lifeStyleOwnsHouse'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': formData.lifeStyleOwnsHouse,
                'name': 'owns house',
                'validations': formData.lifeStyleOwnsHouse.rules,                
                'value': values
            });
            setFormData({ ...formData, lifeStyleOwnsHouse: {...formData.lifeStyleOwnsHouse ,value:values}});
        }else if(id === 'lifeStyleOwnsCar'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': formData.lifeStyleOwnsCar,
                'name': 'owns car',
                'validations': formData.lifeStyleOwnsCar.rules,                
                'value': values
            });
            setFormData({ ...formData, lifeStyleOwnsCar: {...formData.lifeStyleOwnsCar ,value:values}});
        }else if(id === 'lifeStyleLanguages'){
            const value = e.map(e => e.key);
            checkErrors({
                'field': formData.lifeStyleLanguages,
                'name': 'drinking habits',
                'validations': formData.lifeStyleLanguages.rules,
                'value': value
            });
            setFormData({ ...formData, lifeStyleLanguages: {...formData.lifeStyleLanguages ,value:value}});
        }
    };
    const handleCheck = () => {
        checkAllFields(formData).then((valid)=>{
            setFormData({...formData});
            if(valid){
                nextStep({...formData});
            }
        });
    };

    //useEffect
    useEffect(() => {
        setFormData({
            basicMaritalStatus:form.basicMaritalStatus,
            basicHeight:form.basicHeight,
            basicBodyType:form.basicBodyType,
            basicWeight:form.basicWeight,
            basicDisability:form.basicDisability,
            basicDisabilityDetail:form.basicDisabilityDetail,
            basicHaveAChildren:form.basicHaveAChildren,
            basicNumberOfChildrens:form.basicNumberOfChildrens,
            basicAwaitingDivorcedRemark:form.basicAwaitingDivorcedRemark,
            basicDivorcedRemark:form.basicDivorcedRemark,
            basicWidowedRemark:form.basicWidowedRemark,
            lifeStyleEatingHabits:form.lifeStyleEatingHabits,
            lifeStyleSmokingHabits:form.lifeStyleSmokingHabits,
            lifeStyleDrinkingHabits:form.lifeStyleDrinkingHabits,
            lifeStyleOwnsHouse:form.lifeStyleOwnsHouse,
            lifeStyleOwnsCar:form.lifeStyleOwnsCar,
            lifeStyleLanguages:form.lifeStyleLanguages
        })
    },[form]);
    return (
        <>
            <p className='lg:col-span-2 font-family-Quicksand-Medium text-[16px] text-[#262626] text-center text-stroke-2 mb-[20px]'>Personal details get you the right matches</p>
            <div className='lg:col-span-2 mb-4'>
                <DropDown
                    options={dropDown?.['maritalStatus']?.option}
                    onUpdate={(e) => handleSelect(e,'maritalStatus')}
                    multiSelect={false}
                    selectedValue={formData?.basicMaritalStatus?.value ? [formData?.basicMaritalStatus?.value] : ''}
                    labelName={'Marital Status *'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
                {/* <div className="grid lg:grid-cols-3 grid-cols-1">
                    {dropDown?.['maritalStatus']?.option.map((option, index) => (
                        <div key={index}>
                            <RadioButton
                                inputId={option.key}
                                modelValue={option.key}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                name="maritalStatus"
                                onClick={(value) => handleSelect(value, 'maritalStatus')}
                                label={option.name}
                                class={'mt-[6px] ml-[10px] mb-[6px] leading-[normal] font-family-Quicksand-Medium text-[16px] text-[#262626] text-stroke'}
                                checked={Number(formData?.basicMaritalStatus?.value) === option.key || false}
                                inputClass={`w-[20px] inline min-h-[30px] cursor-pointer`}
                                radioClassWrapper={'lg:mt-[5px] lg:mb-[2px]'}
                            />
                        </div>
                    ))}
                </div> */}
                {formData?.basicMaritalStatus?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.basicMaritalStatus.error}</small>
                }
            </div>
            {
                formData?.basicMaritalStatus?.value ? 
                    Number(formData?.basicMaritalStatus?.value) !== 1 ? 
                        <div className={`${Number(formData?.basicHaveAChildren?.value) === 1 ? 'lg:pr-[15px]' : 'lg:col-span-2'} mb-4`}>
                            <label className={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}>Have a Children</label>
                            <div className={`${Number(formData?.basicHaveAChildren?.value) === 1 ? 'lg:grid-cols-2' : 'lg:grid-cols-4'} grid grid-cols-1`}>
                                {dropDown?.['yesNo']?.option.map((option, index) => (
                                    <div key={index}>
                                        <RadioButton
                                            inputId={`${option.key}+children`}
                                            modelValue={`${option.key}+children`}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            name="basicHaveAChildren"
                                            onClick={(value) => handleSelect(value, 'basicHaveAChildren')}
                                            label={option.name}
                                            class={'mt-[6px] ml-[10px] mb-[6px] leading-[normal] font-family-Quicksand-Medium text-[16px] text-[#262626] text-stroke'}
                                            checked={`${formData?.basicHaveAChildren?.value}+children` === `${option.key}+children` || false}
                                            inputClass={`w-[20px] inline min-h-[30px] cursor-pointer`}
                                            radioClassWrapper={'lg:mt-[5px] lg:mb-[2px] lg:mr-[10px]'}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div> 
                    : '' 
                : ''
            }
            {
                formData?.basicHaveAChildren?.value && formData?.basicMaritalStatus?.value ? 
                    Number(formData?.basicHaveAChildren?.value) === 1 && Number(formData?.basicMaritalStatus?.value) !== 1 ? 
                        <div className='lg:pl-[15px] mb-4'>
                            <CustomInput
                                label="No. of Childrens"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                                class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                                type="number"
                                modelValue={formData?.basicNumberOfChildrens?.value ? formData?.basicNumberOfChildrens?.value : ''}
                                placeHolder={'No. of Childrens'}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(value,event) => handleSelect(event,'numberOfChildrens')}
                                onKeyPress={(event)=>{
                                    if(event.event.charCode === 45 || event.event.charCode === 43 || event.event.charCode === 46 || event.event.key === 'e' || event.event.key === 'E'){
                                        event.event.preventDefault();
                                    }
                                }}
                                onKeyDown={(event)=>{
                                    if(event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown'){
                                        event.event.preventDefault();
                                    }
                                }}
                                onPaste={(event) => {
                                    event.event.preventDefault();
                                }}
                            />
                        </div>
                    : '' 
                : ''
            }
            {
                formData?.basicHaveAChildren?.value && formData?.basicMaritalStatus?.value? 
                    Number(formData?.basicHaveAChildren?.value) === 1 && Number(formData?.basicMaritalStatus?.value) !== 1 ? 
                        <div className='lg:col-span-2 mb-4'>
                            <CustomInput
                                label={`${dropDown?.['maritalStatus']?.option.find(e => e.key === Number(formData?.basicMaritalStatus?.value)).name} Remark`}
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                                class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                                type="text"
                                modelValue={
                                    Number(formData?.basicMaritalStatus?.value) === 2 ? formData?.basicWidowedRemark?.value ? formData?.basicWidowedRemark?.value : '' : 
                                    Number(formData?.basicMaritalStatus?.value) === 3 ? formData?.basicDivorcedRemark?.value ? formData?.basicDivorcedRemark?.value : '' :  
                                    Number(formData?.basicMaritalStatus?.value) === 4 ? formData?.basicAwaitingDivorcedRemark?.value ? formData?.basicAwaitingDivorcedRemark?.value : '' :
                                    ''
                                }
                                placeHolder={'Remark'}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(value,event) => handleSelect(event,`${formData?.basicMaritalStatus?.value}`)}
                            />
                        </div>
                    : '' 
                : ''
            }
            <div className='lg:pr-[15px] mb-4'>
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['height']?.option}
                    onUpdate={(e) => handleSelect(e,'height')}
                    multiSelect={false}
                    selectedValue={formData?.basicHeight?.value ? [formData?.basicHeight?.value] : []}
                    labelName={'Height *'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
                {formData?.basicHeight?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.basicHeight.error}</small>
                }
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['weight']?.option}
                    onUpdate={(e) => handleSelect(e,'basicWeight')}
                    multiSelect={false}
                    selectedValue={formData?.basicWeight?.value ? [formData?.basicWeight?.value] : []}
                    labelName={'Weight'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <DropDown
                    options={dropDown?.['body']?.option}
                    onUpdate={(e) => handleSelect(e,'basicBodyType')}
                    multiSelect={false}
                    selectedValue={formData?.basicBodyType?.value ? [formData?.basicBodyType?.value] : []}
                    labelName={'Body Type'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:pl-[15px] mb-4 z-10'>
                <label className={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}>Disability *</label>
                <div className="grid lg:grid-cols-2 grid-cols-1">
                    {dropDown?.['disability']?.option.map((option, index) => (
                        <div key={index}>
                            <RadioButton
                                inputId={`${option.key}+disability`}
                                modelValue={`${option.key}+disability`}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                name="disability"
                                onClick={(value) => handleSelect(value, 'disability')}
                                label={option.name}
                                class={'mt-[6px] ml-[10px] mb-[6px] leading-[normal] font-family-Quicksand-Medium text-[16px] text-[#262626] text-stroke'}
                                checked={`${formData?.basicDisability?.value}+disability` === `${option.key}+disability` || false}
                                inputClass={`w-[20px] inline min-h-[30px] cursor-pointer`}
                                radioClassWrapper={'lg:mt-[5px] lg:mb-[2px]'}
                            />
                        </div>
                    ))}
                </div>
                {formData?.basicDisability?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.basicDisability.error}</small>
                }
            </div>
            {
                formData?.basicDisability?.value == 2 ?
                    <div className='lg:col-span-2 mb-4'>
                        <CustomInput
                            placeHolder="Enter Details of Disability"
                            label="Details of Disability"
                            inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                            class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                            type="text"
                            modelValue={formData?.basicDisabilityDetail?.value ? formData?.basicDisabilityDetail?.value : ''}
                            isDisabled={false}
                            isReadonly={false}
                            isDirectFocus={false}
                            isRequired={false}
                            isOutline={true}
                            onChange={(_, e) => handleSelect(e,'basicDisabilityDetail')}
                        />
                    </div> 
                : null
            }
            <div className="lg:pr-[15px] mb-4">
                <DropDown
                    options={dropDown?.['eatingHabits']?.option}
                    onUpdate={(value) => handleSelect(value, 'lifeStyleEatingHabits')}
                    multiSelect={false}
                    selectedValue={formData?.lifeStyleEatingHabits?.value ? [formData.lifeStyleEatingHabits?.value] : []}
                    labelName={"Eating Habits"}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className="lg:pl-[15px] mb-4">
                <DropDown
                    options={dropDown?.['smokingHabits']?.option}
                    onUpdate={(value) => handleSelect(value, 'lifeStyleSmokingHabits')}
                    multiSelect={false}
                    selectedValue={formData?.lifeStyleSmokingHabits?.value ? [formData.lifeStyleSmokingHabits?.value] : []}
                    labelName={"Smoking Habits"}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className="lg:pr-[15px] mb-4">
                <DropDown
                    options={dropDown?.['drinkingHabits']?.option}
                    onUpdate={(value) => handleSelect(value, 'lifeStyleDrinkingHabits')}
                    multiSelect={false}
                    selectedValue={formData?.lifeStyleDrinkingHabits?.value ? [formData.lifeStyleDrinkingHabits?.value] : []}
                    labelName={"Drinking Habits"}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className="lg:pl-[15px] mb-4">
                <label className={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}>Own a House?</label>
                <div className="grid lg:grid-cols-2 grid-cols-1">
                    {dropDown?.['yesNo']?.option.map((option, index) => (
                        <div key={index}>
                            <RadioButton
                                inputId={`${option.key}+ownHouse`}
                                modelValue={`${option.key}+ownHouse`}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                name="ownHouse"
                                onClick={(value) => handleSelect(value, 'lifeStyleOwnsHouse')}
                                label={option.name}
                                checked={`${formData?.lifeStyleOwnsHouse?.value}+ownHouse` === `${option.key}+ownHouse` || false}
                                class={'mt-[6px] ml-[10px] mb-[6px] leading-[normal] font-family-Quicksand-Medium text-[16px] text-[#262626] text-stroke'}
                                inputClass={`w-[20px] inline min-h-[30px] cursor-pointer`}
                                radioClassWrapper={'lg:mt-[5px] lg:mb-[2px]'}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="lg:pr-[15px] mb-4">
                <label className={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}>Own a Car?</label>
                <div className="grid lg:grid-cols-2 grid-cols-1">
                    {dropDown?.['yesNo']?.option.map((option, index) => (
                        <div key={index}>
                            <RadioButton
                                inputId={`${option.key}+ownCar`}
                                modelValue={`${option.key}+ownCar`}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                name="ownCar"
                                onClick={(value) => handleSelect(value, 'lifeStyleOwnsCar')}
                                label={option.name}
                                checked={`${formData?.lifeStyleOwnsCar?.value}+ownCar` === `${option.key}+ownCar` || false}
                                class={'mt-[6px] ml-[10px] mb-[6px] leading-[normal] font-family-Quicksand-Medium text-[16px] text-[#262626] text-stroke'}
                                inputClass={`w-[20px] inline min-h-[30px] cursor-pointer`}
                                radioClassWrapper={'lg:mt-[5px] lg:mb-[2px]'}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="lg:pl-[15px] mb-4">
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['languagesknown']?.option}
                    onUpdate={(value) => handleSelect(value, 'lifeStyleLanguages')}
                    multiSelect={true}
                    selectedValue={formData?.lifeStyleLanguages?.value ? formData.lifeStyleLanguages?.value : []}
                    labelName={"Languages known"}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'max-h-[300px] overflow-auto flex-wrap rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular px-[16px] py-[7px] h-[auto]'}
                    wrapperClass={'mb-[12px] mt-[5px] relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:col-span-2 lg:mt-[25px] lg:mb-[11px] mt-4 flex md:justify-between justify-end z-10'>
                <Button
                    onClick={prevStep} 
                    buttonName={'Previous'}
                    class={'block leading-[normal] bg-[#ed1c24] ms:rounded-[50px] rounded-[20px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] ms:px-[30px] px-[15px] md:mr-0 mr-[10px]'}
                />
                <Button
                    onClick={handleCheck} 
                    buttonName={'Next'}
                    class={'block leading-[normal] bg-[#ed1c24] ms:rounded-[50px] rounded-[20px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] ms:px-[30px] px-[15px]'}
                />
            </div>
        </>
    );
};

export default Step2;
