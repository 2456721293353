// customComposable.js

export function useCustomComposable() {
    function makeUniqueId(length = 6) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    function debounce(func, timeout = 300) {
        let timer = null;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(null, args); }, timeout);
        };
    }

    function focusNextInput(event, nextInputId) {
        // Check if the device is an iPhone
        const userAgent = window.navigator.userAgent.toLowerCase();
        const checkIfIphone = /iphone/.test(userAgent);

        if (event && event?.event?.key === 'Enter' && checkIfIphone) {
            event.event.preventDefault();
            const nextInput = document.getElementById(nextInputId);
            if (nextInput) {
                nextInput.focus();
            }
        }
    }

    function focusOnDropdown(nextInputId) {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const checkIfIphone = /iphone/.test(userAgent);
        if(checkIfIphone){
            const nextInput = document.getElementById(nextInputId);
            if (nextInput) {
                nextInput.focus();
            }
        }
    }

    return {
        makeUniqueId,
        debounce,
        focusNextInput,
        focusOnDropdown
    };
}

export default useCustomComposable;