import { useEffect, useState } from "react";
import Input from "../../atom/input.jsx";
import useCustomComposable from '../../../utils/composables/index.js';
import './index.css'
const CheckBox = (props) => {
    const {makeUniqueId} = useCustomComposable();
    const [labelName,setLabelName] = useState(props.label? props.label :'');
    const [value, setValue] = useState(props.modelValue);
    const [id] = useState(makeUniqueId(6));
    useEffect(() => {
        setValue(props.modelValue);
    }, [props.modelValue]);
    useEffect(() => {
        setLabelName(props.label);
    },[props.label])
    return(
        <div className={`flex items-center ${props.checkBoxClassWrapper ? props.checkBoxClassWrapper : ''}`}>
            <Input
                inputId={props.inputId ? props.inputId : id}
                checked={props.checked ? props.checked : false}
                inputClass={props.inputClass ? props.inputClass : ''}
                type="checkbox"
                name={props.name ? props.name : ''}
                modelValue={value}
                isDisabled={props.isDisabled ? props.isDisabled : false}
                isReadonly={props.isReadonly ? props.isReadonly : false}
                isDirectFocus={props.isDirectFocus ? props.isDirectFocus : false}
                isRequired={props.isRequired ? props.isRequired : false}
                height={props.height ? props.height : '20px'}
                width={props.width ? props.width : '20px'}
                isOutline={props.isOutline ? props.isOutline : true}
                onChange={(event) => props.onChange ? props.onChange(event) : ''}
                onFocus={(event) => props.onFocus ? props.onFocus(event) : ''}
                onBlur={(event) => props.onBlur ? props.onBlur(event) : ''}
                onClick={(event) => props.onClick ? props.onClick(event) : ''}
                isCheckBy={props.isCheckBy ? props.isCheckBy : false}
            />
            {labelName && <label className={props.labelClass ? props.labelClass : ''} htmlFor={props.inputId ? props.inputId : id}>{labelName}</label>}
        </div>
    )
}

export default CheckBox;