import { useSelector } from 'react-redux';
import Button from '../../../atom/button.jsx';
import DropDown from '../../../atom/dropDown.jsx';
import React, { useEffect, useState } from 'react';
import useValidation from "../../../../utils/validation.js";
import CustomInput from "../../../molecules/customInput.jsx";
import Autocomplete from "react-google-autocomplete";
import * as env from '../../../../config/env.js';

const Step3 = ({ form, nextStep,prevStep }) => {
    //validation
    const  { checkErrors,checkAllFields } = useValidation();
    //dropdown option
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    //useState
    const [formData,setFormData] = useState({
        educationHighestQualification:form.educationHighestQualification,
        educationSubject:form.educationSubject,
        educationInDetail:form.educationInDetail,
        educationAdditionalDegree:form.educationAdditionalDegree,
        educationEmployeedIn:form.educationEmployeedIn,
        educationOccupation:form.educationOccupation,
        educationAnnualIncome:form.educationAnnualIncome,
        educationWorkLocation:form.educationWorkLocation,
        locationCountry:form.locationCountry,
        locationCity:form.locationCity,
        locationState:form.locationState,
        educationCollege:form.educationCollege,
        educationOccupationDetail:form.educationOccupationDetail,
        educationOrganization:form.educationOrganization,
        locationResident:form.locationResident
    });
    //function
    const handleCheck = () => {
        checkAllFields(formData).then((valid)=>{
            setFormData({...formData});
            if(valid){
                nextStep(formData);
            }
        });
    };
    const handleSelect = (e,id) => {
        if(id === 'degree'){
            checkErrors({
                'field': formData.educationHighestQualification,
                'name': 'highest qualification',
                'validations': formData.educationHighestQualification.rules,                
                'value': e.key
            });
            setFormData({ ...formData, educationHighestQualification: {...formData.educationHighestQualification,value:e.key}});
        }else if(id === 'educationSubject'){
            checkErrors({
                'field': formData.educationSubject,
                'name': 'education subject',
                'validations': formData.educationSubject.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData, educationSubject: {...formData.educationSubject,value:e.target.value} });
        }else if(id === 'educationInDetail'){
            checkErrors({
                'field': formData.educationInDetail,
                'name': 'additional education detail',
                'validations': formData.educationInDetail.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData, educationInDetail: {...formData.educationInDetail,value:e.target.value} });
        }else if(id === 'additionalDegree'){
            checkErrors({
                'field': formData.educationAdditionalDegree,
                'name': 'additional degree',
                'validations': formData.educationAdditionalDegree.rules,                
                'value': e.key
            });
            setFormData({ ...formData, educationAdditionalDegree: {...formData.educationAdditionalDegree,value:e.key} });
        }else if(id === 'educationEmployeedIn'){
            checkErrors({
                'field': formData.educationEmployeedIn,
                'name': 'employed in',
                'validations': formData.educationEmployeedIn.rules,                
                'value': e.key
            });
            setFormData({ ...formData, educationEmployeedIn: {...formData.educationEmployeedIn,value:e.key} });
        }else if(id === 'educationOccupation'){
            checkErrors({
                'field': formData.educationOccupation,
                'name': 'education occupation',
                'validations': formData.educationOccupation.rules,                
                'value': e.key
            });
            setFormData({ ...formData, educationOccupation: {...formData.educationOccupation,value:e.key} });
        }else if(id === 'educationAnnualIncome'){
            checkErrors({
                'field': formData.educationAnnualIncome,
                'name': 'annual income',
                'validations': formData.educationAnnualIncome.rules,                
                'value': e.key
            });
            setFormData({ ...formData, educationAnnualIncome: {...formData.educationAnnualIncome,value:e.key} });
        }else if(id === 'educationWorkLocation'){
            checkErrors({
                'field': formData.educationWorkLocation,
                'name': 'work location',
                'validations': formData.educationWorkLocation.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData, educationWorkLocation: {...formData.educationWorkLocation,value:e.target.value} });
        }else if(id === 'location'){
            const addressComponents = e?.address_components;
            let state = '';
            let country = '';
            let city = '';
    
            // Loop through address components and extract the relevant information
            addressComponents?.forEach(component => {
                const types = component.types;
                if (types.includes('administrative_area_level_1')) {
                    state = component.long_name; // or component.short_name
                }
                if (types.includes('country')) {
                    country = component.long_name; // or component.short_name
                }
                if (types.includes('locality') || types.includes('postal_town')) {
                    city = component.long_name; // or component.short_name
                }
            });
            checkErrors({
                'field': formData.locationState,
                'name': 'state',
                'validations': formData.locationState.rules,                
                'value': state
            });
            checkErrors({
                'field': formData.locationCity,
                'name': 'city',
                'validations': formData.locationCity.rules,                
                'value': city
            });
            checkErrors({
                'field': formData.locationCountry,
                'name': 'country',
                'validations': formData.locationCountry.rules,                
                'value': country
            });
            setFormData(prevFormData => ({
                ...prevFormData,
                locationState: { ...formData.locationState, value: state },
                locationCity: { ...formData.locationCity, value: city },
                locationCountry: { ...formData.locationCountry, value: country }
            }));
        }else if(id === 'educationCollege'){
            checkErrors({
                'field': formData.educationCollege,
                'name': 'education college',
                'validations': formData.educationCollege.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData, educationCollege: {...formData.educationCollege,value:e.target.value} });
        }else if(id === 'educationOccupationDetail'){
            checkErrors({
                'field': formData.educationOccupationDetail,
                'name': 'education occupation detail',
                'validations': formData.educationOccupationDetail.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData, educationOccupationDetail: {...formData.educationOccupationDetail ,value:e.target.value}});
        }else if(id === 'educationOrganization'){
            checkErrors({
                'field': formData.educationOrganization,
                'name': 'organization',
                'validations': formData.educationOrganization.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData, educationOrganization: {...formData.educationOrganization ,value:e.target.value}});
        }else if(id === 'locationResident'){
            checkErrors({
                'field': formData.locationResident,
                'name': 'resident',
                'validations': formData.locationResident.rules,                
                'value': e.key
            });
            setFormData({ ...formData, locationResident: {...formData.locationResident,value:e.key} });
        }
    };
    const handleChange = (value) => {
        if(!(value.target.value)){
            checkErrors({
                'field': formData.locationState,
                'name': 'state',
                'validations': formData.locationState.rules,                
                'value': ''
            });
            checkErrors({
                'field': formData.locationCity,
                'name': 'city',
                'validations': formData.locationCity.rules,                
                'value': ''
            });
            checkErrors({
                'field': formData.locationCountry,
                'name': 'country',
                'validations': formData.locationCountry.rules,                
                'value': ''
            });
            setFormData(prevFormData => ({
                ...prevFormData,
                locationState: { ...formData.locationState, value: '' },
                locationCity: { ...formData.locationCity, value: '' },
                locationCountry: { ...formData.locationCountry, value: '' }
            }));
        }
    }
    //useEffect
    useEffect(() => {
        setFormData({
            educationHighestQualification:form.educationHighestQualification,
            educationSubject:form.educationSubject,
            educationInDetail:form.educationInDetail,
            educationAdditionalDegree:form.educationAdditionalDegree,
            educationEmployeedIn:form.educationEmployeedIn,
            educationOccupation:form.educationOccupation,
            educationAnnualIncome:form.educationAnnualIncome,
            educationWorkLocation:form.educationWorkLocation,
            locationCountry:form.locationCountry,
            locationCity:form.locationCity,
            locationState:form.locationState,
            educationCollege:form.educationCollege,
            educationOccupationDetail:form.educationOccupationDetail,
            educationOrganization:form.educationOrganization,
            locationResident:form.locationResident
        });
    },[form]);
    
    return (
        <>
            <p className='lg:col-span-2 font-family-Quicksand-Medium text-[16px] text-[#262626] text-center text-stroke-2 mb-[20px]'>Personal details helps you get relevant matches</p>
            <div className='lg:pr-[15px] mb-4'>
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['degree']?.option}
                    onUpdate={(e) => handleSelect(e,'degree')}
                    multiSelect={false}
                    selectedValue={formData?.educationHighestQualification?.value ? [formData?.educationHighestQualification?.value] : []}
                    labelName={'Highest Qualification *'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
                {formData?.educationHighestQualification?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.educationHighestQualification.error}</small>
                }
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <CustomInput
                    label="Education Subject"
                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                    placeHolder={'Enter Education Subject'}
                    class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    type="text"
                    modelValue={formData?.educationSubject?.value ? formData?.educationSubject?.value : ''}
                    isDisabled={false}
                    isReadonly={false}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onChange={(_, e) => handleSelect(e,'educationSubject')}
                />
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <CustomInput
                    label="Education In Detail"
                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                    placeHolder={'Enter Education In Detail'}
                    class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    type="text"
                    modelValue={formData?.educationInDetail?.value ? formData?.educationInDetail?.value : ''}
                    isDisabled={false}
                    isReadonly={false}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onChange={(_, e) => handleSelect(e,'educationInDetail')}
                />
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <CustomInput
                    label="College / Institute"
                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                    placeHolder={'Enter College / Institute'}
                    class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    type="text"
                    modelValue={formData?.educationCollege?.value ? formData?.educationCollege?.value : ''}
                    isDisabled={false}
                    isReadonly={false}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onChange={(_, e) => handleSelect(e,'educationCollege')}
                />
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['degree']?.option}
                    onUpdate={(e) => handleSelect(e,'additionalDegree')}
                    multiSelect={false}
                    selectedValue={formData?.educationAdditionalDegree?.value ? [formData?.educationAdditionalDegree?.value] : []}
                    labelName={'Additional Degree'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['employedIn']?.option}
                    onUpdate={(e) => handleSelect(e,'educationEmployeedIn')}
                    multiSelect={false}
                    selectedValue={formData?.educationEmployeedIn?.value ? [formData?.educationEmployeedIn?.value] : []}
                    labelName={'Employeed In'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['occupation']?.option}
                    onUpdate={(e) => handleSelect(e,'educationOccupation')}
                    multiSelect={false}
                    selectedValue={formData?.educationOccupation?.value ? [formData?.educationOccupation?.value] : []}
                    labelName={'Occupation *'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
                {formData?.educationOccupation?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.educationOccupation.error}</small>
                }
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <CustomInput
                    label="Occupation in Detail"
                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                    placeHolder={'Enter Occupation in Detail'}
                    class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    type="text"
                    modelValue={formData?.educationOccupationDetail?.value ? formData?.educationOccupationDetail?.value : ''}
                    isDisabled={false}
                    isReadonly={false}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onChange={(_, e) => handleSelect(e,'educationOccupationDetail')}
                />
            </div>
            <div className='lg:col-span-2 mb-4'>
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['annualIncome']?.option}
                    onUpdate={(e) => handleSelect(e,'educationAnnualIncome')}
                    multiSelect={false}
                    selectedValue={formData?.educationAnnualIncome?.value ? [formData?.educationAnnualIncome?.value] : []}
                    labelName={'Annual Income'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <CustomInput
                    label="Work Location"
                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                    placeHolder={'Enter Work Location'}
                    class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    type="text"
                    modelValue={formData?.educationWorkLocation?.value ? formData?.educationWorkLocation?.value : ''}
                    isDisabled={false}
                    isReadonly={false}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onChange={(_, e) => handleSelect(e,'educationWorkLocation')}
                />
            </div>
            <div className='lg:pl-[15px] mb-4'>
                <CustomInput
                    label="Organization"
                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                    placeHolder={'Enter Organization'}
                    class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    type="text"
                    modelValue={formData?.educationOrganization?.value ? formData?.educationOrganization?.value : ''}
                    isDisabled={false}
                    isReadonly={false}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onChange={(_, e) => handleSelect(e,'educationOrganization')}
                />
            </div>
            <div className='lg:col-span-2 mb-4'>
                <label className='text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'>City, State, Country *</label>
                <Autocomplete
                    apiKey={env.GOOGLEAPIKEY}
                    onChange={(e) => handleChange(e)}
                    onPlaceSelected={(place) => {handleSelect(place,'location')}}
                    types={['(regions)']}
                    fields={['address_components']}
                    className='w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'
                    placeholder={'Enter city country state'}
                    defaultValue={formData?.locationCity?.value && formData?.locationState?.value && formData?.locationCountry?.value ? `${formData?.locationCity?.value}, ${formData?.locationState?.value}, ${formData?.locationCountry?.value}` : ''}
                />
                { formData?.locationCountry?.error && formData?.locationCity?.error && formData?.locationState?.error ?
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">The city state country field is required</small>
                    :formData?.locationCountry?.error ? <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small> 
                    :formData?.locationCity?.error ? <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small>
                    :formData?.locationState?.error ? <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small>
                    :null
                }
            </div>
            <div className='lg:col-span-2 mb-4'>
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['resident']?.option}
                    onUpdate={(e) => handleSelect(e,'locationResident')}
                    multiSelect={false}
                    selectedValue={formData?.locationResident?.value ? [formData?.locationResident?.value] : []}
                    labelName={'Resident/Visa Status'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
            </div>
            <div className='lg:col-span-2 lg:mt-[25px] lg:mb-[11px] mt-4 flex md:justify-between justify-end z-10'>
                <Button
                    onClick={prevStep} 
                    buttonName={'Previous'}
                    class={'block leading-[normal] bg-[#ed1c24] rounded-[50px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] ms:px-[30px] px-[15px] md:mr-0 mr-[10px]'}
                />
                <Button
                    onClick={handleCheck} 
                    buttonName={'Next'}
                    class={'block leading-[normal] bg-[#ed1c24] rounded-[50px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] ms:px-[30px] px-[15px]'}
                />
            </div>
        </>
    );
};

export default Step3;
