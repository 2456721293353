import CustomInput from "../../molecules/customInput.jsx";
import cancel from '../../../assets/images/png/x-button-1.png';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as env from '../../../config/env.js';
import { apiRequest } from "../../../services/index.js";
import Popup from "../../atom/popUp.jsx";
import Spinner from "../../atom/spinner/spinner.jsx";
import { useSearch } from "../../atom/SearchContext.jsx";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import useValidation from "../../../utils/validation.js";
import { ToastContainer, toast } from "react-toastify";
const SearchById = () => {
    const { setSearchData } = useSearch();
    const navigate = useNavigate();
    const  { checkErrors,checkAllFields } = useValidation();
    const userDetails = useSelector(state => state?.user?.user);
    const [saveFiltered,setSaveFiltered] = useState([]);
    const [isPopUp,setIsPopUp] = useState(false);
    const [deleteObj,setDeleteObj] = useState({});
    const [index,setIndex] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [filter,setFilter] = useState({
        search: {value: '',rules:"required",name: "search",error: ""}
    });
    useEffect(() => {
        if(userDetails?.userId){
            try {
                setIsLoading(true);
                apiRequest("get", `${env.GETLISTOFMYSAVEDSEARCH}/${userDetails?.userId}`).then((response) => {
                    if(response.status === 200){
                        if(response?.data?.data && response?.data?.data.length){
                            setSaveFiltered(response?.data?.data);
                        }
                        setIsLoading(false);
                    }else{
                        setIsLoading(false);
                    }
                }).catch(() => {
                    console.error('Error in getting the saved data');
                    setIsLoading(false);
                });   
            } catch (error) {
                console.error('error in getting the saved data');
                setIsLoading(false);
            }
        }
    },[userDetails]);
    const handleSubmit = async() => {
        setIsLoading(true);
        try {
            apiRequest("delete", `${env.DELETESAVESEARCH}/${deleteObj?._id}`).then((response) => {
                let deleteFilter = [...saveFiltered];
                deleteFilter.splice(index, 1);
                setSaveFiltered(deleteFilter);
                setIsPopUp(false);
                setIsLoading(false);
            }).catch(() => {
                console.error('Error in deleting data');
                setIsPopUp(false);
                setIsLoading(false);
            });   
        } catch (error) {
            console.error('error in deleting data');
            setIsPopUp(false);
            setIsLoading(false);
        }

    };
    const handleDelete = (ele,index) => {
        setIndex(index);
        setIsPopUp(true);
        setDeleteObj(ele);
    };
    const handleSearch = (e) => {
        let filter = {
            ...e?.filterObj,
            filterId:e?._id
        }        
        setSearchData(filter);
        navigate(`/matches?pageNumber=${1}`);
    };
    const handleSearchWithRmId = () => {
        setFilter({...filter});
        checkAllFields(filter).then((valid)=>{
            if(valid){
                try {
                    setIsLoading(true);
                    apiRequest("get", `${env.GETUSERIDFROMRMID}/${filter.search.value ? filter.search.value : ''}`).then((response) => {
                        if(response.status === 200){
                            navigate(`/author/${userDetails?.userId}/${response.data.data}`);
                            setIsLoading(false);
                        }else{
                            toast.error('User Not found', {
                                position: 'top-right',
                            });
                            setIsLoading(false);
                        }
                    }).catch((error) => {
                        console.error('Error in getting the my matches',error);
                        setIsLoading(false);
                        if (error?.response?.data?.message) {
                            toast.error(error.response.data.message, {
                                position: 'top-right',
                            });
                        }
                    })   
                } catch (error) {
                    console.error('Error in search');
                    setIsLoading(false);
                    if (error?.response?.data?.message) {
                        toast.error(error.response.data.message, {
                            position: 'top-right',
                        });
                    }
                }
            }
        });
    };
    const handleSearchWithRmIdWithEnter = (event) => {
        if(event.event.charCode === 13){
            handleSearchWithRmId();
        }
    };
    return(
        <>
            <ToastContainer />
            {
                isLoading ? 
                <>
                    <Spinner /> 
                </>
                : null
            }
            {
                isPopUp ? 
                    <Popup
                        onClose={() => setIsPopUp(false)}
                        onConfirm={() => handleSubmit()}
                        closeBtnName={'No'}
                        submitBtnName={'Yes'}
                        submitClass={'leading-[normal] text-white bg-[#ed1c24] py-[10px] px-[15px] rounded-[50px] font-family-Quicksand-SemiBold text-[18px]'}
                        closeClass={'leading-[normal] text-white bg-[#e1b923] py-[10px] px-[15px] rounded-[50px] font-family-Quicksand-SemiBold text-[18px] ml-[0.25rem]'}
                        classWrapper={'bg-[#ead7ab] md:max-w-[960px] w-full md:px-[35px] md:pt-[50px] md:pb-[24px] xms:px-[20px] px-[10px] text-center py-[15px] rounded-[20px] shadow-lg relative'}
                        mainClassWrapper={'p-[10px]'}
                    >
                        <div>
                            <p className="pt-[20px] ms:pb-[25px] text-[20px] text-[#262626] font-family-Quicksand-Regular text-stroke">Are you sure you want to delete ?</p>
                        </div>
                    </Popup>
                : null
            }
            <h3 className="leading-[1.2] mb-[15px] md:mb-[20px] text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular lg:text-[30px] text-[26px]">My Saved Searches</h3>
            <div className="p-[20px] bg-[#F0E1BA] rounded-[10px] mt-[20px]">
                {
                    saveFiltered && saveFiltered.length && !isLoading ?
                        (saveFiltered.map((e,ind) =>
                            <div key={ind} className="flex items-center itmes-center justify-between max-h-[180px] my-[10px]">
                                <h4 onClick={() => handleSearch(e)} className="truncate font-family-Quicksand-Bold text-[20px] text-[#931217] cursor-pointer leading-[normal]">{e.searchName}</h4>
                                <img onClick={() => handleDelete(e,ind)} src={cancel} alt="Cancel" className="w-[20px] h-[20px] cursor-pointer" />
                            </div>
                        ))
                    :
                        <p className="font-family-Quicksand-Regular text-[20px] text-[#262626] text-stroke-2 leading-[normal]">You have no saved searches.</p>
                }
            </div>
            <h3 className="leading-[1.2] my-[15px] md:mt-[32px] md:mb-[20px] text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular lg:text-[30px] text-[26px]">Search by Profile ID</h3>
            <div className="relative">
                <CustomInput
                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] border border-[#E9D3A4] font-family-Quicksand-Regular rounded-[10px] pl-[16px] pr-[50px] py-[5px] h-[41px]'}
                    class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                    type="text"
                    modelValue={filter?.search?.value ? filter.search.value : ''}
                    placeHolder="Enter Profile id"
                    isDisabled={false}
                    isReadonly={false}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onChange={(e) => {
                        checkErrors({
                            'field': filter.search,
                            'name': 'search',
                            'validations': filter.search.rules,
                            'value': e
                        });
                        setFilter({ ...filter, search: {...filter.search ,value:e}});
                    }}
                    onKeyPress={(e) => handleSearchWithRmIdWithEnter(e)}
                />
                {filter?.search?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.search.error}</small>
                }
                <FontAwesomeIcon onClick={() => handleSearchWithRmId()} className="text-[#B68B2F] absolute right-[20px] top-[10px] cursor-pointer" icon={faSearch} />
            </div>
        </>
    );
};

export default SearchById;