import { useState } from "react";
import Button from "../../atom/button";
import { useSelector } from "react-redux";
import DropDown from "../../atom/dropDown.jsx";
import useValidation from "../../../utils/validation";
import useCustomComposable from "../../../utils/composables";

const BasicSearch = ({handleApply,handleReset}) => {
    const { focusOnDropdown } = useCustomComposable();
    const  { checkErrors,checkAllFields } = useValidation();
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const [filter,setFilter] = useState({
        basicReligion: {value: '',rules:"required",name: "religion",error: ""},
        basicCaste: {value: '',rules:"required",name: "caste",error: ""},
        basicMotherTongue: {value: '' ,rules:"required",name: "mother tongue",error: ""},
        basicAgeMax: {value: '' ,rules:"",name: "max age",error: ""},
        basicAgeMin: {value: '' ,rules:"",name: "min age",error: ""}
    });
    const handleAgeValidation = (e,id) => {
        const updatedFormData = { ...filter };
        const value = e.key;

        if (id === 'basicAgeMax') {
            updatedFormData.basicAgeMax.value = value;
            updatedFormData.basicAgeMax.error = '';
        } else if (id === 'basicAgeMin') {
            updatedFormData.basicAgeMin.value = value;
            updatedFormData.basicAgeMin.error = '';
        }
        const minAge = updatedFormData.basicAgeMin.value;
        const maxAge = updatedFormData.basicAgeMax.value;

        if (minAge && maxAge) {
            if (parseInt(minAge) > parseInt(maxAge)) {
                updatedFormData.basicAgeMin.error = 'The min age should be smaller than max age';
                updatedFormData.basicAgeMax.error = 'The max age should be greater than min age';
            }else{
                updatedFormData.basicAgeMin.error = '';
                updatedFormData.basicAgeMax.error = '';
            }
        }

        // Run validation for the changed field
        if (id === 'basicAgeMax') {
            checkErrors({
                'field': updatedFormData.basicAgeMax,
                'name': 'max age',
                'validations': updatedFormData.basicAgeMax.rules,
                'value': value
            });
            handleDropDown('mothertongue');
        } else if (id === 'basicAgeMin') {
            checkErrors({
                'field': updatedFormData.basicAgeMin,
                'name': 'min age',
                'validations': updatedFormData.basicAgeMin.rules,
                'value': value
            });
            handleDropDown('maxage');
        }
        // Update the state
        setFilter({
            ...updatedFormData
        });
    };
    const handleSelect = (e,id) => {
        if(id === 'basicReligion'){
            checkErrors({
                'field': filter.basicReligion,
                'name': 'religion',
                'validations': filter.basicReligion.rules,
                'value': e.key
            });
            setFilter({ ...filter, basicReligion: {...filter.basicReligion ,value:e.key}});
            handleDropDown('caste');
        }else if(id === 'basicCaste'){
            checkErrors({
                'field': filter.basicCaste,
                'name': 'caste',
                'validations': filter.basicCaste.rules,
                'value': e.key
            });
            setFilter({ ...filter, basicCaste: {...filter.basicCaste ,value:e.key}});
            handleDropDown('minage');
        }else if(id === 'basicMotherTongue'){
            checkErrors({
                'field': filter.basicMotherTongue,
                'name': 'mother tongue',
                'validations': filter.basicMotherTongue.rules,
                'value': e.key
            });
            setFilter({ ...filter, basicMotherTongue: {...filter.basicMotherTongue ,value:e.key}});
        }
    }
    const handleResetFilter = () => {
        setFilter({
            basicReligion: {value: '',rules:"required",name: "religion",error: ""},
            basicCaste: {value: '',rules:"required",name: "caste",error: ""},
            basicMotherTongue: {value: '' ,rules:"required",name: "mother tongue",error: ""},
            basicAgeMax: {value: '' ,rules:"",name: "max age",error: ""},
            basicAgeMin: {value: '' ,rules:"",name: "min age",error: ""}
        });
        handleReset({});
    };
    const handleApplyFilter = () => {
        const updatedFormData = { ...filter };
        if (!filter.basicAgeMin.value) {
            updatedFormData.basicAgeMin.error = 'The min age field is required';
        }
        if (!filter.basicAgeMax.value) {
            updatedFormData.basicAgeMax.error = 'The max age field is required';
        }
        setFilter(updatedFormData);
        checkAllFields(filter).then((valid)=>{
            if(valid && filter.basicAgeMax.error === '' && filter.basicAgeMin.error === ''){
                handleApply(filter,'basic');
            }
        });
    };
    const handleDropDown = (nextInputId) => {
        focusOnDropdown(nextInputId);
    };
    return(
        <div className="bg-[#F0E1BA] sm:p-[30px] p-[20px] rounded-[10px] grid lg:grid-cols-2 grid-cols-1">
            <div className="mt-[11px] lg:pr-[15px]">
                {filter?.religion?.value}
                <DropDown
                    options={dropDown?.['religion']?.option}
                    onUpdate={(e) => handleSelect(e,'basicReligion')}
                    multiSelect={false}
                    selectedValue={filter?.basicReligion?.value ? [filter?.basicReligion?.value] : []}
                    labelName={'Religion*'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                    selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] lg:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                    focusInd="religion"
                />
                {filter?.basicReligion?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicReligion.error}</small>
                }
            </div>
            <div className="mt-[11px] lg:pl-[15px]">
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['caste']?.option}
                    onUpdate={(e) => handleSelect(e,'basicCaste')}
                    multiSelect={false}
                    selectedValue={filter?.basicCaste?.value ? [filter?.basicCaste?.value] : []}
                    labelName={'Caste*'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                    selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                    focusInd="caste"
                />
                {filter?.basicCaste?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicCaste.error}</small>
                }
            </div>
            <div className="mt-[11px] lg:pr-[15px]">
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['age']?.option}
                    onUpdate={(e) => handleAgeValidation(e,'basicAgeMin')}
                    multiSelect={false}
                    selectedValue={filter?.basicAgeMin?.value ? [filter?.basicAgeMin?.value] : []}
                    labelName={'Age*'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                    selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] lg:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                    focusInd="minage"
                />
                {filter?.basicAgeMin?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicAgeMin.error}</small>
                }
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['age']?.option}
                    onUpdate={(e) => handleAgeValidation(e,'basicAgeMax')}
                    multiSelect={false}
                    selectedValue={filter?.basicAgeMax?.value ? [filter?.basicAgeMax?.value] : []}
                    labelName={'to'}
                    labelClass={'px-[5px] leading-[1.5] font-family-Quicksand-Regular text-[20px] text-[#262626]'}                
                    selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] lg:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                    focusInd="maxage"
                />
                {filter?.basicAgeMax?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicAgeMax.error}</small>
                }
            </div>
            <div className="mt-[11px] lg:pl-[15px]">
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['motherTongue']?.option}
                    onUpdate={(e) => handleSelect(e,'basicMotherTongue')}
                    multiSelect={false}
                    selectedValue={filter?.basicMotherTongue?.value ? [filter?.basicMotherTongue?.value] : []}
                    labelName={'Mother Tongue*'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                    selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                    focusInd="mothertongue"
                />
                {filter?.basicMotherTongue?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicMotherTongue.error}</small>
                } 
            </div>
            <div className="lg:col-span-2 ml-auto mt-[37px] mb-[10px]">
                <Button
                    class={'px-[29px] bg-[#ed1c24] leading-[20.7px] py-[12px] text-white font-family-Quicksand-SemiBold text-[18px] rounded-[35px] inline-block'}
                    buttonName={'Reset'}
                    onClick={() => handleResetFilter()}
                />
                <Button 
                    class={'px-[29px] bg-[#ed1c24] leading-[20.7px] ml-[20px] py-[12px] text-white font-family-Quicksand-SemiBold text-[18px] rounded-[35px] inline-block'}
                    buttonName={'Submit'}
                    onClick={() => handleApplyFilter()}
                />
            </div>
        </div>
    );
};

export default BasicSearch;