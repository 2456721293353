export const en = {
    generalErrorMessage: {
        fieldIsRequired: 'field is required',
    },
    //Login Process Messages
    authErrorMessage: {
        emailError: 'The email field must be a valid email',
        passwordValid: 'The password field must be at least 6 characters',
        validPassRegex:'The password must be at least 6 characters long, and contain at least 1 alphabet,1 uppercase and 1 numeric',
        confirmPasswordValid: 'The confirm password confirmation does not match',
        validCharacters: 'The first name field may only contain alphabetic characters as well as spaces',
        minMust: 'must be at least 3 characters',
    },
    //Create Company Process Messages
    companyErrorMessage: {
        phoneNumberValid:'The phone number field must be at least 10 characters'
    },
    email: 'Email',
    password: 'password',
    currentPassword:'current password',
    newPassword:'new password',
    confirmPassword:"confirm new password",
    firstName:'first name',
    lastName:'last name',
    phoneNumber:'phone number',
}