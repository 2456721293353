import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'setUserProfileReview',
    initialState: {
        setUserProfileReview: null,
    },
    reducers: {
        setUserProfileReview: (state, action) => {
            state.setUserProfileReview = action.payload;
        },
    },
});

export const { setUserProfileReview } = slice.actions;
export default slice.reducer;