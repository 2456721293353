import { useState } from 'react';
import RadioButton from '../../molecules/radio/radio';
import CustomInput from "../../molecules/customInput.jsx";
import Button from '../../atom/button';
import { useSelector } from 'react-redux';
import { apiRequest } from '../../../services/index.js';
import * as env from '../../../config/env.js';
import Spinner from '../../atom/spinner/spinner.jsx';
import Popup from '../../atom/popUp.jsx';
import { useAuth } from '../../../route/auth.jsx';
import useValidation from '../../../utils/validation.js';

const DeleteProfile = ({form,submitForm}) => {
    const { logout } = useAuth();
    const [togglePopUp, setTogglePopUp] = useState(false);
    const userDetails = useSelector(state => state?.user?.user);
    const [isLoading,setIsLoading] = useState(false)
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const  { checkErrors,checkAllFields } = useValidation();
    const [formData,setFormData] = useState({
        profileDeleteReasons:form.profileDeleteReasons,
        profileDeleteOtherReasons:form.profileDeleteOtherReasons,
        deleteReasons:form.deleteReasons
    });
    
    const handleSelect = (e,id) => {
        if(id === 'reasons'){
            const values = e.value ? e.value.split('+')[0] : '';
            if(Number(values) === 3){
                setFormData({ ...formData,deleteReasons: {...formData.deleteReasons,value:'',rules:''},profileDeleteOtherReasons:{...formData.profileDeleteOtherReasons,value:1},profileDeleteReasons: {...formData.profileDeleteReasons,value:Number(values)} });
            }else{
                setFormData({ ...formData,profileDeleteReasons: {...formData.profileDeleteReasons,value:Number(values)},deleteReasons: {...formData.deleteReasons,value:'',rules:''},profileDeleteOtherReasons:{...formData.profileDeleteOtherReasons,value:''} });
            }
        }else if(id === 'otherReasons'){
            const values = e.value ? e.value.split('+')[0] : '';
            if(Number(values) === 4){
                setFormData({ ...formData,profileDeleteOtherReasons: {...formData.profileDeleteOtherReasons,value:Number(values)},deleteReasons: {...formData.deleteReasons,rules:'required|min:3|max:100',value:''} });
            }else{
                setFormData({ ...formData,profileDeleteOtherReasons: {...formData.profileDeleteOtherReasons,value:Number(values)},deleteReasons: {...formData.deleteReasons,rules:'',value:''} });
            }
        }else if(id === 'deleteReasons'){
            checkErrors({
                'field': formData.deleteReasons,
                'name': 'delete reason',
                'validations': formData.deleteReasons.rules,
                'event': e,
                'value': e.target.value
            });
            setFormData({ ...formData, deleteReasons: {...formData.deleteReasons,value:e.target.value} });
        }
    };
    const handleSubmit = () => {
        try {
            setIsLoading(true);
            let deleteObject = {
                id:userDetails.userId,
                reason:formData?.deleteReasons?.value && formData?.deleteReasons?.value !== 3 ? formData?.deleteReasons?.value : formData?.profileDeleteOtherReasons?.value && formData?.profileDeleteOtherReasons?.value !== 4 ? formData?.profileDeleteOtherReasons?.value : formData?.profileDeleteReasons?.value ? formData?.profileDeleteReasons?.value : 1,
                deleteKey:formData?.profileDeleteReasons?.value !== 3 ? 'deleteReason' : formData?.profileDeleteOtherReasons?.value !== 4 ? 'otherDeleteReasons' : formData?.deleteReasons?.value ? 'deleteReasons' : 'deleteReasons'
            }            
            apiRequest("post", env.DELETEPROFILE, deleteObject).then(async(response) => {
                if (response.status === 200) {
                    setTogglePopUp(false);
                    logout();
                }else{
                    setTogglePopUp(false);
                    logout();
                }
            }).catch((error) => {
                console.error('error in deleting user',error);
                setIsLoading(false);
                setTogglePopUp(false);
                logout();
            });
        } catch (error) {
            console.error('error in deleting user',error);
            setTogglePopUp(false);
            logout();
        }
    };
    const handleToggle = () => {
        setFormData({...formData});
        checkAllFields(formData).then((valid)=>{
            if(valid){
                setTogglePopUp(true);
            }
        })
    };
    return(
        <>
            {isLoading ? <Spinner /> : ''}
            {togglePopUp ? (
                <Popup
                    onClose={() => setTogglePopUp(false)}
                    onConfirm={handleSubmit}
                    closeBtnName={'No'}
                    submitBtnName={'Yes'}
                    submitClass={'font-family-sans-serif text-[#fff] bg-[#ed1c24] border-[#ed1c24] rounded-[50px] px-[20px] py-[8px] leading-[1.5] text-[1rem] border'}
                    closeClass={'font-family-sans-serif text-[#fff] ml-[0.25rem] bg-[#e1b923] border-[#e1b923] rounded-[50px] px-[20px] py-[8px] leading-[1.5] text-[1rem] border'}
                    classWrapper={'bg-[#ead7ab] m-[10px] md:px-[35px] md:pt-[25px] px-[15px] text-center py-[15px] rounded-[20px] shadow-lg relative'}
                >
                    <div>
                        <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Delete Account</h2>
                        <p className="text-[16px] font-family-Quicksand-Medium text-stroke py-[15px] md:px-[25px] md:pt-[30px] md:pb-[25px]">Are you sure you want to delete this account?</p>
                    </div>
                </Popup> 
            ) : null}
            <div className="bg-[#f0e1b9] rounded-[15px] md:py-[25px] md:px-[30px] p-[15px]">
                <p className="tracking-[0.5px] text-stroke-4 pb-[15px] leading-[normal] mb-[20px] border-b-[#b7892d] border-b font-family-Quicksand-Bold text-[18px] text-[#931217]">Please choose a reason for profile deletion</p>
                <div className='pb-[15px] mb-[25px] border-b-[#b7892d] border-b'>
                    <p className='font-family-Quicksand-Regular text-[18px] tracking-[0.5px] text-[#262626] text-stroke-4 leading-[30px] pb-[15px]'>Note: If you delete your profile, it cannot be restored.</p>
                    {dropDown?.['deleteReasons']?.option.map((option, index) => (
                        <div key={index}>
                            <RadioButton
                                inputId={`${option.key}+delete`}
                                modelValue={`${option.key}+delete`}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                name="reasons"
                                onClick={(value) => handleSelect(value, 'reasons')}
                                label={option.name}
                                class={'font-family-Quicksand-Medium text-[16px] text-stroke cursor-pointer leading-[20px] text-[#666] relative'}
                                checked={`${formData?.profileDeleteReasons?.value}+delete` === `${option.key}+delete` || false}
                                inputClass={`setting-styled-radioBtn opacity-0 absolute`}
                                radioClassWrapper={'mb-[10px]'}
                            />
                        </div>
                    ))}
                </div>
                <div>
                    {
                        formData?.profileDeleteReasons?.value === 3 ? 
                        <>
                            <p className="tracking-[0.5px] text-stroke-4 pb-[15px] leading-[normal] font-family-Quicksand-Bold text-[18px] text-[#931217]">Other Reasons</p>
                            {dropDown?.['otherDeleteReasons']?.option.map((option, index) => (
                                <div key={index}>
                                    <RadioButton
                                        inputId={`${option.key}+otherDelete`}
                                        modelValue={`${option.key}+otherDelete`}
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        name="otherReasons"
                                        onClick={(value) => handleSelect(value, 'otherReasons')}
                                        label={option.name}
                                        class={'font-family-Quicksand-Medium text-[16px] text-stroke cursor-pointer leading-[20px] text-[#666] relative'}
                                        checked={`${formData?.profileDeleteOtherReasons?.value}+otherDelete` === `${option.key}+otherDelete` || false}
                                        inputClass={`setting-styled-radioBtn opacity-0 absolute`}
                                        radioClassWrapper={'mb-[10px]'}
                                    />
                                </div>
                            ))}
                        </>
                        : null
                    }
                </div>
                <div className={`flex xl:items-center items-end ${formData.profileDeleteOtherReasons.value === 4 && formData?.profileDeleteReasons?.value === 3 ? 'justify-between' : 'justify-end' } mt-[25px] xl:flex-row flex-col`}>
                    {
                        formData.profileDeleteOtherReasons.value === 4 && formData?.profileDeleteReasons?.value === 3 ? 
                            <>
                                <div className={`xl:max-w-[460px] w-full relative ${formData.deleteReasons.error ? 'xl:pb-0 pb-[15px]' : ''}`}>
                                    <CustomInput
                                        placeHolder={'Enter Reason'}
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px] rounded-[10px]'}
                                        class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                                        type="text"
                                        modelValue={formData?.deleteReasons?.value ? formData?.deleteReasons?.value : ''}
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(_, e) => handleSelect(e,'deleteReasons')}
                                    />
                                    {formData.deleteReasons.error && 
                                        <small className="absolute xl:top-[40px] top-[45px] left-0 text-[#ff0000] lg:text-[16px] text-[14px] font-family-Quicksand-Bold">{formData.deleteReasons.error}</small>
                                    }
                                </div>
                            </>
                        : null
                    }
                    <Button
                        class={`${formData.deleteReasons.error ? 'mt-[40px] xms:mt-[20px]' : 'mt-[20px]'} xl:mt-0 leading-[normal] text-[#fff] cursor-pointer py-[7.5px] px-[25px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold`}
                        buttonName={'Delete Account'}
                        onClick={() => handleToggle()}
                    />
                </div>
            </div>
        </>
    );
};

export default DeleteProfile;