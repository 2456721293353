import React, { createContext, useState, useContext } from 'react';
import Spinner from '../components/atom/spinner/spinner';
import { apiRequest } from '../services';
import Cookies from 'js-cookie';
import * as env from '../config/env.js';
import { getPartner } from '../stores/reducers/getPartnerReducer';
import { getUser } from '../stores/reducers/userReducer';
import { setCheckOutReducers } from '../stores/reducers/setCheckOutReducers';
import { getDropDown } from '../stores/reducers/getDropDownReducer';
import { setUserProfileReview } from '../stores/reducers/setUserProfileReviewReducer';
import { getUserProfileReview } from '../stores/reducers/userProfileReviewReducer';
import { getUserWithoutAuth } from '../stores/reducers/userWithOutAuthReducer';
import { useDispatch } from 'react-redux';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('userId') ? true : false);
    const login = () => {
        setIsAuthenticated(true);
    };
    const clearStore = async() => {
        await dispatch(getUser(null));
        await dispatch(getPartner(null));
        await dispatch(getDropDown(null));
        await dispatch(setCheckOutReducers(null));
        await dispatch(setUserProfileReview(null));
        await dispatch(getUserProfileReview(null));
        await dispatch(getUserWithoutAuth(null));
    };

    const logout = async() => {
        setLoading(true);
        try {
            let logoutObject = {
                sessionId:Cookies.get('sessionId')
            }
            apiRequest("post", env.LOGOUT, logoutObject).then(async(response) => {
                if(response.status === 200){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('userId');
                    Cookies.remove('sessionId');
                    setIsAuthenticated(false);
                    setTimeout(async() => {
                        clearStore();
                    });
                    setLoading(false);
                }else{
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('userId');
                    Cookies.remove('sessionId');
                    setIsAuthenticated(false);
                    setTimeout(async() => {
                        clearStore();
                    });
                    setLoading(false);
                }
            }).catch(async(error) => {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('userId');
                Cookies.remove('sessionId');
                setIsAuthenticated(false);
                setTimeout(async() => {
                    clearStore();
                });
                setLoading(false);
                console.error('error',error);
            });
        } catch (error) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userId');
            Cookies.remove('sessionId');
            setIsAuthenticated(false);
            setTimeout(async() => {
                clearStore();
            });
            setLoading(false);
            console.error('ERROr in logout');
        }
    };
    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {loading ? <Spinner /> : null}
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);