import { useEffect, useRef, useState } from "react";
import ImageUpload from "../../atom/attachment.jsx";
import Popup from "../../atom/popUp.jsx";
import Button from "../../atom/button.jsx";
import * as env from '../../../config/env.js';
import { apiRequest } from "../../../services/index.js";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Spinner from '../../atom/spinner/spinner.jsx';
import { getUser } from "../../../stores/reducers/userReducer.js";
import { getUserProfileReviewAction } from "../../../stores/action/userProfileReviewAction.js";
const Photos = ({userDetails,isStep,errors,setError}) => {    
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const [imagePreview,setImagePreview] = useState(userDetails?.photos && userDetails?.photos?.length ? userDetails.photos : []);
    const [isOpen,setIsOpen] = useState(false);
    const [ind,setInd] = useState(false);
    const [togglePopUp, setTogglePopUp] = useState(false);
    const [isButton,setIsButton] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [imageObject,setImageObject] = useState({});
    const [validation,setValidation] = useState('');
    const [required,setRequired] = useState(errors ? errors : '');
    
    const handleImageUpload = (file) => {
        try {
            setIsLoading(true);
            const selectedFile = file[0]?.url?.target?.files[0];
            if (!selectedFile) {
                throw new Error('No file selected');
            }
            const formData = new FormData();
            formData.append('type', 'upload');
            formData.append('userId', userDetails?.userId);
            formData.append('isMainPhoto', imagePreview && imagePreview.length ? false : true);
            formData.append('file', selectedFile);
            apiRequest("post", env.SETCANDIDATEPHOTOS, formData,"files").then(async(response) => {
                if(response?.status === 200){
                    if (response?.data?.data && Object?.keys(response?.data?.data)?.length) {
                        if (response?.data?.data?.photoUrls?.length) {
                            setImagePreview((prevImageObjects) => {
                                const updatedImageObjects = [...prevImageObjects];
                                const photoData = { ...response.data.data };
                                photoData.isMainPhoto = imagePreview && imagePreview.length ? false : true;
                                photoData._id = photoData.photoId;
                                updatedImageObjects.push(photoData);
                                let userObject = {
                                    ...userDetails,
                                    photos: updatedImageObjects
                                };
                                if(isStep){
                                    userObject.completedStep = 6;
                                    dispatch(getUserProfileReviewAction(userObject,true));
                                    setRequired('');
                                    setError();
                                }else {
                                    dispatch(getUser(userObject));
                                }
                                return updatedImageObjects;
                            });
                        }
                    }
                    setIsButton(false);
                    setIsLoading(false);
                    setValidation('');
                    toast.success('Uploaded successfully', {
                        position: 'top-right',
                    });
                }else{
                    toast.error('Please try it again', {
                        position: 'top-right',
                    });
                    setIsButton(false);
                    setIsLoading(false);
                    setValidation('');
                }
            }).catch(async(error) => {
                console.error('error in uploading image',error);
                setIsButton(false);
                setIsLoading(false);
                setValidation('');
                toast.error('Please try it again', {
                    position: 'top-right',
                });
            })
        } catch (error) {
            console.error('error in uploading image');
            setIsButton(false);
            setIsLoading(false);
            setValidation('');
            toast.error('Please try it again', {
                position: 'top-right',
            });
        }
    };
    const handleToggleCountryCode = (index,e) => {
        setInd(index);
        setImageObject(e);
        setIsOpen(!isOpen);
    };
    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleSubmit = () => {
        try {            
            setIsLoading(true);
            const formData = new FormData();
            formData.append('type', 'delete');
            formData.append('userId', userDetails?.userId);
            formData.append('imageId', imageObject._id);
            apiRequest("post", env.SETCANDIDATEPHOTOS, formData,"files").then(async(response) => {
                if(response.status === 200){
                    const newImagePreview = [...imagePreview];
                    newImagePreview.splice(ind, 1);
                    if(imageObject.isMainPhoto === true && newImagePreview.length){
                        newImagePreview[0] = { ...newImagePreview[0], isMainPhoto: true };
                        await handleChange(newImagePreview);
                    }
                    let userObject = {
                        ...userDetails,
                        photos: newImagePreview
                    };
                    if(isStep){
                        userObject.completedStep = 6;
                        dispatch(getUserProfileReviewAction(userObject,true));
                        setRequired('');
                    }else {
                        dispatch(getUser(userObject));
                    }
                    setImagePreview(newImagePreview);
                    setTogglePopUp(false);
                    setIsLoading(false);
                    setIsButton(false);
                    setValidation('');
                    toast.success('Deleted successfully', {
                        position: 'top-right',
                    });
                }else {
                    setIsLoading(false);
                    setIsButton(false);
                    setValidation('');
                    toast.error('Please try it again', {
                        position: 'top-right',
                    });
                }
            }).catch((error) => {
                console.error('error in deleting the image',error);
                setTogglePopUp(false);
                setIsLoading(false);
                setIsButton(false);
                setValidation('');
                toast.error('Please try it again', {
                    position: 'top-right',
                });
            });   
        } catch (error) {
            console.error('error in deleting');
            setTogglePopUp(false);
            setIsLoading(false);
            setIsButton(false);
            setValidation('');
            toast.error('Please try it again', {
                position: 'top-right',
            });
        }
    };
    const handleSetMainPhoto = () => {
        if(imagePreview && imagePreview.length){
            const imageUpload = imagePreview.map(image => ({ ...image })); // Create a copy of each object
            const imageIndex = imageUpload.findIndex(e => e.isMainPhoto === true);
            if(imageIndex > -1){
                imageUpload[imageIndex] = { ...imageUpload[imageIndex], isMainPhoto: false }; // Create a new object with updated isMainPhoto
            }
            imageUpload[ind] = { ...imageUpload[ind], isMainPhoto: true }; // Create a new object with updated isMainPhoto
            setImagePreview(imageUpload);
        }
        setIsOpen(false);
        setIsButton(true);
    };
    const handleChange = (data) => {
        if(imagePreview && imagePreview.length) {
            try {
                setIsLoading(true);                
                const image = data && data?.length ? data.find(e => e.isMainPhoto === true) : imagePreview.find(e => e.isMainPhoto === true);
                const formData = new FormData();
                formData.append('type', 'setMainPhoto');
                formData.append('userId', userDetails?.userId);
                formData.append('imageId', image._id);
                apiRequest("post", env.SETCANDIDATEPHOTOS, formData,"files").then(async(response) => {
                    if(response.status === 200){
                        const newImagePreview = data && data?.length ? [...data] : [...imagePreview];
                        let userObject = {
                            ...userDetails,
                            photos: newImagePreview
                        };
                        if(isStep){
                            userObject.completedStep = 6;
                            dispatch(getUserProfileReviewAction(userObject,true));
                            setRequired('');
                        }else {
                            dispatch(getUser(userObject));
                        }
                        setImagePreview(newImagePreview);
                        setIsButton(false);
                        setIsLoading(false);
                        setValidation('');
                        if(!(data && data?.length)){
                            toast.success('Updated successfully', {
                                position: 'top-right',
                            });
                        }
                    }else {
                        setIsButton(false);
                        setIsLoading(false);
                        setValidation('');
                        toast.error('Please try it again', {
                            position: 'top-right',
                        });
                    }
                }).catch((error) => {
                    console.error('error in deleting the image',error);
                    setIsButton(false);
                    setIsLoading(false);
                    setValidation('');
                    toast.error('Please try it again', {
                        position: 'top-right',
                    });
                });   
            } catch (error) {
                console.error('error in deleting');
                setIsButton(false);
                setIsLoading(false);
                setValidation('');
                toast.error('Please try it again', {
                    position: 'top-right',
                });
            }
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    const setProfileImage = (photoUrls) => {
        const image150x150 = photoUrls?.find(url => url['150x150']);
        return image150x150 ? image150x150['150x150'] : '';
    };
    const handleValidation = () => {
        setValidation('You are only allowed to upload a maximum of 4 Photos');
    };
    useEffect(() => {
        if(validation){
            setTimeout(() => {
                setValidation('');
            },5000)
        }
    },[validation])
    useEffect(() => {
        setRequired(errors);
    },[errors])
    return(
        <>
            <ToastContainer />
            {isLoading ? <Spinner /> : null}
            <div>
                {
                    !isStep ?
                        <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[48px] mb-[15px] lg:leading-[57.6px] md:leading-[30px] leading-[normal]">My Photos</h2>
                    : null

                }
                <div className={`bg-[#f0e1b9] rounded-[12px] px-[15px] md:px-[40px] py-[35px] md:pt-[27px] md:pb-[47px] relative`}>
                    <p className="text-center font-family-Quicksand-Medium text-stroke mb-[25px] text-[16px] text-[#262626]">Get more responses by uploading upto 4 photos on your profile.</p>
                    <div className="grid grid-cols-1 xms:grid-cols-2 ms:grid-cols-3 xl:grid-cols-4 mt-[15px]">
                        {
                            imagePreview && imagePreview.length ?
                            imagePreview.map((e, index) => 
                                <div className="mx-auto relative" key={index}>
                                    {
                                        isStep || imagePreview.length > 1
                                        ? <div onClick={() => handleToggleCountryCode(index,e)} className="after:bg-no-repeat after:w-[30px] relative after:h-[30px] after:right-[3px] after:cursor-pointer after:bg-[length:25px] after:top-[7px] after:absolute after:bg-[url('../../assets/images/png/eidticon.png')]"></div> 
                                        : ''
                                    }
                                    <img className="w-[150px] mb-[15px] h-[150px] block rounded-[12px]" src={setProfileImage(e.photoUrls)} alt={`Preview ${index}`} />
                                    {
                                        isOpen && index === ind ?
                                            <div ref={dropdownRef} className="before:absolute before:w-[10px] before:top-[-5px] before:right-[10px] before:h-[10px] before:bg-white before:rotate-45 px-[10px] pt-[3px] pb-[4px] absolute bg-white rounded-[6px] w-[105px] top-[45px] right-[7px] right-[7px]">
                                                <ul>
                                                    {
                                                        isStep ?
                                                            <li onClick={() => setTogglePopUp(true)} className="mt-[2px] mb-[3px] leading-[normal] cursor-pointer font-family-Quicksand-Medium text-[14px] text-stroke-2 text-[#ed1c24]">
                                                                Delete
                                                            </li>
                                                        :
                                                        imagePreview.length > 1 ?
                                                            <li onClick={() => setTogglePopUp(true)} className="mt-[2px] mb-[3px] leading-[normal] cursor-pointer font-family-Quicksand-Medium text-[14px] text-stroke-2 text-[#ed1c24]">
                                                                Delete
                                                            </li>
                                                        : null
                                                    }
                                                    {
                                                        e.isMainPhoto === false ?
                                                        <li onClick={() => handleSetMainPhoto()} className="mt-[2px] mb-[3px] leading-[normal] cursor-pointer font-family-Quicksand-Medium text-[14px] text-stroke-2 text-[#262626]">
                                                            Main photos
                                                        </li>
                                                        : ''
                                                    }
                                                </ul>
                                            </div>
                                        : null
                                    }
                                    {
                                        e.isMainPhoto === true ?
                                            <span className="absolute leading-[normal] bottom-[15px] left-0 text-[14px] font-family-Quicksand-Bold text-[#fff] py-[2px] px-[5px] rounded-[8px] bg-[#b7882c]">Main Photo</span>
                                        : ''
                                    }
                                </div> )
                            : null
                        }
                        {togglePopUp ? (
                            <Popup
                                onClose={() => setTogglePopUp(false)}
                                onConfirm={handleSubmit}
                                closeBtnName={'Cancel'}
                                submitBtnName={'Delete'}
                                submitClass={'font-family-sans-serif text-[#fff] bg-[#ed1c24] border-[#ed1c24] rounded-[50px] px-[20px] py-[8px] leading-[1.5] text-[1rem] border'}
                                closeClass={'font-family-sans-serif text-[#fff] ml-[0.25rem] bg-[#e1b923] border-[#e1b923] rounded-[50px] px-[20px] py-[8px] leading-[1.5] text-[1rem] border'}
                                classWrapper={'bg-[#ead7ab] m-[10px] md:px-[35px] md:pt-[25px] px-[15px] text-center py-[15px] rounded-[20px] shadow-lg relative'}
                            >
                                <div>
                                    <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Delete Photo</h2>
                                    <p className="text-[16px] font-family-Quicksand-Medium text-stroke py-[15px] md:px-[25px] md:pt-[30px] md:pb-[25px]">Are you sure you want to delete this photo?</p>
                                </div>
                            </Popup> 
                        ) : null}
                        {
                            userDetails?.photos && userDetails.photos.length < 4 ?
                            <ImageUpload
                                labelClass='after:content-["+"] after:mb-[5px] mb-[15px] mx-auto after:border-[#b7892d] after:border-[3px] after:px-[9.4px] after:rounded-[50%] after:leading-[normal] after:text-[27px] after:font-family-Quicksand-Bold text-[14px] font-family-Quicksand-SemiBold text-[#b7892d] hover:text-[#c98d17] flex-col-reverse bg-[#f6ecc8] hover:bg-[#ffffde] w-[150px] h-[150px] flex justify-center items-center rounded-[12px] cursor-pointer'
                                buttonName={'Upload Photo'}
                                onImageUpload={handleImageUpload}
                            /> : null
                        }
                    </div>
                    <p className='w-full md:w-[48%] mx-auto text-center font-family-Quicksand-Medium text-stroke text-[16px] text-[#262626]'>
                        Upload photos from your computer Within 4 mb of size, jpg/jpeg/png
                    </p>
                    {
                        userDetails?.photos && userDetails.photos.length < 4 ?
                        <ImageUpload
                            labelClass='inline-block leading-[normal] text-[#fff] cursor-pointer py-[8px] px-[30px] mt-[20px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                            buttonName={'Browse Photo'}
                            onImageUpload={handleImageUpload}
                        /> :
                        <div className="text-center">
                            <div className="text-center">
                                <label onClick={() => handleValidation()} className='inline-block leading-[normal] text-[#fff] cursor-pointer py-[8px] px-[30px] mt-[20px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'>
                                    Browse Photo
                                </label>
                            </div>
                            {validation ? <small className="pt-[10px] block text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{validation}</small> : ''}
                        </div>
                    }
                    {
                        required ? 
                            <div className="text-center">
                                <small className="pt-[10px] block text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{required}</small> 
                            </div> 
                        : ''
                    }
                    {
                        isButton ? 
                            <Button 
                                class={'leading-[normal] block mx-auto mt-4 text-[#fff] cursor-pointer py-[8px] px-[30px] mt-[20px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'}
                                buttonName={'Save'}
                                onClick={() => handleChange()}
                            />
                        : ''
                    }
                </div>
            </div>
        </>
    )
};

export default Photos;