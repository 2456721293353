const ProfileReview = () => {
    return(
        <>
            <div className="flex lg:w-full xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] max-w-full mx-auto lg:pt-[175px] lg:pb-[175px] md:pt-[120px] md:pb-[120px] pt-[140px] pb-[140px] justify-center min-h-[500px] items-center text-center">
                <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Your profile is in under review.</h2>
            </div>
        </>
    );
};

export default ProfileReview;