import { useEffect, useState } from "react";
import Photos from "./myPhotos.jsx";
import Profile from "./myProfile.jsx";
import MemberShip from "./memberShip.jsx";
import { useDispatch, useSelector } from 'react-redux';
import * as env from '../../../config/env.js';
import EditMyProfile from "./editMyProfile.jsx";
import { ToastContainer, toast } from "react-toastify";
import ProfilePicture from "./profilePicture.jsx";
import PartnerPerference from "./partnerPreference.jsx";
import Button from "../../../components/atom/button.jsx";
import ContactDetails from "../dashBoard/contactDetails.jsx";
import EditPartnerPreference from "./editPartnerPreference.jsx";
import { apiRequest } from "../../../services/index.js";
import Spinner from "../../atom/spinner/spinner.jsx";
import { getUser } from "../../../stores/reducers/userReducer.js";
import { getPartner } from "../../../stores/reducers/getPartnerReducer.js";
import { getDashboardDetail } from "../../../stores/action/geDashboard.js";

function DashBoard() {
    const dispatch = useDispatch();
    //useSelector
    const userDetails = useSelector(state => state?.user?.user);
    const partnerDetail = useSelector(state => state?.getPartner?.getPartner);
    //useState
    // const [isLoading,setIsLoading] = useState(false);
    const [activeClass,setActiveClass] = useState(1);
    const [isProfileEdit,setIsProfileEdit] = useState(false);
    const [isContactDetailEdit,setIsContactDetailEdit] = useState(false);
    const [isPartnerPerfernece,setIsPartnerPerfernece] = useState(false);
    const [handleSaveEditProfile,setHandleSaveEditProfile] = useState(false);
    const [handleSaveContactDetail,setHandleSaveContactDetail] = useState(false);
    const [userDetail] = useState(userDetails);
    const [isLoading,setIsLoading] = useState(false);

    const [contactDetailProfile,setContactDetailProfile] = useState({});

    //function
    const handleClick = (value) => {
        setActiveClass(value);
    };
    const handleEditContactDetail = () => {
        setIsProfileEdit(true);
        setIsContactDetailEdit(true);
    };
    const handleCancelContactDetail = () => {
        setIsProfileEdit(false);
        setIsContactDetailEdit(false);
    };
    const handleSubmitContactDetail = (e) => {
        setContactDetailProfile(prev => {
            const updatedProfile = {
                ...prev,
                ...e
            };
            if(handleSaveContactDetail){
                try {
                    setIsLoading(true);
                    let submitData = {
                        isAdmin:false,
                        email:userDetails.email,
                        id:userDetails.userId,
                        mobileNumber:userDetails.mobile.mobileNumber,
                        countryCode:userDetails.mobile.countryCode,
                        gender:userDetails.gender ? userDetails.gender : null,
                        basicGor:updatedProfile.basicGor.value ? updatedProfile.basicGor.value : '',
                        basicProfileCreatedFor:updatedProfile.basicProfileCreatedFor.value ? updatedProfile.basicProfileCreatedFor.value : null,
                        firstName:updatedProfile?.firstName?.value ? updatedProfile.firstName.value : '',
                        lastName:updatedProfile?.lastName?.value ? updatedProfile.lastName.value : '',
                        basicHeight:updatedProfile.basicHeight.value ? updatedProfile.basicHeight.value : null,
                        basicWeight:updatedProfile.basicWeight.value ? updatedProfile.basicWeight.value : null,
                        basicBodyType:updatedProfile.basicBodyType.value ? updatedProfile.basicBodyType.value : null,
                        basicMaritalStatus:updatedProfile.basicMaritalStatus.value ? updatedProfile.basicMaritalStatus.value : null,
                        basicHaveAChildren:updatedProfile.basicHaveAChildren.value ? updatedProfile.basicHaveAChildren.value : null,
                        basicNumberOfChildrens:updatedProfile.basicNumberOfChildrens.value ? updatedProfile.basicNumberOfChildrens.value : null,
                        basicAwaitingDivorcedRemark:updatedProfile.basicAwaitingDivorcedRemark.value ? updatedProfile.basicAwaitingDivorcedRemark.value : '',
                        basicDivorcedRemark:updatedProfile.basicDivorcedRemark.value ? updatedProfile.basicDivorcedRemark.value : '',
                        basicWidowedRemark:updatedProfile.basicWidowedRemark.value ? updatedProfile.basicWidowedRemark.value : '',
                        basicReligion:updatedProfile.basicReligion.value ? updatedProfile.basicReligion.value : null,
                        basicCaste:updatedProfile.basicCaste.value ? updatedProfile.basicCaste.value : null,
                        basicMotherTongue:updatedProfile.basicMotherTongue.value ? updatedProfile.basicMotherTongue.value : null,
                        basicDisability:updatedProfile.basicDisability.value ? updatedProfile.basicDisability.value : null,
                        basicWillingToMarry:updatedProfile.basicWillingToMarry.value,
                        aboutMe:updatedProfile.aboutMe.value ? updatedProfile.aboutMe.value : '',
                        locationCountry:updatedProfile.locationCountry.value ? updatedProfile.locationCountry.value : null,
                        locationCity:updatedProfile.locationCity.value ? updatedProfile.locationCity.value : null,
                        locationState:updatedProfile.locationState.value ? updatedProfile.locationState.value : null,
                        locationResident:updatedProfile.locationResident.value ? updatedProfile.locationResident.value : null,
                        horoscopeStar:updatedProfile.horoscopeStar.value ? updatedProfile.horoscopeStar.value : null,
                        horoscopeDateOfBirth:updatedProfile.horoscopeDateOfBirth.value,
                        horoscopeTimeOfBirth:updatedProfile.horoscopeTimeOfBirth.value ? updatedProfile.horoscopeTimeOfBirth.value : '',
                        horoscopeStateOfBirth:updatedProfile.horoscopeStateOfBirth.value ? updatedProfile.horoscopeStateOfBirth.value : '',
                        horoscopeCityOfBirth:updatedProfile.horoscopeCityOfBirth.value ? updatedProfile.horoscopeCityOfBirth.value : '',
                        horoscopeManglik:updatedProfile.horoscopeManglik.value ? updatedProfile.horoscopeManglik.value : null,
                        horoscopeBelieveInHoroscope:updatedProfile.horoscopeBelieveInHoroscope.value ? Number(updatedProfile.horoscopeBelieveInHoroscope.value) : null,
                        horoscopeMatch:updatedProfile.horoscopeMatch.value ? Number(updatedProfile.horoscopeMatch.value) : null,
                        educationHighestQualification:updatedProfile.educationHighestQualification.value ? updatedProfile.educationHighestQualification.value : null,
                        educationAdditionalDegree:updatedProfile.educationAdditionalDegree.value ? updatedProfile.educationAdditionalDegree.value : null,
                        educationSubject:updatedProfile.educationSubject.value ? updatedProfile.educationSubject.value : '',
                        educationInDetail:updatedProfile.educationInDetail.value ? updatedProfile.educationInDetail.value : '',
                        educationCollege:updatedProfile.educationCollege.value ? updatedProfile.educationCollege.value : '',
                        educationEmployeedIn:updatedProfile.educationEmployeedIn.value ? updatedProfile.educationEmployeedIn.value : null,
                        educationOccupation:updatedProfile.educationOccupation.value ? updatedProfile.educationOccupation.value : null,
                        educationOccupationDetail:updatedProfile.educationOccupationDetail.value ? updatedProfile.educationOccupationDetail.value : '',
                        educationOrganization:updatedProfile.educationOrganization.value ? updatedProfile.educationOrganization.value : '',
                        educationAnnualIncome:updatedProfile.educationAnnualIncome.value ? updatedProfile.educationAnnualIncome.value : null,
                        familyFatherOccupation:updatedProfile.familyFatherOccupation.value ? updatedProfile.familyFatherOccupation.value : null,
                        familyMotherOccupation:updatedProfile.familyMotherOccupation.value ? updatedProfile.familyMotherOccupation.value : null,
                        familySister:updatedProfile.familySister.value ? updatedProfile.familySister.value : null,
                        familyMarriedSister:updatedProfile.familyMarriedSister.value ? updatedProfile.familyMarriedSister.value : null,
                        familyBrother:updatedProfile.familyBrother.value ? updatedProfile.familyBrother.value : null,
                        familyMarriedBrother:updatedProfile.familyMarriedBrother.value ? updatedProfile.familyMarriedBrother.value : null,
                        familyStatus:updatedProfile.familyStatus.value ? updatedProfile.familyStatus.value : null,
                        familyType:updatedProfile.familyType.value ? updatedProfile.familyType.value : null,
                        familyValues:updatedProfile.familyValues.value ? updatedProfile.familyValues.value : null,
                        familyIncome:updatedProfile.familyIncome.value ? updatedProfile.familyIncome.value : null,
                        familyLivingState:updatedProfile.familyLivingState.value ? updatedProfile.familyLivingState.value : null,
                        familyLivingCountry:updatedProfile.familyLivingCountry.value ? updatedProfile.familyLivingCountry.value : null,
                        familyLivingIn:updatedProfile.familyLivingIn.value ? updatedProfile.familyLivingIn.value : null,
                        familyLiveWithFamily:updatedProfile.familyLiveWithFamily.value ? updatedProfile.familyLiveWithFamily.value : null,
                        lifeStyleEatingHabits:updatedProfile.lifeStyleEatingHabits.value ? updatedProfile.lifeStyleEatingHabits.value : null,
                        lifeStyleSmokingHabits:updatedProfile.lifeStyleSmokingHabits.value ? updatedProfile.lifeStyleSmokingHabits.value : null,
                        lifeStyleDrinkingHabits:updatedProfile.lifeStyleDrinkingHabits.value ? updatedProfile.lifeStyleDrinkingHabits.value : null,
                        lifeStyleOwnsHouse:updatedProfile.lifeStyleOwnsHouse.value ? updatedProfile.lifeStyleOwnsHouse.value : null,
                        lifeStyleOwnsCar:updatedProfile.lifeStyleOwnsCar.value ? updatedProfile.lifeStyleOwnsCar.value : null,
                        lifeStyleLanguages:updatedProfile?.lifeStyleLanguages?.value && updatedProfile?.lifeStyleLanguages?.value?.length ? updatedProfile.lifeStyleLanguages.value : [],
                        interestFavouriteVacationDestination:updatedProfile.interestFavouriteVacationDestination.value && updatedProfile.interestFavouriteVacationDestination.value.length ? updatedProfile.interestFavouriteVacationDestination.value : [],
                        interestFavouriteCusine:updatedProfile.interestFavouriteCusine.value && updatedProfile.interestFavouriteCusine.value.length ? updatedProfile.interestFavouriteCusine.value : [],
                        interestSportsFitness:updatedProfile.interestSportsFitness.value && updatedProfile.interestSportsFitness.value.length ? updatedProfile.interestSportsFitness.value : [],
                        interestPreferredMovies:updatedProfile.interestPreferredMovies.value && updatedProfile.interestPreferredMovies.value.length ? updatedProfile.interestPreferredMovies.value : [],
                        interestFavouriteTVShows:updatedProfile.interestFavouriteTVShows.value && updatedProfile.interestFavouriteTVShows.value.length ? updatedProfile.interestFavouriteTVShows.value : [],
                        interestDressStyle:updatedProfile.interestDressStyle.value && updatedProfile.interestDressStyle.value.length ? updatedProfile.interestDressStyle.value : [],
                        interestFavouriteRead:updatedProfile.interestFavouriteRead.value && updatedProfile.interestFavouriteRead.value.length ? updatedProfile.interestFavouriteRead.value : [],
                        interestMusic:updatedProfile.interestMusic.value && updatedProfile.interestMusic.value.length ? updatedProfile.interestMusic.value : [],
                        interest:updatedProfile.interest.value && updatedProfile.interest.value.length ? updatedProfile.interest.value : [],
                        interestHobbies:updatedProfile.interestHobbies.value && updatedProfile.interestHobbies.value.length ? updatedProfile.interestHobbies.value :[],
                        alternateMobile1:{
                            mobileNumber:updatedProfile.alternateMobile1.value ? updatedProfile.alternateMobile1.value : '',
                            countryCode:updatedProfile.alternateMobile1.countryCode ? updatedProfile.alternateMobile1.countryCode : ''
                        },
                        alternateMobile2:{
                            mobileNumber:updatedProfile.alternateMobile2.value ? updatedProfile.alternateMobile2.value : '',
                            countryCode:updatedProfile.alternateMobile2.countryCode ? updatedProfile.alternateMobile2.countryCode : ''
                        },
                        convenientTimeMax:updatedProfile.convenientTimeMax.value ? updatedProfile.convenientTimeMax.value : '',
                        convenientTimeMin:updatedProfile.convenientTimeMin.value ? updatedProfile.convenientTimeMin.value : ''
                    }
                    apiRequest("post", env.EDITUSERPROFILE, submitData).then(async(response) => {
                        if(response.status === 200){
                            delete submitData.id
                            delete submitData.isAdmin
                            let userDetailInfo = userDetails;
                            userDetailInfo = {
                                ...userDetailInfo,
                                ...submitData
                            }
                            await dispatch(getUser(userDetailInfo));
                            await dispatch(getDashboardDetail(userDetailInfo.userId));
                            setHandleSaveContactDetail(false);
                            setIsLoading(false);
                            toast.success('Updated successfully', {
                                position: 'top-right',
                            });
                        }else{
                            toast.error('Please try it again', {
                                position: 'top-right',
                            });
                            setIsLoading(false);
                            setHandleSaveContactDetail(false);
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            toast.error(error.response.data.message, {
                                position: 'top-right',
                            });
                        }else{
                            toast.error('Please try it again', {
                                position: 'top-right',
                            });
                        }
                        setIsLoading(false);
                        setHandleSaveContactDetail(false);
                        console.error('Error',error);
                    });
                } catch (error) {
                    setIsLoading(false);
                    console.error('Error',error);
                    toast.error('Please try it again', {
                        position: 'top-right',
                    });
                }
            }else{
                setHandleSaveEditProfile(true);
            }
            return updatedProfile;
        });
        
    };
    const handleEditMyProfile = (_,value,obj) => {
        if(value && value === 'cancel'){
            setIsProfileEdit(false);
            setIsContactDetailEdit(false);
            setHandleSaveEditProfile(false);
        }else if(value && value === 'save'){
            setContactDetailProfile(prev => {
                const updatedProfile = {
                  ...prev,
                  ...obj
                };
                if(handleSaveEditProfile){
                    try {
                        setIsLoading(true);
                        let submitData = {
                            isAdmin:false,
                            email:userDetails.email,
                            id:userDetails.userId,
                            mobileNumber:userDetails.mobile.mobileNumber,
                            countryCode:userDetails.mobile.countryCode,
                            gender:userDetails.gender ? userDetails.gender : null,
                            basicGor:updatedProfile.basicGor.value ? updatedProfile.basicGor.value : '',
                            basicProfileCreatedFor:updatedProfile.basicProfileCreatedFor.value ? updatedProfile.basicProfileCreatedFor.value : null,
                            firstName:updatedProfile?.firstName?.value ? updatedProfile.firstName.value : '',
                            lastName:updatedProfile?.lastName?.value ? updatedProfile.lastName.value : '',
                            basicHeight:updatedProfile.basicHeight.value ? updatedProfile.basicHeight.value : null,
                            basicWeight:updatedProfile.basicWeight.value ? updatedProfile.basicWeight.value : null,
                            basicBodyType:updatedProfile.basicBodyType.value ? updatedProfile.basicBodyType.value : null,
                            basicMaritalStatus:updatedProfile.basicMaritalStatus.value ? updatedProfile.basicMaritalStatus.value : null,
                            basicHaveAChildren:updatedProfile.basicHaveAChildren.value ? updatedProfile.basicHaveAChildren.value : null,
                            basicNumberOfChildrens:updatedProfile.basicNumberOfChildrens.value ? updatedProfile.basicNumberOfChildrens.value : null,
                            basicAwaitingDivorcedRemark:updatedProfile.basicAwaitingDivorcedRemark.value ? updatedProfile.basicAwaitingDivorcedRemark.value : '',
                            basicDivorcedRemark:updatedProfile.basicDivorcedRemark.value ? updatedProfile.basicDivorcedRemark.value : '',
                            basicWidowedRemark:updatedProfile.basicWidowedRemark.value ? updatedProfile.basicWidowedRemark.value : '',
                            basicReligion:updatedProfile.basicReligion.value ? updatedProfile.basicReligion.value : null,
                            basicCaste:updatedProfile.basicCaste.value ? updatedProfile.basicCaste.value : null,
                            basicMotherTongue:updatedProfile.basicMotherTongue.value ? updatedProfile.basicMotherTongue.value : null,
                            basicDisability:updatedProfile.basicDisability.value ? updatedProfile.basicDisability.value : null,
                            basicWillingToMarry:updatedProfile.basicWillingToMarry.value,
                            aboutMe:updatedProfile.aboutMe.value ? updatedProfile.aboutMe.value : '',
                            locationCountry:updatedProfile.locationCountry.value ? updatedProfile.locationCountry.value : null,
                            locationCity:updatedProfile.locationCity.value ? updatedProfile.locationCity.value : null,
                            locationState:updatedProfile.locationState.value ? updatedProfile.locationState.value : null,
                            locationResident:updatedProfile.locationResident.value ? updatedProfile.locationResident.value : null,
                            horoscopeStar:updatedProfile.horoscopeStar.value ? updatedProfile.horoscopeStar.value : null,
                            horoscopeDateOfBirth:updatedProfile.horoscopeDateOfBirth.value,
                            horoscopeTimeOfBirth:updatedProfile.horoscopeTimeOfBirth.value ? updatedProfile.horoscopeTimeOfBirth.value : '',
                            horoscopeStateOfBirth:updatedProfile.horoscopeStateOfBirth.value ? updatedProfile.horoscopeStateOfBirth.value : '',
                            horoscopeCityOfBirth:updatedProfile.horoscopeCityOfBirth.value ? updatedProfile.horoscopeCityOfBirth.value : '',
                            horoscopeManglik:updatedProfile.horoscopeManglik.value ? updatedProfile.horoscopeManglik.value : null,
                            horoscopeBelieveInHoroscope:updatedProfile.horoscopeBelieveInHoroscope.value ? Number(updatedProfile.horoscopeBelieveInHoroscope.value) : null,
                            horoscopeMatch:updatedProfile.horoscopeMatch.value ? Number(updatedProfile.horoscopeMatch.value) : null,
                            educationHighestQualification:updatedProfile.educationHighestQualification.value ? updatedProfile.educationHighestQualification.value : null,
                            educationAdditionalDegree:updatedProfile.educationAdditionalDegree.value ? updatedProfile.educationAdditionalDegree.value : null,
                            educationSubject:updatedProfile.educationSubject.value ? updatedProfile.educationSubject.value : '',
                            educationInDetail:updatedProfile.educationInDetail.value ? updatedProfile.educationInDetail.value : '',
                            educationCollege:updatedProfile.educationCollege.value ? updatedProfile.educationCollege.value : '',
                            educationEmployeedIn:updatedProfile.educationEmployeedIn.value ? updatedProfile.educationEmployeedIn.value : null,
                            educationOccupation:updatedProfile.educationOccupation.value ? updatedProfile.educationOccupation.value : null,
                            educationOccupationDetail:updatedProfile.educationOccupationDetail.value ? updatedProfile.educationOccupationDetail.value : '',
                            educationOrganization:updatedProfile.educationOrganization.value ? updatedProfile.educationOrganization.value : '',
                            educationAnnualIncome:updatedProfile.educationAnnualIncome.value ? updatedProfile.educationAnnualIncome.value : null,
                            familyFatherOccupation:updatedProfile.familyFatherOccupation.value ? updatedProfile.familyFatherOccupation.value : null,
                            familyMotherOccupation:updatedProfile.familyMotherOccupation.value ? updatedProfile.familyMotherOccupation.value : null,
                            familySister:updatedProfile.familySister.value ? updatedProfile.familySister.value : null,
                            familyMarriedSister:updatedProfile.familyMarriedSister.value ? updatedProfile.familyMarriedSister.value : null,
                            familyBrother:updatedProfile.familyBrother.value ? updatedProfile.familyBrother.value : null,
                            familyMarriedBrother:updatedProfile.familyMarriedBrother.value ? updatedProfile.familyMarriedBrother.value : null,
                            familyStatus:updatedProfile.familyStatus.value ? updatedProfile.familyStatus.value : null,
                            familyType:updatedProfile.familyType.value ? updatedProfile.familyType.value : null,
                            familyValues:updatedProfile.familyValues.value ? updatedProfile.familyValues.value : null,
                            familyIncome:updatedProfile.familyIncome.value ? updatedProfile.familyIncome.value : null,
                            familyLivingState:updatedProfile.familyLivingState.value ? updatedProfile.familyLivingState.value : null,
                            familyLivingCountry:updatedProfile.familyLivingCountry.value ? updatedProfile.familyLivingCountry.value : null,
                            familyLivingIn:updatedProfile.familyLivingIn.value ? updatedProfile.familyLivingIn.value : null,
                            familyLiveWithFamily:updatedProfile.familyLiveWithFamily.value ? updatedProfile.familyLiveWithFamily.value : null,
                            lifeStyleEatingHabits:updatedProfile.lifeStyleEatingHabits.value ? updatedProfile.lifeStyleEatingHabits.value : null,
                            lifeStyleSmokingHabits:updatedProfile.lifeStyleSmokingHabits.value ? updatedProfile.lifeStyleSmokingHabits.value : null,
                            lifeStyleDrinkingHabits:updatedProfile.lifeStyleDrinkingHabits.value ? updatedProfile.lifeStyleDrinkingHabits.value : null,
                            lifeStyleOwnsHouse:updatedProfile.lifeStyleOwnsHouse.value ? updatedProfile.lifeStyleOwnsHouse.value : null,
                            lifeStyleOwnsCar:updatedProfile.lifeStyleOwnsCar.value ? updatedProfile.lifeStyleOwnsCar.value : null,
                            lifeStyleLanguages:updatedProfile?.lifeStyleLanguages?.value && updatedProfile?.lifeStyleLanguages?.value?.length ? updatedProfile.lifeStyleLanguages.value : [],
                            interestFavouriteVacationDestination:updatedProfile.interestFavouriteVacationDestination.value && updatedProfile.interestFavouriteVacationDestination.value.length ? updatedProfile.interestFavouriteVacationDestination.value : [],
                            interestFavouriteCusine:updatedProfile.interestFavouriteCusine.value && updatedProfile.interestFavouriteCusine.value.length ? updatedProfile.interestFavouriteCusine.value : [],
                            interestSportsFitness:updatedProfile.interestSportsFitness.value && updatedProfile.interestSportsFitness.value.length ? updatedProfile.interestSportsFitness.value : [],
                            interestPreferredMovies:updatedProfile.interestPreferredMovies.value && updatedProfile.interestPreferredMovies.value.length ? updatedProfile.interestPreferredMovies.value : [],
                            interestFavouriteTVShows:updatedProfile.interestFavouriteTVShows.value && updatedProfile.interestFavouriteTVShows.value.length ? updatedProfile.interestFavouriteTVShows.value : [],
                            interestDressStyle:updatedProfile.interestDressStyle.value && updatedProfile.interestDressStyle.value.length ? updatedProfile.interestDressStyle.value : [],
                            interestFavouriteRead:updatedProfile.interestFavouriteRead.value && updatedProfile.interestFavouriteRead.value.length ? updatedProfile.interestFavouriteRead.value : [],
                            interestMusic:updatedProfile.interestMusic.value && updatedProfile.interestMusic.value.length ? updatedProfile.interestMusic.value : [],
                            interest:updatedProfile.interest.value && updatedProfile.interest.value.length ? updatedProfile.interest.value : [],
                            interestHobbies:updatedProfile.interestHobbies.value && updatedProfile.interestHobbies.value.length ? updatedProfile.interestHobbies.value :[],
                            alternateMobile1:{
                                mobileNumber:updatedProfile.alternateMobile1.value ? updatedProfile.alternateMobile1.value : '',
                                countryCode:updatedProfile.alternateMobile1.countryCode ? updatedProfile.alternateMobile1.countryCode : ''
                            },
                            alternateMobile2:{
                                mobileNumber:updatedProfile.alternateMobile2.value ? updatedProfile.alternateMobile2.value : '',
                                countryCode:updatedProfile.alternateMobile2.countryCode ? updatedProfile.alternateMobile2.countryCode : ''
                            },
                            convenientTimeMax:updatedProfile.convenientTimeMax.value ? updatedProfile.convenientTimeMax.value : '',
                            convenientTimeMin:updatedProfile.convenientTimeMin.value ? updatedProfile.convenientTimeMin.value : ''
                        }
                        apiRequest("post", env.EDITUSERPROFILE, submitData).then(async(response) => {
                            if(response.status === 200){
                                delete submitData.id
                                delete submitData.isAdmin
                                let userDetailInfo = userDetails;
                                userDetailInfo = {
                                    ...userDetailInfo,
                                    ...submitData
                                }
                                await dispatch(getUser(userDetailInfo));
                                await dispatch(getDashboardDetail(userDetailInfo.userId));
                                setIsProfileEdit(false);
                                setIsContactDetailEdit(false);
                                setHandleSaveEditProfile(false);
                                setIsLoading(false);
                                toast.success('Updated successfully', {
                                    position: 'top-right',
                                });
                            }else{
                                toast.error('Please try it again', {
                                    position: 'top-right',
                                });
                                setIsLoading(false);
                                setIsProfileEdit(false);
                                setIsContactDetailEdit(false);
                                setHandleSaveEditProfile(false);
                            }
                        }).catch((error) => {
                            if (error?.response?.data?.message) {
                                toast.error(error.response.data.message, {
                                    position: 'top-right',
                                });
                            }
                            setIsLoading(false);
                            setIsProfileEdit(false);
                            setIsContactDetailEdit(false);
                            setHandleSaveEditProfile(false);
                            console.error('Error',error);
                        });
                    } catch (error) {
                        setIsLoading(false);
                        console.error('Error',error);
                        setIsProfileEdit(false);
                        setIsContactDetailEdit(false);
                        setHandleSaveEditProfile(false);
                    }
                }else{
                    setHandleSaveContactDetail(true);
                    setIsProfileEdit(false);
                    setIsContactDetailEdit(false);
                    setHandleSaveEditProfile(false);
                }
                return updatedProfile;
            });
        }else{
            setIsProfileEdit(true);
            setIsContactDetailEdit(true);
            setHandleSaveEditProfile(false);
        }
    };
    const handleEditPartnerPerfernece = (_,value,obj) => {
        if(value && value === 'cancel'){
            setIsPartnerPerfernece(false);
        }else if(value && value === 'save'){
            try {
                setIsLoading(true);
                let setPartnerPrefernce = {
                    userId:userDetails.userId,
                    basicAgeMax: obj?.basicAgeMax?.value ? obj?.basicAgeMax?.value :null,
                    basicAgeMin: obj?.basicAgeMin?.value ? obj?.basicAgeMin?.value :null,
                    basicHeightMax:obj?.basicHeightMax?.value ? obj?.basicHeightMax?.value : null,
                    basicHeightMin:obj?.basicHeightMin?.value ? obj?.basicHeightMin?.value : null,
                    basicReligion: obj?.basicReligion?.value && obj?.basicReligion?.value?.length ? obj?.basicReligion?.value : [],
                    basicCaste: obj?.basicCaste?.value && obj?.basicCaste?.value?.length ? obj?.basicCaste?.value : [],
                    locationCountry: obj?.locationCountry?.value && obj?.locationCountry?.value?.length ? obj?.locationCountry?.value : [],
                    educationAnnualIncome:obj?.educationAnnualIncome?.value ? obj?.educationAnnualIncome?.value : null
                };
                apiRequest("post", env.SETPARTNERPREFERENCE2, setPartnerPrefernce).then(async(response) => {
                    if(response.status === 200){
                        toast.success('Updated successfully', {
                            position: 'top-right',
                        });
                        delete setPartnerPrefernce.userId
                        let partnerDetailInfo = partnerDetail;
                        partnerDetailInfo = {
                            ...partnerDetailInfo,
                            ...setPartnerPrefernce
                        }
                        await dispatch(getPartner(partnerDetailInfo));
                        await dispatch(getDashboardDetail(userDetails.userId));
                        setIsLoading(false);
                    }else{
                        toast.error('Please try it again', {
                            position: 'top-right',
                        });
                        setIsLoading(false);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        toast.error(error.response.data.message, {
                            position: 'top-right',
                        });
                    }
                    console.error('Error in updating the partner',error);
                    setIsLoading(false);
                })
            } catch (error) {
                console.error('error in updating the partner',error);
                setIsLoading(false);
            }
            setIsPartnerPerfernece(false);
        }else{
            setIsPartnerPerfernece(true);
        }
    };
    const [userInfo] = useState(userDetails);
    
    const [formDataPartner,setFormDataPartner] = useState(
        {
            basicAgeMax: {value: partnerDetail?.basicAgeMax ? partnerDetail?.basicAgeMax :'' ,rules:"",name: "basic age max",error: ""},
            basicAgeMin: {value: partnerDetail?.basicAgeMin ? partnerDetail?.basicAgeMin :'' ,rules:"",name: "basic age min",error: ""},
            basicHeightMax:{value: partnerDetail?.basicHeightMax ? partnerDetail?.basicHeightMax : '',rules:"",name: "max height",error: ""},
            basicHeightMin:{value: partnerDetail?.basicHeightMin ? partnerDetail?.basicHeightMin : '',rules:"",name: "min height",error: ""},
            basicReligion: {value: partnerDetail?.basicReligion && partnerDetail?.basicReligion?.length ? partnerDetail?.basicReligion : '',rules:"",name: "religion",error: ""},
            basicCaste: {value: partnerDetail?.basicCaste && partnerDetail?.basicCaste?.length ? partnerDetail?.basicCaste : '',rules:"",name: "caste",error: ""},
            locationCountry: {value: partnerDetail?.locationCountry && partnerDetail?.locationCountry?.length ? partnerDetail?.locationCountry : [],rules:"",name: "country",error: ""},
            // basicMotherTongue: {value: partnerDetail?.basicMotherTongue && partnerDetail?.basicMotherTongue?.length ? partnerDetail?.basicMotherTongue :'' ,rules:"",name: "mother tongue",error: ""},
            // basicDisability:{value: partnerDetail?.basicDisability ? partnerDetail?.basicDisability : '',rules:"",name: "disability",error: ""},
            educationAnnualIncome:{value: partnerDetail?.educationAnnualIncome ? partnerDetail?.educationAnnualIncome : '',rules:"",name: "annual income",error: ""},
        }
    );
    useEffect(() => {
        setFormDataPartner({
            basicAgeMax: {value: partnerDetail?.basicAgeMax ? partnerDetail?.basicAgeMax :'' ,rules:"",name: "basic age max",error: ""},
            basicAgeMin: {value: partnerDetail?.basicAgeMin ? partnerDetail?.basicAgeMin :'' ,rules:"",name: "basic age min",error: ""},
            basicHeightMax:{value: partnerDetail?.basicHeightMax ? partnerDetail?.basicHeightMax : '',rules:"",name: "max height",error: ""},
            basicHeightMin:{value: partnerDetail?.basicHeightMin ? partnerDetail?.basicHeightMin : '',rules:"",name: "min height",error: ""},
            basicReligion: {value: partnerDetail?.basicReligion && partnerDetail?.basicReligion?.length ? partnerDetail?.basicReligion : '',rules:"",name: "religion",error: ""},
            basicCaste: {value: partnerDetail?.basicCaste && partnerDetail?.basicCaste?.length ? partnerDetail?.basicCaste : '',rules:"",name: "caste",error: ""},
            locationCountry: {value: partnerDetail?.locationCountry && partnerDetail?.locationCountry?.length ? partnerDetail?.locationCountry : [],rules:"",name: "country",error: ""},
            // basicMotherTongue: {value: partnerDetail?.basicMotherTongue && partnerDetail?.basicMotherTongue?.length ? partnerDetail?.basicMotherTongue :'' ,rules:"",name: "mother tongue",error: ""},
            // basicDisability:{value: partnerDetail?.basicDisability ? partnerDetail?.basicDisability : '',rules:"",name: "disability",error: ""},
            educationAnnualIncome:{value: partnerDetail?.educationAnnualIncome ? partnerDetail?.educationAnnualIncome : '',rules:"",name: "annual income",error: ""},
        })
    },[partnerDetail])
    return (
        <>
            {
                isLoading ? 
                <>
                    <Spinner /> 
                </>
                : null
            }
            {
                userDetails?.userProfileStatus === 4 || userDetails?.userProfileStatus === 7 ?
                    <div className="lg:flex lg:w-full xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] max-w-full mx-auto xl:mt-[90px] xl:mb-[100px] lg:mt-[60px] lg:mb-[60px] mt-[40px] mb-[40px] lg:justify-between">
                        <div className="lg:w-[33%] w-full px-[15px] mb-[20px]">
                            <ProfilePicture
                                user={userDetails}
                                imageWrapper={'relative mx-auto profile__wrapper w-[220px] h-[220px] after:top-[-12px] after:left-[-21px] after:bg-no-repeat after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/profile_design.png")]'}
                                bgWrapper={'px-[25px] pt-[40px] pb-[25px]'}
                            />
                            {
                                activeClass === 2 ? 
                                    <ContactDetails
                                        handleEditContactDetail={handleEditContactDetail}
                                        handleCancelContactDetail={handleCancelContactDetail}
                                        handleSubmitContactDetail={(e) => handleSubmitContactDetail(e)}
                                        isContactDetailEdit={isContactDetailEdit}
                                        handleSaveContactDetail={handleSaveContactDetail}
                                    />
                                :
                                null
                            }
                        </div>
                        <div className="lg:w-[67%] w-full px-[15px]">
                            {isProfileEdit || isPartnerPerfernece ? null : 
                                <div className="flex overflow-auto border-[1px] border-[#b7892d] rounded-[50px] ms:justify-between max-w-[max-content] mb-[25px]">
                                    <Button
                                        class={`${activeClass === 1 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#b7892d]'} tracking-[0.456px] leading-[normal] pt-[6.5px] pb-[8.5px] px-[7.4px] min-w-[auto] whitespace-nowrap ms:min-w-[98px] text-[14px] ms:px-[18px] ms:pt-[8px] ms:pb-[10px] rounded-[50px] font-family-Quicksand-Bold text-center block`}
                                        buttonName={'Dashboard'}
                                        onClick={() => handleClick(1)}
                                    />
                                    <Button
                                        class={`${activeClass === 2 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#b7892d]'} tracking-[0.456px] leading-[normal] pt-[6.5px] pb-[8.5px] px-[7.4px] min-w-[auto] whitespace-nowrap ms:min-w-[98px] text-[14px] ms:px-[18px] ms:pt-[8px] ms:pb-[10px] rounded-[50px] font-family-Quicksand-Bold text-center block`}
                                        buttonName={'My Profile'}
                                        onClick={() => handleClick(2)}
                                    />
                                    <Button
                                        class={`${activeClass === 3 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#b7892d]'} tracking-[0.456px] leading-[normal] pt-[6.5px] pb-[8.5px] px-[7.4px] min-w-[auto] whitespace-nowrap ms:min-w-[98px] text-[14px] ms:px-[18px] ms:pt-[8px] ms:pb-[10px] rounded-[50px] font-family-Quicksand-Bold text-center block`}
                                        buttonName={'My Photos'}
                                        onClick={() => handleClick(3)}
                                    />
                                    <Button
                                        class={`${activeClass === 4 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#b7892d]'} tracking-[0.456px] leading-[normal] pt-[6.5px] pb-[8.5px] px-[7.4px] min-w-[auto] whitespace-nowrap ms:min-w-[98px] text-[14px] ms:px-[18px] ms:pt-[8px] ms:pb-[10px] rounded-[50px] font-family-Quicksand-Bold text-center block`}
                                        buttonName={'Partner Preference'}
                                        onClick={() => handleClick(4)}
                                    />
                                </div>
                            }
                            {
                                activeClass === 1 ? <MemberShip /> :
                                activeClass === 2 ? 
                                <>
                                    <ToastContainer />
                                    {
                                        !isProfileEdit ? 
                                            <Profile
                                                onClick={(event) => handleEditMyProfile(event)}
                                                userInfo={userInfo}
                                            />
                                        :
                                            <EditMyProfile
                                                onClick={(event,value,obj) => handleEditMyProfile(event,value,obj)}
                                                handleSaveEditProfile={handleSaveEditProfile}
                                            />
                                    }
                                
                                </>
                                :
                                activeClass === 3 ? 
                                    <>
                                        <Photos
                                            userDetails={userDetails}
                                        />
                                    </>
                                :
                                activeClass === 4 ? 
                                !isPartnerPerfernece ? 
                                    <>
                                        <ToastContainer />
                                        <PartnerPerference
                                            partnerInfo={partnerDetail}
                                            userDetail={userDetail}
                                            onClick={(event) => handleEditPartnerPerfernece(event)} 
                                        /> 
                                    </>
                                    : <EditPartnerPreference 
                                            profileObject={formDataPartner}
                                            onClick={(event,value,obj) => handleEditPartnerPerfernece(event,value,obj)}
                                        />
                                : null
                            }
                        </div>
                    </div>
                : null
            }
        </>
    )
}

export default DashBoard;