const Button = (props) => {
    return(
        <button
            onClick={(event) =>
                props.onClick ? props.onClick({
                    event: event
                }) : ''
            }
            className={props.class ? props.class : ''}
        >
            {props.buttonName ? props.buttonName : ''}
        </button>
    );
}

export default Button;