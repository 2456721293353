import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'user',
    initialState: {
        user: null,
    },
    reducers: {
        getUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export const { getUser } = slice.actions;
export default slice.reducer;