import '../header/index.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../atom/button.jsx';
import {useAuth} from "../../../route/auth.jsx"
import male from "../../../assets/images/png/male.png";
import female from "../../../assets/images/png/female.png";
import { useLocation,useNavigate } from 'react-router-dom';
import ClientWidthContext from '../../atom/clientWidth.jsx';
import logoImage from '../../../assets/images/png/logo.png';
import toogleImage from '../../../assets/images/svg/toogleImage.svg';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSearch } from '../../atom/SearchContext.jsx';
import { getUserProfileReviewAction } from '../../../stores/action/userProfileReviewAction';
import Spinner from '../../atom/spinner/spinner';
function Header({isAuthenticated}) {
    const dispatch = useDispatch();
    const dashboardDetail = useSelector(state => state?.dashboard?.dashboard);
    const setProfileImage = () => {
        if(userProfileDetail){
            const defaultPhoto = userProfileDetail?.photos?.find(photo => photo.isMainPhoto);
            if (defaultPhoto) {
                const image250x260 = defaultPhoto.photoUrls.find(url => url['50x50']);
                return image250x260 ? image250x260['50x50'] : (userProfileDetail?.gender === 1 ? male : userProfileDetail?.gender === 2 ? female : '');
            }else{
                return userProfileDetail?.gender === 1 ? male : userProfileDetail?.gender === 2 ? female : '';
            }
        }else if (userDetail?.photos && userDetail?.photos.length > 0) {
            const defaultPhoto = userDetail.photos.find(photo => photo.isMainPhoto);
            if (defaultPhoto) {
                const image250x260 = defaultPhoto.photoUrls.find(url => url['50x50']);
                return image250x260 ? image250x260['50x50'] : (userDetail?.gender === 1 ? male : userDetail?.gender === 2 ? female : '');
            }else{
                return userDetail?.gender === 1 ? male : userDetail?.gender === 2 ? female : '';
            }
        } else {
            return userDetail?.gender === 1 ? male : userDetail?.gender === 2 ? female : '';
        }
    };
    const { setSearchData } = useSearch();
    const [isLoading,setIsLoading] = useState(false);
    const userDetail = useSelector(state => state?.user?.user);
    const userProfileDetail = useSelector(state => state?.userProfileReview?.userProfileReview);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const clientWidth = useContext(ClientWidthContext);
    const location = useLocation();
    const [toggle,setToggle] = useState(true);
    const logoImages = useRef(null);
    const selectDropDown = useRef(null);
    const selectDropDownName = useRef(null);
    const [divHeight, setDivHeight] = useState(0);
    const [isOpen,setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const handleOutsideClick = (event) => {
        if (selectDropDownName.current && !selectDropDownName.current.contains(event.target) && selectDropDown.current && !selectDropDown.current.contains(event.target) && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (!userProfileDetail && (userDetail?.userProfileStatus === 3 || userDetail?.userProfileStatus === 5)) {
            const fetchData = async () => {
                setIsLoading(true);
                const userId = userDetail?._id;
                if (userId) {
                    try {
                        await dispatch(getUserProfileReviewAction(userId));
                        setProfileImage();
                        setIsLoading(false);
                    } catch (error) {
                        setIsLoading(false);    
                    }
                }else{
                    setIsLoading(false);
                }
            };
            fetchData();
        }else {
            setProfileImage();
        }
    }, [userDetail,userProfileDetail,dispatch]);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    useEffect(() => {
        if (logoImages?.current) {
          setDivHeight(logoImages.current.clientHeight);
        }
    }, [logoImages?.current?.clientHeight]);
    useEffect(() => {
        if(clientWidth <= 767){
            setToggle(false);
        }else{
            setToggle(true);
        }
    },[clientWidth,location?.pathname]);
    const handleSignOut = () => {
        setIsOpen(false)
        logout();
    };
    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            {
                isLoading ? 
                <>
                    <Spinner /> 
                </>
                : null
            }
            <div className={`${clientWidth <= 767 ? 'bg-[#8b4c1d] after:z-0' : 'md:bg-[url("../../assets/images/png/header_bg.png")] after:z-0'} relative pb-[65px] bg-no-repeat after:bg-no-repeat after:bg-bottom after:bg-[url('../../assets/images/png/header_bottom_bg.png')] after:w-[100%] after:h-[100%] after:absolute after:bottom-[-1px]`}>
                <div className="flex md:justify-between max-w-[540px] md:max-w-[1140px] mx-auto relative z-10">
                    <div ref={logoImages} className="w-[40%] md:w-1/4">
                        {
                            isAuthenticated ? 
                                <Link to={'/'} rel="noopener noreferrer">
                                    <img src={logoImage} alt='logoImage' className='px-[15px]' />
                                </Link>
                            : 
                                <Link target="_blank" rel="noopener noreferrer" to={'https://rishtamatrimony.com/'}>
                                    <img src={logoImage} alt='logoImage' className='px-[15px]' />
                                </Link>
                        }
                    </div>
                    {clientWidth <= 767 ? (
                    <div>
                        <img className='absolute right-[30px] translate-y-[-50%] top-2/4' onClick={(event) => {event.preventDefault(); setToggle(!toggle);setIsOpen(false);}} src={toogleImage} alt='toogleImage' />
                    </div>
                    ) : null}
                    
                    {toggle ? (<div className={`md:flex ${userDetail?.userProfileStatus === 4 || userDetail?.userProfileStatus === 7 ? '' : 'justify-end'} pt-[37px] flex-wrap md:items-start md:w-3/3 lg:w-[61.5%] lg:px-0 md:pr-[15px] ${clientWidth === 1024 ? 'justify-center' : ''} ${clientWidth <= 767 ? `animate-[dropdown-animation_0.3s_ease-in_forwards] absolute bg-[#f6ebc7] left-[0] w-[100%] p-[15px] mx-auto translate-x-[-50%]` : ''}`} style={clientWidth <= 767 ? { top: `${divHeight}px` } : {}}>
                        <nav className=''>
                        {
                            isAuthenticated ?
                                userDetail?.userProfileStatus === 4 || userDetail?.userProfileStatus === 7 ? (
                                    <ul className="md:flex md:items-center pt-[8px]">
                                        <li className={`md:text-[#ffffff] xl:ml-[12px] xl:mr-[17px] lg:mx-[5px] md:mx-[5px] xl:text-[18px] lg:text-[16px] md:text-[14px] uppercase ${clientWidth <= 767 ? 'mb-[4px] font-family-Quicksand-regular capitalize text-[18px] text-[#4e1904]' : 'font-family-Quicksand-SemiBold'}`}>
                                            <Link onClick={() => setSearchData({})} to="/" className={`${location.pathname === '/' ?  `${clientWidth <= 767 ? 'text-[#ed1c24]' : 'md:bg-[#ed1c24] md:pt-[4px] leading-[27px] block md:pr-[13px] md:pb-[5px] md:pl-[12px] md:rounded-[30px]'}` : ''}`}>Dashboard</Link>
                                        </li>
                                        <li className={`md:text-[#ffffff] xl:ml-[12px] xl:mr-[17px] lg:mx-[5px] md:mx-[5px] xl:text-[18px] lg:text-[16px] md:text-[14px] uppercase ${clientWidth <= 767 ? 'mb-[4px] font-family-Quicksand-regular capitalize text-[18px] text-[#4e1904]' : 'font-family-Quicksand-SemiBold'}`}>
                                            <Link to={`/matches?pageNumber=1&tab=1`} onClick={() => setSearchData({})} className={`${location.pathname.includes('matches') ? `${clientWidth <= 767 ? 'text-[#ed1c24]' : 'md:bg-[#ed1c24] md:pt-[4px] leading-[27px] block md:pr-[13px] md:pb-[5px] md:pl-[12px] md:rounded-[30px]'}` : ''}`}>My Matches</Link>
                                        </li>
                                        <li className={`md:text-[#ffffff] xl:ml-[12px] xl:mr-[17px] lg:mx-[5px] md:mx-[5px] xl:text-[18px] lg:text-[16px] md:text-[14px] uppercase ${clientWidth <= 767 ? 'mb-[4px] font-family-Quicksand-regular capitalize text-[18px] text-[#4e1904]' : 'font-family-Quicksand-SemiBold'}`}>
                                            <Link onClick={() => setSearchData({})} to="/likes?pageNumber=1&tab=1" className={`${location.pathname.includes('likes') ? `${clientWidth <= 767 ? 'text-[#ed1c24]' : 'md:bg-[#ed1c24] md:pt-[4px] leading-[27px] block md:pr-[13px] md:pb-[5px] md:pl-[12px] md:rounded-[30px]'}` : ''}`}>My Like</Link>
                                        </li>
                                        <li className={`md:text-[#ffffff] xl:ml-[12px] xl:mr-[17px] lg:mx-[5px] md:mx-[5px] xl:text-[18px] lg:text-[16px] md:text-[14px] uppercase ${clientWidth <= 767 ? 'mb-[4px] font-family-Quicksand-regular capitalize text-[18px] text-[#4e1904]' : 'font-family-Quicksand-SemiBold'}`}>
                                            <Link to="/search" onClick={() => setSearchData({})} className={`${location.pathname === '/search' ? `${clientWidth <= 767 ? 'text-[#ed1c24]' : 'md:bg-[#ed1c24] md:pt-[4px] leading-[27px] block md:pr-[13px] md:pb-[5px] md:pl-[12px] md:rounded-[30px]'}` : ''}`}>Search</Link>
                                        </li>
                                    </ul> 
                                ) : null
                            : 
                            <ul className="md:flex md:items-center pt-[8px]">
                                <li className={`md:text-[#ffffff] xl:ml-[12px] xl:mr-[17px] lg:mx-[5px] md:mx-[5px] xl:text-[18px] lg:text-[16px] md:text-[14px] uppercase ${clientWidth <= 767 ? 'mb-[4px] font-family-Quicksand-regular capitalize text-[18px] text-[#4e1904]' : 'font-family-Quicksand-SemiBold'}`}>
                                    <Link target="_blank" rel="noopener noreferrer" to={'/search'} className={`${location.pathname === '/' ?  `${clientWidth <= 767 ? 'text-[#ed1c24]' : 'md:bg-[#ed1c24] md:pt-[4px] leading-[27px] block md:pr-[13px] md:pb-[5px] md:pl-[12px] md:rounded-[30px]'}` : ''}`}>Search</Link>
                                </li>
                                <li className={`md:text-[#ffffff] xl:ml-[12px] xl:mr-[17px] lg:mx-[5px] md:mx-[5px] xl:text-[18px] lg:text-[16px] md:text-[14px] uppercase ${clientWidth <= 767 ? 'mb-[4px] font-family-Quicksand-regular capitalize text-[18px] text-[#4e1904]' : 'font-family-Quicksand-SemiBold'}`}>
                                    <Link target="_blank" rel="noopener noreferrer" to={'https://rishtamatrimony.com/about-us/'} className={`${location.pathname === '/matches?pageNumber=1' ? `${clientWidth <= 767 ? 'text-[#ed1c24]' : 'md:bg-[#ed1c24] md:pt-[4px] leading-[27px] block md:pr-[13px] md:pb-[5px] md:pl-[12px] md:rounded-[30px]'}` : ''}`}>About Us</Link>
                                </li>
                                <li className={`md:text-[#ffffff] xl:ml-[12px] xl:mr-[17px] lg:mx-[5px] md:mx-[5px] xl:text-[18px] lg:text-[16px] md:text-[14px] uppercase ${clientWidth <= 767 ? 'mb-[4px] font-family-Quicksand-regular capitalize text-[18px] text-[#4e1904]' : 'font-family-Quicksand-SemiBold'}`}>
                                    <Link target="_blank" rel="noopener noreferrer" to={'https://rishtamatrimony.com/contact-us/'} className={`${location.pathname === '/likes?pageNumber=1' ? `${clientWidth <= 767 ? 'text-[#ed1c24]' : 'md:bg-[#ed1c24] md:pt-[4px] leading-[27px] block md:pr-[13px] md:pb-[5px] md:pl-[12px] md:rounded-[30px]'}` : ''}`}>Contact Us</Link>
                                </li>
                                <li className={`md:text-[#ffffff] xl:ml-[12px] xl:mr-[17px] lg:mx-[5px] md:mx-[5px] xl:text-[18px] lg:text-[16px] md:text-[14px] uppercase ${clientWidth <= 767 ? 'mb-[4px] font-family-Quicksand-regular capitalize text-[18px] text-[#4e1904]' : 'font-family-Quicksand-SemiBold'}`}>
                                    <Link target="_blank" rel="noopener noreferrer" to={'https://rishtamatrimony.com/help/'} className={`${location.pathname === '/search' ? `${clientWidth <= 767 ? 'text-[#ed1c24]' : 'md:bg-[#ed1c24] md:pt-[4px] leading-[27px] block md:pr-[13px] md:pb-[5px] md:pl-[12px] md:rounded-[30px]'}` : ''}`}>Help</Link>
                                </li>
                            </ul>
                        }
                        </nav>
                        {
                            isAuthenticated ? 
                                <div className={`relative flex items-center ${clientWidth <= 767 ? 'my-[10px]' : 'md:pl-[7px]'}`}>
                                    {
                                        setProfileImage() ? 
                                        <div className='w-[50px] h-[50px] cursor-pointer'>
                                            <img src={setProfileImage(userDetail)} ref={selectDropDown} alt='userProfile' className={`${clientWidth <= 1024 ? 'mt-[-4px]' : ''} rounded-[50%] w-[50px] h-[50px]`} onClick={() => handleToggleDropdown()} />
                                        </div> : null
                                    }
                                    {
                                        isOpen ?
                                            <div ref={dropdownRef} className='block ease-in-out absolute md:right-0 w-[240px] top-[calc(100%+20px)] bg-white rounded-[12px] p-[20px]'>
                                                <ul>
                                                    {
                                                        userDetail?.userProfileStatus === 4 || userDetail?.userProfileStatus === 7 ? 
                                                            <li className='mb-[6px] leading-[normal]'>
                                                                <Link to="/" onClick={() => {setIsOpen(false);setSearchData({});}} className={`${location.pathname === '/' ? 'text-[#ed1c24]' : 'text-[#262626]'} leading-[normal] text-[16px] font-family-Quicksand-Medium`}>Dashboard</Link>
                                                            </li> 
                                                        : ''
                                                    }
                                                    {
                                                        userDetail?.userProfileStatus === 4 || userDetail?.userProfileStatus === 7 ? 
                                                            <li className='mb-[6px] leading-[normal]'>
                                                                <Link to="/settings?tab=transaction-history" onClick={() => {setIsOpen(false);setSearchData({});}} className={`${location.search.includes('transaction-history') ? 'text-[#ed1c24]' : 'text-[#262626]'} leading-[normal] text-[16px] font-family-Quicksand-Medium`}>Transaction History</Link>
                                                            </li> 
                                                        : ''
                                                    }
                                                    {
                                                        userDetail?.userProfileStatus === 4 || userDetail?.userProfileStatus === 7 ? 
                                                            <li className='mb-[6px] leading-[normal]'>
                                                                <Link onClick={() => {setIsOpen(false);setSearchData({});}} to={'/settings'} className={`${location.search.includes('alerts') ? 'text-[#ed1c24]' : 'text-[#262626]'} leading-[normal] text-[16px] font-family-Quicksand-Medium`}>Settings</Link>
                                                            </li>
                                                        : ''
                                                    }                                 
                                                    <li className='mb-[6px] leading-[normal]'>
                                                        <Link target="_blank" to="https://rishtamatrimony.com/help/" onClick={() => setIsOpen(false)} className='leading-[normal] text-[#262626] text-[16px] font-family-Quicksand-Medium'>Help</Link>
                                                    </li>
                                                    <li className='mb-[6px] leading-[normal]'>
                                                        <Link onClick={() => handleSignOut()} className='leading-[normal] text-[#262626] text-[16px] font-family-Quicksand-Medium'>Sign Out</Link>
                                                    </li>
                                                    {
                                                        userDetail?.userProfileStatus === 4 &&
                                                        <li className='h-[1px] bg-[#b7892d] mt-[17px] mb-[17px]'>
                                                        </li>
                                                    }
                                                    <li>
                                                        {dashboardDetail?.planDetail ? <p onClick={() => {setIsOpen(true);setSearchData({});}} className='leading-[normal] text-[#262626] text-[16px] font-family-Quicksand-Medium text-stroke-2'>Account type: {dashboardDetail?.planDetail?.planName}</p> : ''}
                                                    </li>
                                                    {
                                                        userDetail?.userProfileStatus === 4 &&
                                                        <li>
                                                            <Link to='/membership' onClick={() => {setIsOpen(false);setSearchData({});}} className={`block font-family-Quicksand-Medium text-[16px] text-center text-white bg-[#ed1c24] rounded-[50px] px-[25px] py-[8px] ${dashboardDetail?.planDetail ? 'mt-[25px]' : ''}`}>View All Plans</Link>
                                                        </li>
                                                    }
                                                </ul>
                                            </div> 
                                        : null
                                    }
                                    <div className='ml-[10px] cursor-pointer'>
                                        <h3 ref={selectDropDownName} onClick={() => handleToggleDropdown()} className={`capitalize font-family-Quicksand-SemiBold ${clientWidth <= 767 ? 'text-[#931217] text-[17px]' : 'text-ellipse md:text-[#ffffff] lg:text-[18px] md:text-[14px]'}`}>{userProfileDetail?.name ? userProfileDetail?.name : userDetail?.name}   {userProfileDetail?.firstName || userProfileDetail?.lastName ? `${userProfileDetail?.firstName} ${userProfileDetail?.lastName}` : userDetail?.firstName || userDetail?.lastName ? `${userDetail?.firstName} ${userDetail?.lastName}` : ''}</h3>
                                    </div>
                                </div>
                            : 
                            <div className={`relative flex items-center ${clientWidth <= 767 ? 'my-[10px]' : 'md:pl-[7px]'}`}>
                                <Button
                                    class={'bg-[#ed1c24] text-[#fff] text-[18px] rounded-[50px] pt-[5px] pb-[10px] pr-[28px] pl-[25px] leading-[27px] border border-[transparent] font-family-Quicksand-SemiBold text-center block mx-auto'}
                                    buttonName={'Login'}
                                    onClick={() => navigate('/login')}
                                />
                                <Button
                                    class={'bg-[#ed1c24] text-[#fff] text-[18px] rounded-[50px] pt-[5px] pb-[10px] pr-[28px] pl-[25px] leading-[27px] border ml-[6px] border-[transparent] font-family-Quicksand-SemiBold text-center block mx-auto'}
                                    buttonName={'Register'}
                                    onClick={() => navigate('/register')}
                                />
                            </div>
                        }
                    </div>) : ''}
                </div>
            </div>
        </>
    );
}

export default Header;